import {
  GOOGLE_FLIGHTS_EXT_ORIGIN_PARAM_VALUES,
  KAYAK_EXT_ORIGIN_PARAM_VALUES,
  SKYSCANNER_EXT_ORIGIN_PARAM_VALUES,
  LANDING_OFFER_FROM_META_KEY,
  LANDING_BRANDED_OFFER_FROM_META,
  GOOGLE_SEARCH_WIDGET_EXT_ORIGIN_PARAM_VALUES,
  COMPANY_HOSTNAME,
  WEGO_EXT_ORIGIN_PARAM_VALUES,
  FLYGRESOR_EXT_ORIGIN_PARAM_VALUES
} from "../constants";
import localstory from "localstory";

import { MarketingTrackingVariables } from "@flights/types";

const isClient = process.env.BUILD_TARGET === "client";
const sessionStore = isClient && localstory(window.sessionStorage, "flights");

const marketingTrackingParams = [
  "ext-src",
  "adplat",
  "aid",
  "label",
  "ext-origin",
  "ext-fwd",
  "ext-tr",
  "salesCountry"
] as const;

export const getMarketingTrackingVariable = (trackingParam: typeof marketingTrackingParams[number]) => {
  const storedTrackingParam = sessionStore && sessionStore.get(trackingParam);

  if (storedTrackingParam && trackingParam == "aid") {
    return Number(storedTrackingParam);
  } else {
    return storedTrackingParam;
  }
};

export const getExtOriginMarketingTrackingVariable = () => {
  if (isClient) {
    return sessionStore && sessionStore.get("ext-origin");
  }
  return window?.__GLOBAL_CONTEXT__?.marketingTrackingVariables?.externalOrigin;
};

export const getSearchApiMarketingParams = () => {
  const mappedParams = {};
  const externalOrigin = getMarketingTrackingVariable("ext-origin");
  const externalForward = getMarketingTrackingVariable("ext-fwd");
  const salesCountry = getMarketingTrackingVariable("salesCountry");

  //https://drive.google.com/file/d/140HE2DVKqLr0uqvKLw1vluKiNxt9b0bP/view
  if (externalOrigin) {
    mappedParams["salesChannel"] = externalOrigin;
  }
  if (externalForward) {
    mappedParams["salesIdentifier"] = externalForward;
  }
  if (salesCountry) {
    mappedParams["salesCountry"] = salesCountry;
  }
  return mappedParams;
};

export const setMarketingTrackingVariables = (marketingTrackingVariables: MarketingTrackingVariables | undefined) => {
  //all variables are stored in sessionStore
  if (!isClient || !sessionStore) {
    return;
  }

  marketingTrackingVariables?.aid && sessionStore.set("aid", marketingTrackingVariables.aid);
  marketingTrackingVariables?.label && sessionStore.set("label", marketingTrackingVariables.label);
  marketingTrackingVariables?.gclid && sessionStore.set("gclid", marketingTrackingVariables.gclid);
  marketingTrackingVariables?.auid && sessionStore.set("auid", marketingTrackingVariables.auid);
  marketingTrackingVariables?.adPlat && sessionStore.set("adplat", marketingTrackingVariables.adPlat);
  marketingTrackingVariables?.externalCountry &&
    sessionStore.set("salesCountry", marketingTrackingVariables.externalCountry);

  marketingTrackingVariables?.externalOrigin &&
    sessionStore.set("ext-origin", marketingTrackingVariables.externalOrigin);
  marketingTrackingVariables?.externalForward &&
    sessionStore.set("ext-fwd", marketingTrackingVariables.externalForward);
  marketingTrackingVariables?.externalSource && sessionStore.set("ext-src", marketingTrackingVariables.externalSource);
  marketingTrackingVariables?.externalTracking &&
    sessionStore.set("ext-tr", marketingTrackingVariables.externalTracking);
};

export const isOfMetaOrigin = () => {
  if (window.location.host === COMPANY_HOSTNAME) {
    return !!window.__GLOBAL_CONTEXT__.isOfMetaOrigin;
  }

  return !!getExtOriginMarketingTrackingVariable();
};
export const isOfPPCOrigin = () => {
  return !!window?.__GLOBAL_CONTEXT__.isPPC;
};
export const isDirect = () => !isOfMetaOrigin() && !isOfPPCOrigin();

export const isOfGoogleFlightsOrigin = () =>
  GOOGLE_FLIGHTS_EXT_ORIGIN_PARAM_VALUES.includes(getExtOriginMarketingTrackingVariable());
export const isOfKayakOrigin = () => KAYAK_EXT_ORIGIN_PARAM_VALUES.includes(getExtOriginMarketingTrackingVariable());
export const isOfSkyScannerOrigin = () =>
  SKYSCANNER_EXT_ORIGIN_PARAM_VALUES.includes(getExtOriginMarketingTrackingVariable());
export const isOfWegoOrigin = () => WEGO_EXT_ORIGIN_PARAM_VALUES.includes(getExtOriginMarketingTrackingVariable());
export const isOfFlygresorOrigin = () =>
  FLYGRESOR_EXT_ORIGIN_PARAM_VALUES.includes(getExtOriginMarketingTrackingVariable());
export const isOfGoogleSearchWidgetOrigin = () =>
  GOOGLE_SEARCH_WIDGET_EXT_ORIGIN_PARAM_VALUES.includes(getExtOriginMarketingTrackingVariable());

export const getMetaLandingOfferToken = () => {
  if (!sessionStore) {
    return;
  }
  return sessionStore.get(LANDING_OFFER_FROM_META_KEY);
};

export const isMetaLandingOfferToken = (offerToken: string) => {
  if (!sessionStore) {
    return;
  }
  const landingOfferToken = getMetaLandingOfferToken();
  return landingOfferToken && offerToken.includes(landingOfferToken);
};

export const isMetaLandingBrandedOfferToken = (brandedOfferToken: string) => {
  if (!sessionStore) {
    return;
  }
  const landingOfferToken = getMetaLandingOfferToken();
  const landingBrandedOfferToken = sessionStore.get(LANDING_BRANDED_OFFER_FROM_META);
  return landingOfferToken && brandedOfferToken == landingBrandedOfferToken;
};

//offerToken : main offer token, brandedOfferToken: offer token off the branded fare offer
export function setLandingBrandedFareOfferToken(offerToken: string, brandedOfferToken: string) {
  if (!sessionStore) {
    return;
  }
  const isMeta = isOfMetaOrigin();
  const isLandingOfferToken = isMetaLandingOfferToken(offerToken);

  //offerToken can be a normal offer token or a previously set branded offer token
  const isLandingBrandedOfferToken = isMetaLandingBrandedOfferToken(offerToken);

  if (isMeta && (isLandingOfferToken || isLandingBrandedOfferToken)) {
    sessionStore.set(LANDING_BRANDED_OFFER_FROM_META, brandedOfferToken);
  }
}
