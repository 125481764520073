import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { UIAriaLive } from "@flights/types";

export enum ActionTypes {
  setMessage = "ariaLive/set",
  reset = "ariaLive/reset"
}

export const actions = {
  setMessage: createAction((ariaLive: UIAriaLive) => ({
    type: ActionTypes.setMessage,
    payload: ariaLive
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
