import { isOfMetaOrigin } from "utils/marketing-url-params";
import { createTrackingUtils } from "..";

const etName = "flights_web_flexible_date_search_mdot";

const createCustomGoal = <T extends string>(name: T): `${typeof etName}_${T}` => `${etName}_${name}`;

const utils = createTrackingUtils({
  name: etName,
  stages: {
    search_results: 1, // All users on SR
    main: 2,
    direct: 3,
    meta: 4,
    solo: 5,
    "2_plus": 6
  },
  goals: {
    flex_is_in_view: 1,
    click_on_item: 2
  },
  goalsWithValue: (
    [
      "sr_diff_percentage",
      "sr_diff_higher_count",
      "sr_diff_lower_count",
      "mismatch_sr_and_cache",
      "users_scrolls_carousel"
    ] as const
  ).map(createCustomGoal)
});

const { stages, variant } = utils;

type Params = {
  travellersCount: number;
};
export const trackMdotFlexStages = ({ travellersCount }: Params) => {
  if (variant() > 0) {
    stages.main();

    if (isOfMetaOrigin()) {
      stages.meta();
    } else {
      stages.direct();
    }

    if (travellersCount > 1) {
      stages["2_plus"]();
    } else {
      stages.solo();
    }
  }
};

export default utils;
