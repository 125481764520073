import React, { useCallback, useState } from "react";
import { Link, Text } from "@bookingcom/bui-react";
import { ATOLLogo } from "./ATOLLogo";
import OrderDetailsCard from "../OrderDetailsInner/OrderDetailsCard";
import ATOLModal from "./ATOLModal";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { UIFlightSegment } from "@flights/types";

type ATOLPostBookingProtectionProps = {
  segments?: UIFlightSegment[];
};

const ATOLPostBookingProtection = (props: ATOLPostBookingProtectionProps) => {
  const { segments = [] } = props;
  const i18n = useI18n() as I18nChildContext;
  const [active, setActive] = useState(false);

  const onClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setActive(true);
  }, []);

  const onClose = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <>
      <OrderDetailsCard
        direction="col"
        heading={<ATOLLogo />}
        tail={
          <Link href="#" onClick={onClick}>
            {i18n.trans(t("flights_view_details_action"))}
          </Link>
        }
      >
        <Text variant="body_2">{i18n.trans(t("flights_atol_pb_header"))}</Text>
        <Text variant="body_2">{i18n.trans(t("flights_atol_pb_subheader"))}</Text>
      </OrderDetailsCard>
      <ATOLModal segments={segments} active={active} onClose={onClose} />
    </>
  );
};

export default ATOLPostBookingProtection;
