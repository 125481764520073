import { I18nChildContext } from "@bookingcom/lingojs-react";
import { SBSearchType } from "@bookingcom/flights-searchbox/@types/client";
import { t } from "@bookingcom/lingojs-core";

import { trackExperiment } from "utils/et";
import { UIBrandedFareInfo, UIFlightSegment, UIProductFlexibleTicket } from "@flights/types";
import { startCase, lowerCase } from "lodash";
import parse from "date-fns/parse";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

export const getModalTitle = (
  i18n: I18nChildContext,
  segments?: UIFlightSegment[],
  tripType?: SBSearchType,
  showTripTypeTitle?: boolean
): string => {
  if (!tripType || !segments) return ""; // loading state
  const destination = segments[0].arrivalAirport.cityName;
  const origin = segments[0].departureAirport.cityName;

  if (showTripTypeTitle) {
    if (tripType === "ROUNDTRIP" && destination) {
      return i18n.trans(t("flights_fd_rt_title", { variables: { destination } }));
    }
    if (tripType === "ONEWAY" && destination) {
      return i18n.trans(t("flights_fd_oneway_title", { variables: { destination } }));
    }
    if (tripType === "MULTISTOP" && origin) {
      return i18n.trans(t("flights_fd_multi_title", { variables: { origin } }));
    }
  }

  if (!destination) {
    return i18n.trans(t("flights_meta_land_next_flight_details_header_no_city"));
  }

  if (tripType === "ROUNDTRIP" && trackExperiment("flights_web_inbound_outbound_sr")) {
    return i18n.trans(t("flights_fd_rt_title", { variables: { destination } }));
  }

  if (tripType === "ONEWAY" || tripType === "ROUNDTRIP") {
    return i18n.trans(
      t("flights_meta_land_next_flight_details_header_city", { variables: { city_name: destination } })
    );
  }

  return i18n.trans(t("flights_details_name"));
};

export const getIncludedBaggageHeader = (
  i18n: I18nChildContext,
  brandedFareInfo?: UIBrandedFareInfo,
  flexibleTicket?: UIProductFlexibleTicket
) => {
  if (brandedFareInfo) {
    return `${i18n.trans(
      t("flights_fd_branded_header", {
        variables: {
          bf_name: `${startCase(lowerCase(brandedFareInfo.fareName))}`
        }
      })
    )} `.replace(":", "");
  }

  if (flexibleTicket) {
    return i18n.trans(t("flights_fd_standard_header"));
  }

  return i18n.trans(t("flights_included_baggage_title"));
};

export const getIncludedBaggageSubHeader = (
  i18n: I18nChildContext,
  brandedFareInfo?: UIBrandedFareInfo,
  flexibleTicket?: UIProductFlexibleTicket
) => {
  if (brandedFareInfo) {
    return i18n.trans(t("flights_fd_branded_subheader"));
  } else if (flexibleTicket) {
    return i18n.trans(t("flights_fd_standard_subheader"));
  }

  return i18n.trans(t("flights_included_baggage_sub"));
};

export const getBookWindowDays = (segments: UIFlightSegment[] | undefined): number | null => {
  if (!segments || segments.length === 0) {
    // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
    return null;
  }

  const departureDate = parse(segments[0].departureTime, "yyyy-MM-dd'T'HH:mm:ss", new Date());
  return differenceInCalendarDays(departureDate, new Date());
};
