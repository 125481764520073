import { UISupportedLocales } from "@flights/types";

export const OurLocalesToDateFNSLocaleMapping: { [key in UISupportedLocales]: string } = {
  ar: "ar-SA",
  bg: "bg",
  ca: "ca",
  cs: "cs",
  da: "da",
  de: "de",
  el: "el",
  "en-gb": "en-GB",
  "en-us": "en-US",
  es: "es",
  "es-ar": "es",
  "es-mx": "es",
  et: "et",
  fi: "fi",
  fr: "fr",
  he: "he",
  hi: "hi",
  hr: "hr",
  hu: "hu",
  id: "id",
  is: "is",
  it: "it",
  ja: "ja",
  ka: "ka",
  ko: "ko",
  lt: "lt",
  lv: "lv",
  ms: "ms",
  nl: "nl",
  no: "nb",
  pl: "pl",
  "pt-br": "pt-BR",
  "pt-pt": "pt",
  ro: "ro",
  ru: "ru",
  sk: "sk",
  sl: "sl",
  sr: "sr",
  sv: "sv",
  th: "th",
  // This is wrong. It should be `tl: fil`, but apparently this is not available on the current version (2.15.0) of date-fns. Will dig deeper later on.
  /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
  // TODO: fix this
  tl: "en-GB",
  tr: "tr",
  uk: "uk",
  vi: "vi",
  "zh-cn": "zh-CN",
  "zh-tw": "zh-TW"
};
