/**
 *
 * FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
 *
 */

import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { UIClientFetchError } from "@flights/types";
import { State } from "./state";

export enum ActionTypes {
  fetch = "paymentCollection/fetch",
  fetchSuccess = "paymentCollection/fetchSuccess",
  fetchError = "paymentCollection/fetchError",
  reset = "paymentCollection/reset"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),
  fetchSuccess: createAction((paymentCollection: State["paymentCollection"]) => ({
    type: ActionTypes.fetchSuccess,
    payload: { paymentCollection }
  })),
  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  })),
  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
