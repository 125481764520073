import { UIBrandedFareOrFlexTicket, UIClientMetricName, UIFlightData, UIPrice } from "@flights/types";
import { I18nChildContext, t } from "@bookingcom/lingojs-react";
import { priceToValue } from "utils/format-price";

export function convertFlightToFare(flight: UIFlightData, i18n: I18nChildContext): UIBrandedFareOrFlexTicket {
  const features = flight.brandedFareInfo?.features || [];
  return {
    brandedFareInfo: {
      fareName: i18n.trans(t("flights_apex_sr_standard_ticket")),
      features
    },
    token: flight.token,
    offerReference: flight.offerReference || flight.token,
    priceBreakdown: flight.priceBreakdown,
    travellerPrices: flight.travellerPrices,
    includedProducts: flight.includedProducts,
    includedProductsBySegment: flight.includedProductsBySegment
  };
}

export function getBrandedFarePriceMismatchMetricName(
  baseOfferPrice: UIPrice,
  brandedFarePrice: UIPrice
): UIClientMetricName {
  const baseOfferPriceValue = priceToValue(baseOfferPrice);
  const brandedFarePriceValue = priceToValue(brandedFarePrice);
  const difference = (brandedFarePriceValue - baseOfferPriceValue) / baseOfferPriceValue;

  if (difference === 0) {
    return "branded_fares_on_search_results_first_bf_price_is_equal";
  } else if (difference < -0.05) {
    return "branded_fares_on_search_results_first_bf_price_is_lower_difference_gt_5_percent";
  } else if (difference < 0) {
    return "branded_fares_on_search_results_first_bf_price_is_lower_difference_lte_5_percent";
  } else if (difference > 0.05) {
    return "branded_fares_on_search_results_first_bf_price_is_higher_difference_gt_5_percent";
  } else {
    return "branded_fares_on_search_results_first_bf_price_is_higher_difference_lte_5_percent";
  }
}
