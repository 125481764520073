import { CFAR_TARGET_COUNTRIES, CFAR_TEST_COUNTRIES } from "constants/ancillaries";
import flights_ace_web_cfar_target_markets from "../utils/experiments/ace/flights_ace_web_cfar_target_markets";
import flights_ace_web_flexibilty_sr_page from "../utils/experiments/ace/flights_ace_web_flexibilty_sr_page";
import flights_ace_web_cfar_test_markets from "../utils/experiments/ace/flights_ace_web_cfar_test_markets";
import usePointOfSale from "./usePointOfSale";
import { useSelector } from "react-redux";
import { getFlight } from "store/flightDetails/selectors";
import { getBookWindowDays } from "components/elements/FlightDetailsInner/components/flightDetailsUtils";

interface UICFARAncillaryValues {
  isCfarVariant: boolean;
  isCfarTargetMarket: boolean;
  isCfarTestMarket: boolean;
  isCfarTestMarketVariant: number;
  bookWindowDays: number | null;
}

export default function useCFARAncillary(bookWindow?: number): UICFARAncillaryValues {
  const pos = usePointOfSale();
  const flight = useSelector(getFlight);
  const bookWindowDays = bookWindow || getBookWindowDays(flight?.segments);
  const isCfarTargetMarket = CFAR_TARGET_COUNTRIES.includes(pos || "");
  const isCfarTargetMarketVariant = !!flights_ace_web_cfar_target_markets.variant();
  const isCfarTestMarket = CFAR_TEST_COUNTRIES.includes(pos || "");
  const isCfarTestMarketVariant = flights_ace_web_cfar_test_markets.variant();
  const isFlexibilityOnSearchResultsVariant = !!flights_ace_web_flexibilty_sr_page.variant();
  const isCfarVariant =
    (isCfarTargetMarket && isCfarTargetMarketVariant) ||
    (isCfarTestMarket && !!isCfarTestMarketVariant) ||
    (isCfarTestMarket && isFlexibilityOnSearchResultsVariant && !!bookWindowDays && bookWindowDays <= 90);

  return { isCfarVariant, isCfarTargetMarket, isCfarTestMarket, isCfarTestMarketVariant, bookWindowDays };
}
