import { Carousel, Stack, Image, Text, Card, Actionable } from "@bookingcom/bui-react";
import React, { ComponentProps, useCallback, useMemo } from "react";
import useFormatPrice from "hooks/useFormatPrice";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { UIPrice, UISearchMinPriceInfo } from "@flights/types";
import { useCreateFlexUrl } from "./useCreateFlexUrl";
import styles from "./FlexibleDateBestPriceInner.module.css";
import useFlexPriceDiffTracker from "hooks/useFlexPriceDiffTracker";

type Common = {
  cheaperCount: number;
  isOneWay: boolean;
  currentBestPrice: UIPrice;
  departureDate: string;
  returnDate?: string;
  isReferencePriceRounded?: boolean; // Used in case Search Results prices are rounded
  hidePriceFromHeader?: boolean; // Used on mobile, where we don't mention prices on the header title
};
type TitleProps = Common;

const Title = ({
  cheaperCount,
  isOneWay,
  currentBestPrice,
  departureDate,
  returnDate,
  isReferencePriceRounded = false,
  hidePriceFromHeader = false
}: TitleProps) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { formatPrice } = useFormatPrice();

  const title = useMemo(() => {
    if (cheaperCount === 0) {
      return i18n.trans(t("flights_sr_flex_dates_best_price"));
    }

    if (cheaperCount === 1) {
      return i18n.trans(t("flights_sr_flex_date_better_price_one"));
    }

    return i18n.trans(t("flights_sr_flex_date_better_prices_multi"));
  }, [cheaperCount, i18n]);

  const subtitle = useMemo(() => {
    if (hidePriceFromHeader) {
      return i18n.trans(t("flights_sr_flex_dates_subtitle_legal"));
    }

    if (isOneWay) {
      return i18n.trans(
        t("flights_sr_flex_dates_your_search_one_way", {
          variables: {
            date: formats.flightDateWeekday(departureDate),
            price: formatPrice(currentBestPrice, { decimalPlaces: isReferencePriceRounded ? 0 : undefined })
          }
        })
      );
    }

    return i18n.trans(
      t("flights_sr_flex_dates_your_search_rt_multi", {
        variables: {
          begin_date: formats.flightDateWeekday(departureDate),
          end_date: returnDate ? formats.flightDateWeekday(returnDate) : "",
          price: formatPrice(currentBestPrice, { decimalPlaces: isReferencePriceRounded ? 0 : undefined })
        }
      })
    );
  }, [
    currentBestPrice,
    departureDate,
    formatPrice,
    formats,
    hidePriceFromHeader,
    i18n,
    isOneWay,
    isReferencePriceRounded,
    returnDate
  ]);

  return (
    <Stack direction="row" alignItems="center">
      <Image
        width="40px"
        height="40px"
        asset={{
          setName: "illustrations-traveller",
          assetName: "FlightsSearch"
        }}
      />
      <Stack.Item shrink={true} grow={true}>
        <Stack gap={0}>
          <Text variant="strong_1" tagName="h2">
            {title}
          </Text>
          <Text variant="body_2">{subtitle}</Text>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

type UISearchMinPriceInfoWithPrice = Omit<UISearchMinPriceInfo, "price"> &
  Required<Pick<UISearchMinPriceInfo, "priceRounded">> & {
    isExpensive: boolean;
  };
type PricesCarouselProps = {
  dates: Array<UISearchMinPriceInfoWithPrice>;
  onCarouselScroll: VoidFunction;
  onItemClick: VoidFunction;
  carouselSize: ComponentProps<typeof Carousel>["size"];
} & Common;

const FlexibleDateBestPriceInner = ({
  onCarouselScroll,
  onItemClick,
  carouselSize,
  dates,
  cheaperCount,
  isOneWay,
  currentBestPrice,
  departureDate,
  returnDate,
  isReferencePriceRounded = false,
  hidePriceFromHeader = false
}: PricesCarouselProps) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { formatPrice } = useFormatPrice();
  const createUrl = useCreateFlexUrl();
  const { setOriginPrice } = useFlexPriceDiffTracker();

  const cardDate = useCallback(
    (departureDate: string, returnDate?: string) => {
      if (isOneWay) {
        return formats.flightDate(departureDate);
      }

      return i18n.trans(
        t("flights_sr_flex_dates_alt_date_rt_multi", {
          variables: {
            begin_date: formats.flightDate(departureDate),
            end_date: returnDate ? formats.flightDate(returnDate) : ""
          }
        })
      );
    },
    [formats, i18n, isOneWay]
  );

  return (
    <Carousel
      size={carouselSize}
      onAfterNavigate={onCarouselScroll}
      title={
        <Title
          isOneWay={isOneWay}
          cheaperCount={cheaperCount}
          currentBestPrice={currentBestPrice}
          departureDate={departureDate}
          returnDate={returnDate}
          isReferencePriceRounded={isReferencePriceRounded}
          hidePriceFromHeader={hidePriceFromHeader}
        />
      }
      nextButtonAriaLabel={i18n.trans(t("a11y_flights_sr_flex_dates_carousel_forward"))}
      previousButtonAriaLabel={i18n.trans(t("a11y_flights_sr_flex_dates_carousel_back"))}
      ariaLabel={i18n.trans(t("a11y_flights_sr_flex_dates_carousel_entry"))}
    >
      {dates.map((date) => (
        <Actionable
          key={date.departureDate}
          href={createUrl(date.offsetDays, date.departureDate, date.returnDate)}
          className={styles.anchor}
          onClick={() => {
            onItemClick();
            setOriginPrice(currentBestPrice.units);
          }}
        >
          <Card variant="elevated" className={styles.card}>
            <Text align="center" variant="body_2">
              {cardDate(date.departureDate, date.returnDate)}
            </Text>
            <Text
              align="center"
              variant="strong_2"
              color={date.isCheapest ? "constructive" : date.isExpensive ? "destructive" : undefined}
            >
              {formatPrice(date.priceRounded, { decimalPlaces: 0 })}
            </Text>
          </Card>
        </Actionable>
      ))}
    </Carousel>
  );
};

export default FlexibleDateBestPriceInner;
