import React, { FC } from "react";

import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { HiddenVisually, Text, Badge } from "@bookingcom/bui-react";

import { UIFlightSegment } from "@flights/types";
import formatting from "../../../../utils/formatting";
import useLocaleContext from "../../../../hooks/useLocaleContext";
import getTechnicalStops from "utils/get-technical-stops";
import Frame from "components/elements/Frame";
import styles from "./FlightCardSegment.mobile.module.css";
import { t } from "@bookingcom/lingojs-core";
import { SPACER_PADDED } from "../../../../constants";
import useGlobalContext from "hooks/useGlobalContext";
import trackDirectToNonStopEnUs from "../../../../utils/experiments/funnel/flights_web_direct_to_nonstop_en_us";

const FlightCardSegmentMobile: FC<{ segment: UIFlightSegment; idx: number }> = ({ segment, idx }) => {
  const {
    departureTime,
    departureAirport,
    arrivalTime,
    arrivalAirport,
    totalTime,
    showWarningOriginAirport,
    showWarningDestinationAirport
  } = segment;

  const { isRTL } = useLocaleContext();

  if (!departureTime || !departureAirport || !arrivalTime || !arrivalAirport || !totalTime) {
    throw new Error("Invalid flight data in FlightCard");
  }

  return (
    <Frame>
      <Frame direction="row" justifyContent="space-between">
        <TimeAndDateBlock
          airport={departureAirport.code}
          timestamp={departureTime}
          alignment={isRTL ? "right" : "left"}
          showWarning={showWarningOriginAirport}
          testAttributes={{
            time: `flight_card_segment_departure_time_${idx}`,
            date: `flight_card_segment_departure_date_${idx}`,
            airport: `flight_card_segment_departure_airport_${idx}`
          }}
        />
        <Stops
          segment={segment}
          dataTestId={{
            duration: `flight_card_segment_duration_${idx}`,
            stops: `flight_card_segment_stops_${idx}`
          }}
        />
        <TimeAndDateBlock
          airport={arrivalAirport.code}
          timestamp={arrivalTime}
          alignment={isRTL ? "left" : "right"}
          showWarning={showWarningDestinationAirport}
          testAttributes={{
            time: `flight_card_segment_destination_time_${idx}`,
            date: `flight_card_segment_destination_date_${idx}`,
            airport: `flight_card_segment_destination_airport_${idx}`
          }}
        />
      </Frame>
    </Frame>
  );
};

const TimeAndDateBlock: FC<{
  timestamp: string;
  airport: string;
  alignment: "left" | "right";
  showWarning?: boolean;
  testAttributes?: {
    time: string;
    airport: string;
    date: string;
  };
}> = ({ timestamp, airport, alignment, showWarning, testAttributes }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  const timeFormatted = formats.flightTime(timestamp);
  const usTimeMatch = timeFormatted.match(/(.+)(AM|PM)$/);

  return (
    <div className={styles.timeDateBlock} style={{ textAlign: alignment }}>
      <Text variant="strong_2" attributes={{ "data-testid": `${testAttributes?.time}` }}>
        {usTimeMatch?.length === 3 ? (
          <>
            {usTimeMatch[1]}
            <Text tagName="span" variant="small_2">
              {usTimeMatch[2]}
            </Text>
          </>
        ) : (
          timeFormatted
        )}
      </Text>
      <Frame
        direction="row"
        justifyContent={alignment === "left" ? "flex-start" : "flex-end"}
        className={styles.iataAndDate}
      >
        <Text
          variant="small_1"
          className={showWarning ? styles.iataWarning : ""}
          attributes={{ "data-testid": `${testAttributes?.airport}` }}
        >
          {airport}
        </Text>
        <Text variant="small_1" className={styles.iataDateSeparator}>
          {SPACER_PADDED}
        </Text>
        <Text variant="small_1" attributes={{ "data-testid": `${testAttributes?.date}` }}>
          {formats.flightDate(timestamp)}
        </Text>
      </Frame>
    </div>
  );
};

const Stops: FC<{
  segment: UIFlightSegment;
  dataTestId?: {
    duration: string;
    stops: string;
  };
}> = ({ segment, dataTestId }) => {
  const { legs, totalTime } = segment;
  const i18n = useI18n() as I18nChildContext;
  const { locale } = useGlobalContext();
  const timeInMinutes = totalTime / 60;
  const stopsCount = legs.length - 1;
  const technicalStopsCount = getTechnicalStops.fromSegment(segment).length;
  const showNonStop = !!trackDirectToNonStopEnUs.variant() && locale === "en-us";

  return (
    <Frame className={styles.stopsRoot} alignItems="center">
      <div className={styles.stopsWrap}>
        <div className={styles.stopsConnector}></div>

        <Badge
          text={
            stopsCount === 0
              ? showNonStop
                ? i18n.trans(t("flights_stops_nonstop_us"))
                : i18n.trans(t("flights_filter_stops_none"))
              : i18n.trans(
                  t("flights_route_num_stops", {
                    num_exception: stopsCount,
                    variables: { num_stops: stopsCount }
                  })
                )
          }
          variant={stopsCount === 0 ? "constructive" : "outline"}
          className={styles.stopsBadge}
          attributes={{ "data-testid": dataTestId?.stops }}
        />
      </div>

      <Text
        variant="small_2"
        className={styles.stopsTextStyle}
        attributes={{ "data-testid": dataTestId?.duration, "aria-hidden": "true" }}
      >
        {formatting.flightDuration(timeInMinutes, i18n)}
      </Text>
      <HiddenVisually>{formatting.flightDurationAria(timeInMinutes, i18n)}</HiddenVisually>

      {Boolean(technicalStopsCount) && (
        <Text variant="small_2">
          {i18n.trans(
            t("flights_sr_technical_stop", {
              variables: {
                num_stop: technicalStopsCount,
                num_exception: technicalStopsCount
              }
            })
          )}
        </Text>
      )}
    </Frame>
  );
};

export default FlightCardSegmentMobile;
