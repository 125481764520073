import { useCallback } from "react";
import useProfiler from "./useProfiler";
import { trackExperiment, trackExperimentStage } from "utils/et";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { UIFlightBadge } from "@flights/types";
import useUserAgent from "./useUserAgent";

export default function useExpCopyBestNewWord() {
  const i18n = useI18n();
  const profiler = useProfiler();
  const { isMobile } = useUserAgent();
  const expHash = "flights_web_copy_best_new_word";
  const variant = trackExperiment("flights_web_copy_best_new_word") || 0;

  const trackStages = useCallback(() => {
    trackExperimentStage(expHash, 1);
    if (profiler.isDesktop()) trackExperimentStage(expHash, 2);
    if (profiler.isMobile()) trackExperimentStage(expHash, 3);
    if (profiler.isDirect()) trackExperimentStage(expHash, 4);
    if (profiler.isMeta()) trackExperimentStage(expHash, 5);
    if (profiler.isSolo()) trackExperimentStage(expHash, 6);
    if (profiler.isOneWay()) trackExperimentStage(expHash, 7);
    if (profiler.isRountrip()) trackExperimentStage(expHash, 8);
    if (profiler.isMultiStop()) trackExperimentStage(expHash, 9);
  }, [expHash, profiler]);

  const getSortingTitle = useCallback(() => {
    trackStages();
    if (!variant) return;
    return variant === 1 ? i18n.trans(t("flights_sort_recommended")) : i18n.trans(t("flights_sort_top_picks"));
  }, [trackStages, variant, i18n]);

  const getSortingToltip = useCallback(() => {
    trackStages();
    if (!variant) return;
    return variant === 1
      ? i18n.trans(t("flights_search_recommended_ranking"))
      : i18n.trans(t("flights_search_top_pick_ranking"));
  }, [trackStages, variant, i18n]);

  const getLabel = useCallback(
    (text?: string, badgeType?: UIFlightBadge["type"]) => {
      if (!isMobile) trackStages();
      if (!variant) return;
      if (!badgeType || badgeType !== "BEST") return;
      if (!text || text !== i18n.trans(t("flights_search_label_best"))) return;
      return variant === 1 ? i18n.trans(t("flights_label_recommended")) : i18n.trans(t("flights_label_top_pick"));
    },
    [trackStages, variant, i18n, isMobile]
  );

  return {
    getSortingTitle,
    getSortingToltip,
    getLabel
  };
}
