import { groupBy, map, filter, forEach, isEqual, get, set } from "lodash";
import { INFANT_MAX_AGE } from "../../../constants";
import {
  UIAirportLocation,
  UIAvailableExtraProducts,
  UIFlightSegment,
  UIIncludedProductBySegment,
  UILuggageAllowance,
  UIProductCabinBaggagePerTraveller,
  UIProductCheckedInBaggageOption,
  UITraveller,
  UITravellerPrice
} from "@flights/types";

type ByType<T> = { personalItem?: T; cabin?: T; checkedIn?: T };
type ByTraveller<T> = { adult?: T; kid?: T; infant?: T };
type BySegment<T> = { eachSegment?: T; uniqueSegments?: T[] };
export type BaggageTravellerType = "adult" | "kid" | "infant";
export type BaggageType = "personalItem" | "cabin" | "checkedIn";
export type BaggageInclusionType = "included" | "partial" | "extra" | "not_available";
export type BaggageTraveller = { travellerType: BaggageTravellerType; travellerReference: string };
export type BaggageFromTo = { from: UIAirportLocation; to: UIAirportLocation };
export type BaggageExtraProduct = UIProductCabinBaggagePerTraveller | UIProductCheckedInBaggageOption;
export type BaggageItem = {
  type: BaggageType;
  segmentIndex: number;
  product: UILuggageAllowance;
  departureAirport: UIAirportLocation;
  arrivalAirport: UIAirportLocation;
  travellerType: BaggageTravellerType;
  travellerReference: string;
};
export type ExtraBaggageItem = {
  type: BaggageType;
  extraProduct: BaggageExtraProduct;
  travellerType: BaggageTravellerType;
  travellerReference: string;
};
export type BaggageStatus = { inclusionType: BaggageInclusionType; type: BaggageType; fromTo: BaggageFromTo[] };
export type BaggagesStatus = ByType<BaggageStatus>;
export type BaggagesDetails = {
  type: BaggageType;
  fromTo: BaggageFromTo[];
  includedProduct?: UILuggageAllowance;
  extraProduct?: BaggageExtraProduct;
};
export type BaggagesDetailsByType = ByType<BaggagesDetails>;
export type BaggagesDetailsBySegment = BySegment<BaggagesDetailsByType>;
export type BaggagesDetailedStatus = ByTraveller<BaggagesDetailsBySegment>;

export function getTravellersList(input: Partial<UITraveller & UITravellerPrice>[]): BaggageTraveller[] {
  return map(input, (traveller) => {
    const age = traveller.age;
    const type = traveller.type || traveller.travellerType;
    const travellerReference = traveller.travellerReference || "";
    let travellerType: BaggageTravellerType = "adult";
    if (type === "KID") travellerType = "kid";
    if (age !== undefined && age <= INFANT_MAX_AGE) travellerType = "infant";
    return { travellerType, travellerReference };
  });
}

export function getTravellersTypes(travellersList: BaggageTraveller[]): BaggageTraveller[] {
  return map(groupBy(travellersList, "travellerType"), (traveller, travellerType: BaggageTravellerType) => {
    return { travellerType, travellerReference: traveller?.[0]?.travellerReference || "" };
  });
}

export function getBaggagesList(input: {
  segments: UIFlightSegment[];
  travellersList: BaggageTraveller[];
  includedProductsBySegment: UIIncludedProductBySegment[];
}) {
  const output: BaggageItem[] = [];
  const { segments, travellersList, includedProductsBySegment } = input;
  const personaItemProduct: UILuggageAllowance = { luggageType: "PERSONAL_ITEM", maxPiece: 1 };
  forEach(travellersList, ({ travellerReference, travellerType }) => {
    forEach(segments, ({ departureAirport, arrivalAirport }, segmentIndex) => {
      forEach(includedProductsBySegment, (includedProducts, includedSegmentIndex) => {
        forEach(includedProducts, (includedProduct) => {
          forEach(includedProduct.travellerProducts, ({ product, type }) => {
            if (segmentIndex !== includedSegmentIndex) return;
            if (travellerReference !== includedProduct.travellerReference) return;
            const data = { travellerType, travellerReference, segmentIndex, departureAirport, arrivalAirport };
            if (type === "cabinBaggage") output.push({ ...data, product, type: "cabin" });
            if (type === "checkedInBaggage") output.push({ ...data, product, type: "checkedIn" });
            if (type === "personalItem") output.push({ ...data, product: personaItemProduct, type: "personalItem" });
          });
        });
      });
    });
  });
  return output;
}

export function getExtraBaggagesList(input: {
  ancillaries: UIAvailableExtraProducts;
  travellersList: BaggageTraveller[];
}) {
  const output: ExtraBaggageItem[] = [];
  const { ancillaries, travellersList } = input;
  const { cabinBaggage, checkedInBaggage, cabinBaggagePerTraveller } = ancillaries;
  forEach(travellersList, ({ travellerReference, travellerType }) => {
    const data = { travellerReference, travellerType };
    if (cabinBaggage) {
      const { priceBreakdown, massUnit, maxHeight, maxLength, maxWeight, maxWidth, sizeUnit } = cabinBaggage;
      const cabinProduct: UIProductCabinBaggagePerTraveller = {
        luggageAllowance: {
          luggageType: "HAND",
          maxPiece: 1,
          massUnit,
          maxWeightPerPiece: maxWeight,
          sizeRestrictions: { maxLength, maxHeight, maxWidth, sizeUnit }
        },
        priceBreakdown,
        travellers: travellersList.map((_) => _.travellerReference)
      };
      output.push({ ...data, extraProduct: cabinProduct, type: "cabin" });
    } else if (cabinBaggagePerTraveller?.travellers.includes(travellerReference))
      output.push({ ...data, extraProduct: cabinBaggagePerTraveller, type: "cabin" });
    forEach(checkedInBaggage?.options, (option) => {
      if (!option.travellers.includes(travellerReference)) return;
      output.push({ ...data, extraProduct: option, type: "checkedIn" });
    });
  });
  return output;
}

export function getStatus(input: {
  segments: UIFlightSegment[];
  travellerTypes: BaggageTraveller[];
  baggagesList: BaggageItem[];
  extraBaggagesList: ExtraBaggageItem[];
}) {
  const output: BaggagesStatus = {};
  const { segments, travellerTypes, baggagesList, extraBaggagesList } = input;
  const totalSegments = segments.length;
  const { travellerReference, travellerType } = filter(travellerTypes, { travellerType: "adult" })[0];
  const baggages = filter(baggagesList, { travellerReference, travellerType });
  const extraBaggages = filter(extraBaggagesList, { travellerReference });
  const baggagesByType = groupBy(baggages, "type");
  const extraBaggagesByType = groupBy(extraBaggages, "type");
  const allBaggagetypes: BaggageType[] = ["personalItem", "cabin", "checkedIn"];
  forEach(allBaggagetypes, (type) => {
    const isExtraProduct = !!extraBaggagesByType?.[type]?.length;
    const isAllSegments = baggagesByType?.[type]?.length === totalSegments;
    const isSomeSegments = baggagesByType?.[type]?.length > 0;
    let inclusionType: BaggageInclusionType = "not_available";
    if (isAllSegments) inclusionType = "included";
    if (!isAllSegments && isSomeSegments) inclusionType = "partial";
    if (!isAllSegments && !isSomeSegments && isExtraProduct) inclusionType = "extra";
    const fromTo: BaggageFromTo[] = map(baggagesByType?.[type], ({ arrivalAirport, departureAirport }) => ({
      from: departureAirport,
      to: arrivalAirport
    }));
    output[type] = { type, inclusionType, fromTo };
  });
  return output;
}

export function getDetailedStatus(input: {
  segments: UIFlightSegment[];
  travellerTypes: BaggageTraveller[];
  baggagesList: BaggageItem[];
  extraBaggagesList: ExtraBaggageItem[];
}) {
  const output: BaggagesDetailedStatus = {};
  const { segments, travellerTypes, baggagesList, extraBaggagesList } = input;
  const allBaggagetypes: BaggageType[] = ["personalItem", "cabin", "checkedIn"];
  forEach(travellerTypes, ({ travellerType, travellerReference }) => {
    const baggages = filter(baggagesList, { travellerType, travellerReference });
    const extraBaggages = filter(extraBaggagesList, { travellerType, travellerReference });
    forEach(allBaggagetypes, (type) => {
      const extraProduct = filter(extraBaggages, { type })?.[0]?.extraProduct;
      const all = map(segments, ({ departureAirport, arrivalAirport }, segmentIndex) => {
        const includedProduct = filter(baggages, { type, segmentIndex })?.[0]?.product;
        const fromTo: BaggageFromTo[] = [{ from: departureAirport, to: arrivalAirport }];
        return { type, fromTo, includedProduct, extraProduct, segmentIndex };
      });
      forEach(all, (baggage) => {
        const { type, includedProduct, fromTo, extraProduct, segmentIndex } = baggage;
        const repeated = all.every((_) => isEqual(_.includedProduct, includedProduct));
        // add baggages to eachFlight/uniqueSegments depends if they are repeated
        if (repeated) set(output, `${travellerType}.eachSegment.${type}`, { type, fromTo, includedProduct });
        else set(output, `${travellerType}.uniqueSegments[${segmentIndex}].${type}`, { type, fromTo, includedProduct });
        // add extra baggages to each flight
        if (extraProduct?.priceBreakdown) {
          const existing = get(output, `${travellerType}.eachSegment.${type}`, {});
          set(output, `${travellerType}.eachSegment.${type}`, { ...existing, type, fromTo, extraProduct });
        }
      });
    });
  });
  return output;
}
