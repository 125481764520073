import { Request } from "express";
import { ArgumentsInterface } from "@bookingcom/lingojs-core";
import { SupportedLocales } from "@bookingcom/lingojs-react";
import { CopyExperimentContext, CopyExperimentParams } from "@bookingcom/lingojs-experiments";

type OnTranslateFactoryType = {
  isIos?: boolean;
  isAndroid?: boolean;
  isMdot?: boolean;
  isDesktop?: boolean;
  country?: string;
};

export function onTranslateFactory(_: {
  config: OnTranslateFactoryType;
  locale: SupportedLocales;
  context: CopyExperimentContext;
  trackExp?: (tag: string) => number;
  trackStage?: (tag: string, stage: number) => void;
}) {
  const { config, locale, context, trackExp, trackStage } = _;
  const { isAndroid, isDesktop, isIos, isMdot, country } = config;
  const siteTypes = [isAndroid && "android", isIos && "iphone", isMdot && "mdot", isDesktop && "www"].filter(
    Boolean
  ) as CopyExperimentParams["siteTypes"];
  const actions = [] as CopyExperimentParams["actions"];
  const countries = (country ? [country] : []) as CopyExperimentParams["countries"];

  return function onTranslate(tag: string, vars?: ArgumentsInterface) {
    const data = context.getVariants(locale, tag, vars, { siteTypes, actions, countries });
    if (!data || !data.matchFound) return tag;
    if (!data.experimentTagName) return tag;
    const variant = trackExp?.(data.experimentTagName) || 0;
    const copyVariant = data.variants?.[variant - 1];
    if (!copyVariant) return tag;
    // Mutating the object reference is a bad practice but "onTranslate" does
    // not allow changing the item atm if not for this we won't be able to run
    // an experiment where one list item replaces another
    // @ts-ignore
    if (copyVariant.item && vars) vars["item"] = copyVariant.item;
    trackStage?.(data.experimentTagName, 1);
    return copyVariant.tag || tag;
  };
}

export function getOnTranslateConfig(req: Request): OnTranslateFactoryType {
  const isApp = req.isMobileApp;
  const isIos = isApp && req.isIOSApp;
  const isAndroid = isApp && req.isAndroidApp;
  const isMdot = !isApp && req.useragent?.isMobile;
  const isDesktop = !isApp && req.useragent?.isMobile;
  const country = req.geoIP?.countryCode;
  return { isIos, isAndroid, isMdot, isDesktop, country };
}
