import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { CartValidationError } from "@flights/types";

export enum ActionTypes {
  setErrorCode = "cartValidationError/setErrorCode",
  resetErrorCode = "cartValidationError/resetErrorCode",
  setHadPhoneNumberError = "cartValidationError/setHadPhoneNumberError",
  setHadEmailError = "cartValidationError/setHadEmailError",
  setPhoneNumberValidationTracked = "cartValidationError/setPhoneNumberValidationTracked",
  setEmailValidationTracked = "cartValidationError/setEmailValidationTracked"
}

export const actions = {
  setErrorCode: createAction((code: CartValidationError) => ({
    type: ActionTypes.setErrorCode,
    payload: { code }
  })),

  resetErrorCode: createAction(() => ({
    type: ActionTypes.resetErrorCode,
    payload: {}
  })),

  setHadPhoneNumberError: createAction(() => ({
    type: ActionTypes.setHadPhoneNumberError,
    payload: {}
  })),

  setHadEmailError: createAction(() => ({
    type: ActionTypes.setHadEmailError,
    payload: {}
  })),

  setPhoneNumberValidationTracked: createAction(() => ({
    type: ActionTypes.setPhoneNumberValidationTracked,
    payload: {}
  })),

  setEmailValidationTracked: createAction(() => ({
    type: ActionTypes.setEmailValidationTracked,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
