import React, { FC, useState, useCallback, MouseEvent, useMemo, useEffect, useRef } from "react";
import { Text, Button, useTheme, Card, Divider, Stack, HiddenVisually } from "@bookingcom/bui-react";
import BreakdownList from "./components/BreakdownList";
import DetailedBreakdownList from "./components/DetailedBreakdownList";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import {
  UIOrderExtras,
  UIPriceBreakdown,
  UIPassenger,
  UITravellerPrice,
  UITraveller,
  UISubsidizedFareType,
  UIFlightData,
  UIAirOrder
} from "@flights/types";
import useEventTracking from "../../../hooks/useEventTracking";
import useUserAgent from "../../../hooks/useUserAgent";

import useGlobalContext from "../../../hooks/useGlobalContext";
import { ATOLPriceBreakdownFooter } from "../ATOL";
import useModalDialog from "hooks/useModalDialog";
import InstalmentsBanner from "./components/InstalmentsBanner";
import isHungaryDepartingRyanAirFlight from "utils/hungaryTaxes";
import PriceTransparencyText from "./components/PriceTransparencyText";
import { trackCustomGoal } from "utils/et";
import useRouteName from "hooks/useRouteName";
import { MpProduct } from "@bookingcom/mp-flights";
import styles from "./PriceBreakdown.module.css";
import FlightAvailableSeats from "../FlightCard/components/FlightAvailableSeats";
import Frame from "../Frame";
import useFormatPrice from "hooks/useFormatPrice";
import { useCheckoutPlaceholder } from "../CheckoutPlaceholder";
import { actions as AriaLiveActions } from "store/ariaLive/actions";
import { useActions } from "../../../store";
import flights_ace_web_fast_track from "utils/experiments/ace/flights_ace_web_fast_track";

/** ! The current version of BUI is keeping the whole body un-scrollable after we close a BottomSheet. This "fixes" it;
 */
const resetBodyOverflow = () => {
  document.body.style.overflow = "auto";
};

type Props = {
  totalPrice: UIPriceBreakdown;
  travellers?: UIPassenger[] | UITraveller[];
  travellerPrices?: UITravellerPrice[];
  ancillaries?: UIOrderExtras;
  showTitle?: boolean;
  fullBreakdownAvailable?: boolean;
  showFlightDetails?: boolean;
  showAncillariesDetails?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  appliedSubsidizedFares?: UISubsidizedFareType[];
  priceTaxBreakdown?: any;
  isAtolProtected?: boolean; // passed from post booking
  flightData?: UIFlightData;
  totalTagName?: string;
  orderData?: UIAirOrder;
  multiProducts?: MpProduct[];
  showAvailableSeats?: boolean;
  showInTripSummaryHeader?: boolean; // flights_web_ddot_checkout_header_with_price
  showInPriceSummaryHideInstallments?: boolean; // flights_web_ddot_checkout_header_with_price
  showNewHeaderCopy?: boolean; // flights_web_ddot_checkout_header_with_price
  paymentCollectionToPay?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  paymentCollectionPaid?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  isBrandedFare?: boolean;
  isPC?: boolean;
};

const PriceBreakdown: FC<Props> = (props) => {
  const {
    totalPrice,
    travellers,
    travellerPrices,
    ancillaries,
    showTitle = true,
    fullBreakdownAvailable = true,
    showFlightDetails = false,
    showAncillariesDetails = false,
    onClose,
    onOpen,
    appliedSubsidizedFares,
    isAtolProtected = false,
    priceTaxBreakdown,
    flightData,
    totalTagName,
    orderData,
    multiProducts = [],
    showInTripSummaryHeader = false, // flights_web_ddot_checkout_header_with_price
    showInPriceSummaryHideInstallments = false, // flights_web_ddot_checkout_header_with_price
    showNewHeaderCopy = false, // flights_web_ddot_checkout_header_with_price
    paymentCollectionToPay, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    paymentCollectionPaid, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    showAvailableSeats = false,
    isPC = false, // flights_web_ddot_checkout_header_with_price
    isBrandedFare = false
  } = props;
  const theme = useTheme();
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();
  const Modal = useModalDialog();
  const [showFullBreakdown, setShowFullBreakdown] = useState<boolean>(false);
  const currentRoute = useRouteName();

  // flights_web_ddot_checkout_header_with_price -- start
  const wrapperref = useRef<HTMLDivElement>(null);
  const { updateTripSummaryPlaceholder } = useCheckoutPlaceholder();
  const ariaLiveActions = useActions(AriaLiveActions);
  const { formatPrice } = useFormatPrice();

  const totalPriceToDisplay = useMemo(() => {
    return totalPrice.totalWithInstalments || totalPrice.total;
  }, [totalPrice.totalWithInstalments, totalPrice.total]);

  const [currentAnnouncedTotalPrice, setCurrentAnnouncedTotalPrice] = useState(totalPriceToDisplay);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (
      !showInTripSummaryHeader ||
      !totalPriceToDisplay ||
      !currentAnnouncedTotalPrice ||
      (totalPriceToDisplay.units === currentAnnouncedTotalPrice.units &&
        totalPriceToDisplay.nanos === currentAnnouncedTotalPrice.nanos)
    )
      return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      ariaLiveActions.setMessage({
        message: i18n.trans(
          t("a11y_flights_aria_live_checkout_new_total_price", {
            variables: { price: formatPrice(totalPriceToDisplay) }
          })
        ),
        type: "polite"
      });
    }, 500);
    setCurrentAnnouncedTotalPrice(totalPriceToDisplay);
  }, [totalPriceToDisplay, currentAnnouncedTotalPrice, ariaLiveActions, i18n, formatPrice, showInTripSummaryHeader]);

  useEffect(() => {
    if (!showInTripSummaryHeader) return;
    updateTripSummaryPlaceholder(
      <Stack alignItems="end" gap={0}>
        <Text tagName="small" variant="small_2" color="neutral_alt">
          {i18n.trans(t("flights_book_process_price_total"))}
        </Text>
        <Text tagName="h2">{formatPrice(totalPriceToDisplay)}</Text>
        <Button.Aligner alignment="end">
          <Button
            variant="tertiary"
            onClick={({ nativeEvent }) => {
              handlePriceBreakdownClick(nativeEvent as any);
            }}
            attributes={{
              "data-testid": "price_breakdown_view_price_breakdown",
              "aria-describedby": "a11y_flights_price_details_cta"
            }}
          >
            {i18n.trans(t("flights_book_process_view_price_details_cta"))}
          </Button>
          <HiddenVisually>
            <span id="a11y_flights_price_details_cta">{i18n.trans(t("a11y_flights_price_details_cta"))}</span>
          </HiddenVisually>
        </Button.Aligner>
      </Stack>
    );
  }, [totalPriceToDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  const trackDetailsClicks = useCallback(() => {
    if (currentRoute === "checkout3" && !isPC) trackCustomGoal("flights_web_ddot_checkout_header_with_price", 1);
    if (currentRoute === "checkout3" && isPC) trackCustomGoal("flights_web_ddot_checkout_header_with_price", 2);
  }, [currentRoute, isPC]);
  // flights_web_ddot_checkout_header_with_price -- end

  const segments = flightData?.segments || orderData?.flightSegments;

  const onModalClose = () => {
    setShowFullBreakdown(false);
    resetBodyOverflow();
    onClose && onClose();
  };

  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("price_breakdown", requestId);

  const handlePriceBreakdownClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      trackDetailsClicks();
      setShowFullBreakdown(true);
      trackV2("click_view_price_breakdown");
      trackV2("click_price_breakdown");
      flights_ace_web_fast_track.goals.open_price_breakdown();
      onOpen && onOpen();
    },
    [setShowFullBreakdown, trackV2, onOpen, trackDetailsClicks]
  );

  const isPriceTransparencyCopyApplicable = useMemo(() => {
    //https://et.booking.com/experiment/1661770.html
    const priceTransparencyTextApplicableRoutes = [
      "checkout3",
      "checkout-ticket-type",
      "checkout-seat-selection",
      "checkout2", //ancillaries
      "checkout" //pax
    ];
    return priceTransparencyTextApplicableRoutes.includes(currentRoute);
  }, [currentRoute]);

  let showMissingPaymentMade = true;

  // We've discovered cases where the BE can a remaining amount greater than the total amount to be paid
  // This is to ensure that we are not displaying negative values or 0 values in the payment breakdown
  const paymentCollectionPaidAmount = parseFloat((paymentCollectionPaid || "").replace(/[^0-9.-]/g, ""));
  if (paymentCollectionPaidAmount <= 0) {
    showMissingPaymentMade = false;
  }

  // flights_web_ddot_checkout_header_with_price -- start
  useEffect(() => {
    if (!wrapperref?.current || !showInTripSummaryHeader) return;
    const disply = wrapperref.current.innerText === "" ? "none" : "block";
    wrapperref.current.style.display = disply;
  }, [wrapperref, showInTripSummaryHeader, props]);
  // flights_web_ddot_checkout_header_with_price -- end

  return (
    <div ref={wrapperref} data-testid="price_breakdown_popover">
      {!showInTripSummaryHeader && (
        <BreakdownList
          showFlightDetails={showFlightDetails}
          showAncillariesDetails={showAncillariesDetails}
          showTitle={showTitle}
          totalPrice={totalPrice}
          travellers={travellers}
          travellerPrices={travellerPrices}
          ancillaries={ancillaries}
          appliedSubsidizedFares={appliedSubsidizedFares}
          priceTaxBreakdown={priceTaxBreakdown}
          flightData={flightData}
          totalTagName={totalTagName}
          multiProducts={multiProducts}
          isRynaAirHungaryFlight={isHungaryDepartingRyanAirFlight(segments)}
          isBrandedFare={isBrandedFare}
          isOrder={!!orderData}
        />
      )}

      {isMobile && <InstalmentsBanner />}

      {!showInTripSummaryHeader && isPriceTransparencyCopyApplicable && !showNewHeaderCopy && <PriceTransparencyText />}

      {!showInTripSummaryHeader && <ATOLPriceBreakdownFooter isAtolProtected={isAtolProtected} />}

      {showAvailableSeats && flightData && (
        <Frame pb={4}>
          <FlightAvailableSeats flight={flightData} />
        </Frame>
      )}

      {/* Ryan Air price breakdown disclaimer for Hungary */}
      {isHungaryDepartingRyanAirFlight(segments) && (
        <Card>
          <Text variant="small_2">{i18n.trans(t("flights_ryanair_hungary_price_breakdown_bottom_hed"))}</Text>
          <Text variant="small_2">{i18n.trans(t("flights_ryanair_hungary_price_breakdown_bottom"))}</Text>
        </Card>
      )}

      {/* FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE */}
      {/* ================================================================== */}
      {paymentCollectionToPay || paymentCollectionPaid ? (
        <Stack gap={3}>
          <Stack.Item>
            <Divider />
          </Stack.Item>
          {paymentCollectionPaid && showMissingPaymentMade && (
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="strong_2">{i18n.trans(t("flights_price_breakdown_missing_payment_paid"))}</Text>
              </Stack.Item>
              <Stack.Item>
                <Text variant="strong_2">{paymentCollectionPaid}</Text>
              </Stack.Item>
            </Stack>
          )}
          {paymentCollectionToPay && (
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="strong_2">{i18n.trans(t("flights_price_breakdown_missing_payment_to_pay"))}</Text>
              </Stack.Item>
              <Stack.Item>
                <Text variant="strong_2">{paymentCollectionToPay}</Text>
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      ) : undefined}
      {/* ================================================================== */}
      {/* FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE */}

      {!showInTripSummaryHeader && fullBreakdownAvailable && (
        <div style={{ paddingTop: theme.units.spacing_2x }}>
          <a
            className={styles.viewBreakdownLink}
            href="#"
            onClick={(e) => {
              handlePriceBreakdownClick(e);
            }}
          >
            <Text
              variant="body_2"
              attributes={{
                "data-testid": "price_breakdown_view_price_breakdown",
                "aria-label": i18n.trans(t("flights_price_breakdown_view"))
              }}
            >
              {showNewHeaderCopy
                ? i18n.trans(t("flights_book_process_view_price_details_cta"))
                : i18n.trans(t("flights_price_breakdown_view"))}
            </Text>
          </a>
        </div>
      )}

      {!isMobile && !showInPriceSummaryHideInstallments && <InstalmentsBanner />}

      <Modal
        title={
          showInTripSummaryHeader || showNewHeaderCopy
            ? i18n.trans(t("flights_price_details_popover_title"))
            : i18n.trans(t("flights_price_breakdown"))
        }
        subtitle={
          showInTripSummaryHeader || showNewHeaderCopy
            ? i18n.trans(t("flights_price_details_popover_subtitle_usp"))
            : undefined
        }
        closeAriaLabel={showInTripSummaryHeader ? i18n.trans(t("a11y_flights_close_price_details")) : undefined}
        onCloseTrigger={onModalClose}
        active={showFullBreakdown}
      >
        <DetailedBreakdownList
          showTitle={false}
          showFlightDetails
          showAncillariesDetails
          totalPrice={totalPrice}
          travellers={travellers}
          travellerPrices={travellerPrices}
          ancillaries={ancillaries}
          appliedSubsidizedFares={appliedSubsidizedFares}
          flightData={flightData}
          instalmentsFees={totalPrice.instalmentsFees}
          orderData={orderData}
          multiProducts={multiProducts}
          isRynaAirHungaryFlight={isHungaryDepartingRyanAirFlight(segments)}
          isBrandedFare={isBrandedFare}
        />
        <ATOLPriceBreakdownFooter isAtolProtected={isAtolProtected} />
        {!isMobile && showInTripSummaryHeader && (
          <>
            {showInPriceSummaryHideInstallments ? (
              <Frame mt={4} mb={4}>
                <InstalmentsBanner />
              </Frame>
            ) : undefined}
            {isHungaryDepartingRyanAirFlight(segments) && (
              <Card>
                <Text variant="small_2">{i18n.trans(t("flights_ryanair_hungary_price_breakdown_bottom_hed"))}</Text>
                <Text variant="small_2">{i18n.trans(t("flights_ryanair_hungary_price_breakdown_bottom"))}</Text>
              </Card>
            )}
          </>
        )}
        {!(showInTripSummaryHeader || showNewHeaderCopy) && (
          <Button
            onClick={onModalClose}
            text={i18n.trans(t("close"))}
            attributes={{ "data-testid": "price_breakdown_close" }}
            wide={isMobile}
          />
        )}
      </Modal>
    </div>
  );
};

export default PriceBreakdown;
