import { createTrackingUtilsV2 } from "..";

const utils = createTrackingUtilsV2({
  name: "flights_apex_web_move_baggage_features_to_server",
  stages: { main: 1, sellable_required: 2 },
  goals: {},
  goalsWithValue: []
});

export default utils;
