import {
  UIAvailableExtraProducts,
  UIClientFetchError,
  UIProductSelected,
  UIProductType,
  UIPreOptInProduct,
  UIPreOptInProductType,
  UIPageLoadTime
} from "@flights/types";
import omit from "lodash/omit";
import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";

export enum ActionTypes {
  fetch = "extraProducts/fetch",
  fetchSuccess = "extraProducts/fetchSuccess",
  fetchError = "extraProducts/fetchError",
  addProduct = "extraProducts/addProduct",
  setProducts = "extraProducts/setProducts",
  removeProduct = "extraProducts/removeProduct",
  removeAllProducts = "extraProducts/removeAllProducts",
  reset = "extraProducts/reset",
  addPreOptInProduct = "extraProducts/addPreOptInProduct",
  removePreOptInProduct = "extraProducts/removePreOptInProduct",
  resetPreOptIn = "extraProducts/resetPreOptIn",
  resetSelectedExtraProducts = "extraProducts/resetSelectedExtraProducts",
  setPageLoadTime = "extraProducts/setPageLoadTime"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((extraProducts: UIAvailableExtraProducts) => ({
    type: ActionTypes.fetchSuccess,
    payload: { extraProducts: omit(extraProducts, ["_debug"]) }
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  })),

  addProduct: createAction((productType: UIProductType, product: UIProductSelected) => ({
    type: ActionTypes.addProduct,
    payload: { product, productType }
  })),

  setProducts: createAction((productType: UIProductType, products: UIProductSelected[]) => ({
    type: ActionTypes.setProducts,
    payload: { products, productType }
  })),

  removeProduct: createAction(
    (
      productType: UIProductType,
      airProductReference?: string,
      travellerReference?: string,
      segmentIndex?: number,
      legIndex?: number
    ) => ({
      type: ActionTypes.removeProduct,
      payload: { productType, airProductReference, travellerReference, segmentIndex, legIndex }
    })
  ),

  removeAllProducts: createAction((productType: UIProductType) => ({
    type: ActionTypes.removeAllProducts,
    payload: { productType }
  })),

  addPreOptInProduct: createAction((productType: UIPreOptInProductType, product: UIPreOptInProduct) => ({
    type: ActionTypes.addPreOptInProduct,
    payload: { productType, product }
  })),

  removePreOptInProduct: createAction((productType: UIPreOptInProductType) => ({
    type: ActionTypes.removePreOptInProduct,
    payload: { productType }
  })),

  resetPreOptIn: createAction(() => ({
    type: ActionTypes.resetPreOptIn,
    payload: {}
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  })),

  resetSelectedExtraProducts: createAction(() => ({
    type: ActionTypes.resetSelectedExtraProducts,
    payload: {}
  })),

  setPageLoadTime: createAction((pageLoadTime: UIPageLoadTime) => ({
    type: ActionTypes.setPageLoadTime,
    payload: pageLoadTime
  }))
};

export type Actions = ActionsUnion<typeof actions>;
