/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useState } from "react";

import { Button, Text, Link, Icon, Title } from "@bookingcom/bui-react";
import { CloseIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { useI18n, I18nChildContext, Trans } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import { useSelectorAppliedFilters } from "../../../store/selectors/appliedFilters";
import { useStore, useActions } from "../../../store";
import { actions as searchActions } from "../../../store/search/actions";

import SearchFiltersDisplayController from "../SearchFiltersDisplayController";
import Frame from "../Frame";
import { trackCustomGoal } from "utils/et";

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
/**
 * TODO Filters are represented using two different set of keys, think about how to consolidate this.
 */
type FilterKey =
  | "NUMBER_OF_STOPS"
  | "DURATION"
  | "JOURNEY_TIME_FILTER"
  | "AIRLINES"
  | "FLIGHT_TIMES_FILTER"
  | "LAYOVER_CONNECTION"
  | "MAX_BUDGET"
  | "MAX_BUDGET_PER_ADULT"
  | "INCLUDED_BAGGAGE"
  | "LAYOVER_DURATION"
  | "AIRPORTS_FILTER"
  | "PREFER_SAME_AIRPORT";

type StoreKeys =
  | "stops"
  | "multiSelectStops"
  | "duration"
  | "departureIntervals"
  | "arrivalIntervals"
  | "airlines"
  | "flightTimes"
  | "shortLayoverConnection"
  | "maxBudget"
  | "maxBudgetPerAdult"
  | "includedBaggage"
  | "maxLayoverDuration"
  | "airports"
  | "preferSameAirport";

type FilterMeta = {
  copy: string;
  storeKeys: StoreKeys[];
};

type FiltersMeta = {
  [key in FilterKey]: FilterMeta;
};

type FilterRowProps = {
  filterMeta: FilterMeta;
  resetFilter: (props: StoreKeys[]) => void;
};

const FilterRow: React.FunctionComponent<FilterRowProps> = ({ filterMeta, resetFilter }) => {
  const i18n = useI18n() as I18nChildContext;
  const { copy, storeKeys } = filterMeta;

  if (!copy || !storeKeys) return null;

  return (
    <Frame direction="row" justifyContent="space-between" alignItems="center">
      <Text variant="body_2">{copy}</Text>
      <Button
        variant="light"
        onClick={() => {
          resetFilter(storeKeys);
        }}
        icon={<Icon svg={CloseIcon} size="small" />}
        attributes={{
          "aria-label": `${i18n.trans(t("flights_filter_reset"))} ${copy}`,
          "data-testid": `search_filters_summary_reset_filter_${storeKeys}`
        }}
      ></Button>
    </Frame>
  );
};

const SearchFiltersSummary: React.FunctionComponent = () => {
  const SHOWN_FILTERS_COUNT_INITIAL = 3;

  const store = useStore();
  const appliedFilters = useSelectorAppliedFilters(store);
  const actions = useActions(searchActions);
  const i18n = useI18n() as I18nChildContext;

  const filtersCount = appliedFilters.length;

  const [displayedFiltersCount, setDisplayedFiltersCount] = useState(SHOWN_FILTERS_COUNT_INITIAL);

  const {
    searchResults: {
      availableFilters: { filteredTotalCount = 0 }
    }
  } = store;

  const filtersMeta: FiltersMeta = {
    NUMBER_OF_STOPS: {
      copy: i18n.trans(t("flights_filter_stops")),
      storeKeys: ["stops", "multiSelectStops"]
    },
    DURATION: {
      copy: i18n.trans(t("flights_filter_duration")),
      storeKeys: ["duration"]
    },
    JOURNEY_TIME_FILTER: {
      copy: i18n.trans(t("flights_filter_flight_time")),
      storeKeys: ["departureIntervals", "arrivalIntervals"]
    },
    AIRLINES: {
      copy: i18n.trans(t("flights_filter_airlines")),
      storeKeys: ["airlines"]
    },
    FLIGHT_TIMES_FILTER: {
      copy: i18n.trans(t("flights_filters_times_title")),
      storeKeys: ["flightTimes"]
    },
    LAYOVER_CONNECTION: {
      copy: i18n.trans(t("flights_filters_duration_layover")),
      storeKeys: ["shortLayoverConnection"]
    },
    MAX_BUDGET: {
      copy: i18n.trans(t("flights_filters_budget_title")),
      storeKeys: ["maxBudget"]
    },
    MAX_BUDGET_PER_ADULT: {
      copy: i18n.trans(t("flights_filter_price_title")),
      storeKeys: ["maxBudgetPerAdult"]
    },
    INCLUDED_BAGGAGE: {
      copy: i18n.trans(t("flights_filters_baggage_title")),
      storeKeys: ["includedBaggage"]
    },
    LAYOVER_DURATION: {
      copy: i18n.trans(t("flights_filters_duration_layover")),
      storeKeys: ["maxLayoverDuration"]
    },
    AIRPORTS_FILTER: {
      copy: i18n.trans(t("flights_filters_airports_title")),
      storeKeys: ["airports"]
    },
    PREFER_SAME_AIRPORT: {
      copy: i18n.trans(t("flights_filters_airports_same_check")),
      storeKeys: ["preferSameAirport"]
    }
  };

  const resetAllFilters = () => {
    trackCustomGoal("flights_web_ddot_sr_wide_page_layout", 5);
    actions.resetFilters();
  };

  const resetFilter = (filters: StoreKeys[]) => {
    filters.forEach((filter) => actions.resetFilter(filter));
  };

  const showMoreCopy = i18n.trans(t("flights_filters_show_more_action"));
  const showLessCopy = i18n.trans(t("flights_filters_show_less_action"));

  return (
    <>
      {/* Header */}
      <Frame mb={4}>
        <Frame direction="row" justifyContent="space-between">
          <Title title={i18n.trans(t("flights_filters_title"))} variant="strong_1" titleTagName="h2" />
          {filtersCount > 0 && (
            <Link onClick={resetAllFilters} attributes={{ "data-testid": "search_filters_summary_reset_all" }}>
              <Trans tag="flights_filters_reset_all_action" />
            </Link>
          )}
        </Frame>

        <Text
          variant="body_2"
          color="neutral_alt"
          attributes={{ "data-testid": "search_filters_summary_results_number" }}
        >
          <Trans
            tag="flights_filters_num_results"
            variables={{
              num_results: filteredTotalCount
            }}
            num_exception={filteredTotalCount}
          />
        </Text>
      </Frame>

      {/* Applied filters */}
      {appliedFilters.slice(0, displayedFiltersCount).map((filter: FilterKey, index) => (
        <Frame key={index} mb={3}>
          <FilterRow filterMeta={filtersMeta[filter]} resetFilter={resetFilter} />
        </Frame>
      ))}

      <SearchFiltersDisplayController
        displayedRowsCount={displayedFiltersCount}
        setDisplayedRowsCount={setDisplayedFiltersCount}
        defaultDisplayedRowsCount={SHOWN_FILTERS_COUNT_INITIAL}
        allRowsCount={filtersCount}
        showMoreCopy={showMoreCopy}
        showLessCopy={showLessCopy}
      />
    </>
  );
};

export default SearchFiltersSummary;
