/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useState } from "react";
import { Stack, InputCheckbox, Button, Link, Text } from "@bookingcom/bui-react";
import useSearchFiltersController from "../useSearchFiltersController";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { ArrowNavUpIcon, ArrowNavDownIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useUserAgent from "hooks/useUserAgent";
import { trackExperiment } from "utils/et";
import styles from "./Airlines.module.css";
import { isOfMetaOrigin } from "utils/marketing-url-params";

type AirlinesControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["airlines"]>;

type AirlinesProps = {
  options: AirlinesControllerProps["options"];
  value: AirlinesControllerProps["value"];
  onChange: AirlinesControllerProps["set"];
  limit?: boolean;
  singleToggle?: boolean;
  /* start - flights_web_filters_count_blackout_desktop */
  hideCount?: boolean;
  /* stop - flights_web_filters_count_blackout_desktop */
};

export default function Airlines(props: AirlinesProps) {
  const { value, options, onChange, limit = false, singleToggle = false, hideCount = false } = props;
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const [hideLimit, setHidleLimit] = useState(limit);
  const showMoreCopy = i18n.trans(t("flights_filters_show_more_action"));
  const showLessCopy = i18n.trans(t("flights_filters_show_less_action"));
  const handleOnChange = useCallback(
    (checked: boolean, code: string) => onChange(checked ? [...value, code] : value.filter((v) => v !== code)),
    [value, onChange]
  );
  const handleOnlyThis = useCallback((code: string) => onChange([code]), [onChange]);
  const isRoundPrice =
    !isOfMetaOrigin() && (isMobile || (!isMobile && !!trackExperiment("flights_web_roundprice_sr_fd_direct_desktop")));
  /*enable round price only from direct not meta user*/

  return (
    <Stack gap={isMobile ? 4 : 2}>
      {options.map((airline, i) => {
        const isHidden = i >= 5 && hideLimit;
        return (
          <WithSuffix
            key={`${i}-${airline.value}`}
            suffix={!hideCount ? airline.count : undefined}
            attributes={{ style: { display: isHidden ? "none" : undefined } }}
          >
            <div className={styles.wrapper} data-testid="airlines_airline">
              <InputCheckbox
                label={
                  !!trackExperiment("flights_web_airlines_filter_price") ? (
                    <>
                      <Text variant="body_2">{airline.label}</Text>
                      <Text tagName="span" color="neutral_alt" variant="body_2">
                        {i18n.trans(
                          t("flights_filters_lowest_price", {
                            variables: { lowest_price: isRoundPrice ? airline.roundPrice : airline.price }
                          })
                        )}
                      </Text>
                    </>
                  ) : (
                    airline.label
                  )
                }
                value={airline.value}
                name={`airlines-filter-${airline.value}`}
                checked={value.includes(airline.value)}
                onChange={({ checked }) => handleOnChange(checked, airline.value)}
                attributes={{ "data-testid": `search_filter_airline_${airline.label}` }}
              />
              {singleToggle ? (
                <Link
                  onClick={() => handleOnlyThis(airline.value)}
                  attributes={{
                    "data-ui-only-this": true,
                    "data-testid": `search_filter_airline_only_this_${airline.label}`
                  }}
                >
                  {i18n.trans(t("flights_filters_airline_only_select"))}
                </Link>
              ) : null}
            </div>
          </WithSuffix>
        );
      })}
      {limit && options.length > 5 ? (
        <Stack.Item>
          <Button.Aligner alignment="start">
            <Button
              size="medium"
              iconPosition="end"
              variant="tertiary"
              text={hideLimit ? showMoreCopy : showLessCopy}
              icon={hideLimit ? ArrowNavDownIcon : ArrowNavUpIcon}
              onClick={() => setHidleLimit(!hideLimit)}
            />
          </Button.Aligner>
        </Stack.Item>
      ) : null}
    </Stack>
  );
}
