import { createTrackingUtilsV2 } from "../index";

const utils = createTrackingUtilsV2({
  name: "flights_web_ios_mdotvalue_searchbox_segment_focus",
  stages: {
    clicked_on_input: 1,
    signed_in: 2,
    signed_out: 3,
    paid_traffic: 4, // meta + ppc
    us_traffic: 5,
    key_markets: 6,
    direct_traffic: 7
  },
  goals: {
    clicked_on_input: 1,
    clicked_on_input_from: 2,
    clicked_on_input_to: 3,
    clicked_on_airport: 4,
    selected_airport_checkbox: 5
  },
  goalsWithValue: []
});

export default utils;
