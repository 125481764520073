/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Chip, SheetContainer, SheetContainerProps, Stack } from "@bookingcom/bui-react";
import BudgetPerAdult from "../Input/BudgetPerAdult";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { trackExperiment, trackExperimentStage } from "utils/et";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useUserAgent from "hooks/useUserAgent";
import { useStore } from "../../../../store";
import useFormatPrice from "hooks/useFormatPrice";
import Frame from "components/elements/Frame";

export type BudgetPerAdultModalProps = {
  position: SheetContainerProps["position"];
};

const BudgetPerAdultModalCom = (props: BudgetPerAdultModalProps) => {
  const { budgetPerAdult } = useSearchFiltersController();
  const i18n = useI18n();
  const [active, setActive] = useState(false);
  const { formatPrice } = useFormatPrice();
  const [shouldApply, setShouldApply] = useState(false);
  const [draftValue, setDraftValue] = useState(budgetPerAdult.value);

  const handleOnClose = useCallback(() => {
    setActive(false);
    setDraftValue(budgetPerAdult.value);
  }, [budgetPerAdult.value]);

  const handleOnChange = useCallback((value: typeof draftValue) => {
    setDraftValue(value);
  }, []);

  const handleOnApply = useCallback(() => {
    budgetPerAdult.set(draftValue);
    setActive(false);
    setShouldApply(true);
  }, [budgetPerAdult, draftValue]);

  const handleOnReset = useCallback(() => {
    budgetPerAdult.reset();
    setActive(false);
    setShouldApply(true);
  }, [budgetPerAdult]);

  useEffect(() => {
    if (shouldApply) {
      setShouldApply(false);
      setDraftValue(budgetPerAdult.value);
    }
  }, [shouldApply, budgetPerAdult]);

  const price = useCallback(
    (units: number) => formatPrice({ nanos: 0, units, currencyCode: budgetPerAdult.options.max.currencyCode }),
    [budgetPerAdult, formatPrice]
  );

  const label = useMemo(() => {
    if (budgetPerAdult.touched) {
      return i18n.trans(
        t("flights_mdot_filters_price_applied", { variables: { max_price: price(budgetPerAdult.value) } })
      );
    }
    return i18n.trans(t("flights_filter_price_title"));
  }, [i18n, price, budgetPerAdult]);

  return (
    <>
      <Chip variant="action" checked={budgetPerAdult.touched} onClick={() => setActive(true)} label={label} />
      <SheetContainer
        active={active}
        onClose={handleOnClose}
        onAfterClose={handleOnClose}
        onCloseTrigger={handleOnClose}
        closeAriaLabel={i18n.trans(t("close"))}
        title={i18n.trans(t("flights_filter_price_title"))}
        keepMounted={false}
        position={props.position}
        footer={
          <Frame mr={4} mb={4} ml={4}>
            <Stack direction="row">
              <Stack.Item grow>
                <Button
                  wide
                  onClick={handleOnReset}
                  disabled={!budgetPerAdult.touched}
                  variant="secondary"
                  size="large"
                >
                  {i18n.trans(t("flights_filter_reset"))}
                </Button>
              </Stack.Item>
              <Stack.Item grow>
                <Button wide onClick={handleOnApply} size="large">
                  {i18n.trans(t("flights_mdot_filters_apply_cta"))}
                </Button>
              </Stack.Item>
            </Stack>
          </Frame>
        }
      >
        <BudgetPerAdult
          min={budgetPerAdult.options.min}
          max={budgetPerAdult.options.max}
          value={draftValue}
          onChange={handleOnChange}
        />
      </SheetContainer>
    </>
  );
};

export default function BudgetPerAdultModal({ position }: BudgetPerAdultModalProps) {
  const {
    budgetPerAdult: { options }
  } = useSearchFiltersController();
  const isMeta = isOfMetaOrigin();
  const { isMobile } = useUserAgent();
  const store = useStore();
  const {
    searchCriteria: { adults, children }
  } = store;
  const travellersCount = (adults || 0) + (children || []).length;
  const canRender = options.max.units !== 0 && options.min.units !== 0 && options.min.units !== options.max.units;

  useEffect(() => {
    if (!canRender) return;
    trackExperimentStage("flights_web_filters_budget_per_adult", 1);
    trackExperimentStage("flights_web_filters_budget_per_adult", isMobile ? 3 : 2);
    trackExperimentStage("flights_web_filters_budget_per_adult", isMeta ? 5 : 4);
    trackExperimentStage("flights_web_filters_budget_per_adult", travellersCount === 1 ? 6 : 7);
  }, [canRender, isMobile, isMeta, travellersCount]);

  if (!canRender) return null;
  if (!trackExperiment("flights_web_filters_budget_per_adult")) return null;

  return <BudgetPerAdultModalCom position={position} />;
}
