import { UIFlightData, UIPriceBreakdown, UIProductFlexibleTicket } from "@flights/types";
import { sumPriceBreakdown } from "utils/price";

export const getFlexibleTicketFullPrice = (flight: UIFlightData) => {
  const flexibleTicket = flight.offerExtras?.flexibleTicket || flight.ancillaries?.flexibleTicket;

  if (!flexibleTicket) throw Error("Missing flexible ticket data");

  return sumPriceBreakdown([flight.priceBreakdown, getFlexibleTicketTotalUpgradePrice(flexibleTicket)]);
};

export const getFlexibleTicketPricePerPerson = (
  flexTicketPrice: UIPriceBreakdown | undefined,
  perPersonTicketPrice: UIPriceBreakdown | undefined
) => {
  if (!flexTicketPrice) throw Error("Missing flexible ticket data");
  if (!perPersonTicketPrice) throw Error("Missing PerPerson Ticket Price");

  return sumPriceBreakdown([perPersonTicketPrice, flexTicketPrice]);
};
export const getCfarTicketPricePerPerson = (
  cfarTicketPrice: UIPriceBreakdown,
  perPersonTicketPrice: UIPriceBreakdown | undefined
) => {
  if (!cfarTicketPrice) throw Error("Missing CFAR ticket data");
  if (!perPersonTicketPrice) throw Error("Missing PerPerson Ticket Price");

  return sumPriceBreakdown([perPersonTicketPrice, cfarTicketPrice]);
};

export const getFlexibleTicketTotalUpgradePrice = (flexibleTicket: UIProductFlexibleTicket): UIPriceBreakdown =>
  sumPriceBreakdown(flexibleTicket.travellers.map(() => flexibleTicket.priceBreakdown));

export const getCfarTicketFullPrice = (flight: UIFlightData): UIPriceBreakdown | undefined => {
  const cfarPrice = getCfarTicketTotalUpgradePrice(flight);

  if (!cfarPrice) return;

  return sumPriceBreakdown([flight.priceBreakdown, cfarPrice]);
};

export const getCfarTicketTotalUpgradePrice = (flight: UIFlightData): UIPriceBreakdown | undefined => {
  const cfarTicket = flight.offerExtras?.cancelForAnyReason || flight.ancillaries?.cancelForAnyReason;

  if (!cfarTicket) return;

  return cfarTicket.priceBreakdown;
};
