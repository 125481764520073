import { createTrackingUtilsV2 } from "utils/experiments";

export const trackFlightsMercuryPrefetchedFares = createTrackingUtilsV2({
  name: "flights_mercury_prefetched_fares",
  stages: {
    SEARCH_RESULTS_PAGE: 1,
    FLIGHT_DETAILS_PAGE: 2
  },
  goals: {},
  goalsWithValue: []
});
