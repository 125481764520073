import Debug from "debug";
import ExperimentEngine from "@bookingcom/experiment-tracking-clientside";
import { ExperimentEngineInterface } from "@bookingcom/experiment-tracking-clientside/dist/src/ExperimentEngine";
import { RequestContext } from "@flights/turbine";

import * as watchEt from "./watch-et";

const debug = Debug("utils:etV2");

let getAsyncRequestContext: () => RequestContext;
if (process.env.BUILD_TARGET === "server") {
  getAsyncRequestContext = require("@flights/turbine").getAsyncRequestContext;
}

const etV2Fallback: ExperimentEngineInterface & { sinkData: () => void } = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track: (hashedTagname: string) => {
    return 0;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stage: (hashedTagname: string, id: number) => {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customGoal(hashedTagname: string, goal: number) {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goal(goalName: string) {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goalWithValue(goalName: string, value: number): boolean {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateStateBlobAndVariants(clientsidePayload: string) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sinkData() {}
};

const getExperimentEngineInstance = () => {
  if (typeof window !== "undefined") {
    return ExperimentEngine.getInstance({
      // Library implements debounce and sinks batches of tracking data.
      sinkUrl: `${window?.__GLOBAL_CONTEXT__?.apiPrefix || ""}/track/sink`,
      clientsidePayload: (typeof window !== "undefined" && window?.__GLOBAL_CONTEXT__?.clientsidePayload) || "",
      sinkTimeoutMs: 500
    });
  } else {
    return etV2Fallback;
  }
};

const track = (hashedTagname: string) => {
  // reading variant from et daemon on the server to render correct version on frontend during SSR
  if (process.env.BUILD_TARGET === "server") {
    return getAsyncRequestContext()?.request.et?.trackExperiment(hashedTagname) || 0;
  } else {
    const experimentEngineInstance = getExperimentEngineInstance();

    debug("Tracking experiment", hashedTagname);
    watchEt.dispatchName(hashedTagname);

    return experimentEngineInstance.track(hashedTagname);
  }
};

const stage = (hashedTagname: string, id: number) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking stage", id, "for experiment", hashedTagname);
  watchEt.log([
    {
      type: "stage",
      name: hashedTagname,
      value: id
    }
  ]);

  return experimentEngineInstance.stage(hashedTagname, id);
};

const customGoal = (hashedTagname: string, goal: number) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking custom goal", goal, "for experiment", hashedTagname);
  watchEt.log([
    {
      type: "customGoal",
      name: hashedTagname,
      value: goal
    }
  ]);

  return experimentEngineInstance.customGoal(hashedTagname, goal);
};

const goal = (goalName: string) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking goal", goalName);
  watchEt.log([
    {
      type: "goal",
      name: goalName
    }
  ]);

  return experimentEngineInstance.goal(goalName);
};

const goalWithValue = (goalName: string, value: number) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking goal with value", goalName, "with value", value);
  watchEt.log([
    {
      type: "goalWithValue",
      name: goalName,
      value
    }
  ]);

  return experimentEngineInstance.goalWithValue(goalName, value);
};

const updateStateBlobAndVariants = (clientsidePayload: string) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Updating state blob and variants with clientside payload", clientsidePayload);

  return experimentEngineInstance.updateStateBlobAndVariants(clientsidePayload);
};

const sinkData = () => {
  const experimentEngineInstance = getExperimentEngineInstance();
  return experimentEngineInstance.sinkData();
};

export const etV2 = {
  track,
  stage,
  customGoal,
  goal,
  goalWithValue,
  updateStateBlobAndVariants,
  sinkData
};
