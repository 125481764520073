/* istanbul ignore file */
/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Icon,
  InputRadio,
  InputRadioGroup,
  ListItem,
  Popover,
  SheetContainer,
  SkeletonLoader,
  Stack,
  Text
} from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";

import { useI18n } from "@bookingcom/lingojs-react";
import { useStore } from "../../../store";

import useUserAgent from "hooks/useUserAgent";
import { BedIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { CloseIcon } from "assets/IconsSeatMap";
import { trackCustomGoal, trackGoal } from "utils/et";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import useClientMetrics from "hooks/useClientMetrics";
import { CONNECTOR_FEEDBACK_RESPONSES } from "cross-sell/constants";
import styles from "./ConnectorCard.module.css";
import {
  TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF,
  TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS
} from "constants/experiments";

interface FeedbackOptionProps {
  text: string;
  customGoal: string;
}

const ConnectorCard: FC = () => {
  const store = useStore();
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("xsell", requestId);
  const { trackClientMetric } = useClientMetrics();
  const [showConnector, setShowConnector] = useState(true);
  const [showFeedbackPopover, setShowFeedbackPopover] = useState(false);
  const [feedbackOption, setFeedbackOption] = useState("0");
  const [connectorRenderedSuccesfully, setConnectorRenderedSuccesfully] = useState(false);

  const { order } = store.order;
  const { crossSell, isLoading } = store.crossSell;
  const cityName =
    crossSell?.searchParams?.destination?.cityName ||
    order?.airOrder?.flightSegments?.[0]?.arrivalAirport?.cityName ||
    undefined;
  const inCityName =
    crossSell?.searchParams?.destination?.inCityName ||
    order?.airOrder?.flightSegments?.[0]?.arrivalAirport?.cityName ||
    undefined;

  const FEEDBACK_OPTIONS = useMemo(() => {
    return [
      {
        text: i18n.trans(t("flights_xsell_stay_connector_feedback_1")),
        customGoal: CONNECTOR_FEEDBACK_RESPONSES.I_DONT_NEED_TO_BOOK_A_STAY
      },
      {
        text: i18n.trans(t("flights_xsell_stay_connector_feedback_2")),
        customGoal: CONNECTOR_FEEDBACK_RESPONSES.I_ALREADY_HAVE_BOOKED_A_STAY
      },
      {
        text: i18n.trans(t("flights_xsell_stay_connector_feedback_3")),
        customGoal: CONNECTOR_FEEDBACK_RESPONSES.I_AM_NOT_READY_TO_BOOK_A_STAY
      },
      { text: i18n.trans(t("flights_xsell_stay_connector_feedback_4")), customGoal: CONNECTOR_FEEDBACK_RESPONSES.OTHER }
    ];
  }, [i18n]);

  const trackCloseEvent = useCallback(() => {
    trackV2("click_connector_close", {
      orderid: order?.orderId || ""
    });
  }, [trackV2, order?.orderId]);
  const trackFeedbackOptionClick = useCallback(
    (feedbackAnswer: number) => {
      trackV2("click_connector_survey_answer", {
        answer: feedbackAnswer,
        orderid: order?.orderId || ""
      });
    },
    [trackV2, order?.orderId]
  );

  const trackDismissal = () => {
    trackCustomGoal(
      TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF,
      TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS.CLICKED_CROSS_SELL_CONNECTOR_DISMISS
    );
  };

  useEffect(() => {
    if (connectorRenderedSuccesfully) {
      trackCustomGoal(
        TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF,
        TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS.RENDERED_CROSS_SELL_CONNECTOR
      );
    }
  }, [connectorRenderedSuccesfully]);

  const FeedbackOption = useCallback(
    (props: FeedbackOptionProps) => {
      const { text, customGoal } = props;

      return (
        <ListItem
          attributes={{ "aria-label": text }}
          onClick={(e) => {
            trackFeedbackOptionClick(Number(customGoal));
            setShowConnector(false);
            e.stopPropagation();
            trackDismissal();
          }}
        >
          <Text variant="body_1">{text}</Text>
        </ListItem>
      );
    },
    [trackFeedbackOptionClick]
  );

  const DesktopPopover = useCallback(
    () => (
      <Popover
        fill
        size="auto"
        hideClose={true}
        position="bottom-end"
        onOpen={() => {
          trackCloseEvent();
        }}
        closeAriaLabel={i18n.trans(t("a11y_flights_xsell_stay_connector_close"))}
      >
        <Popover.Trigger>
          {(attributes) => (
            <Button
              icon={<Icon svg={CloseIcon} className={styles.closeBtn} size="small" />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className={styles.closeBtnWrap}
              variant="tertiary-neutral"
              size="medium"
              attributes={{
                "aria-label": i18n.trans(t("a11y_flights_xsell_stay_connector_close")),
                ...attributes
              }}
            />
          )}
        </Popover.Trigger>
        <Popover.Content>
          <Stack direction="column" justifyContent="start" alignItems="start" className={styles.feedbackItemsInfo}>
            <Text className={styles.popoverTitle} variant="headline_3">
              {i18n.trans(t("flights_xsell_stay_connector_feedback_title"))}
            </Text>
            {FEEDBACK_OPTIONS.map((option, index) => {
              return <FeedbackOption key={index} customGoal={String(option.customGoal)} text={option.text} />;
            })}
          </Stack>
        </Popover.Content>
      </Popover>
    ),
    [FeedbackOption, i18n, FEEDBACK_OPTIONS, trackCloseEvent]
  );

  const MobileConnector = useCallback(() => {
    return (
      <div role="presentation" onClick={(e) => e.stopPropagation()}>
        <Button
          icon={<Icon svg={CloseIcon} className={styles.closeBtn} />}
          onClick={(e) => {
            e.preventDefault();
            setShowFeedbackPopover(true);
            trackCloseEvent();
          }}
          variant="tertiary-neutral"
          size="medium"
          attributes={{
            "aria-label": i18n.trans(t("a11y_flights_xsell_stay_connector_close"))
          }}
        />
      </div>
    );
  }, [i18n, trackCloseEvent]);

  const handleConnectorClick = (targetURL: string) => {
    trackGoal("flights_web_xsell_carousel_cta_click");
    trackGoal("flights_web_xsell_carousel_click");
    trackV2("xsell_button_click", {
      orderid: order?.orderId || "",
      button:
        cityName && inCityName
          ? i18n.trans(
              t("flights_xsell_stay_connector", {
                variables: {
                  in_city_name: inCityName,
                  city_name: cityName
                }
              })
            )
          : "",
      url: targetURL,
      request_id: crossSell?.meta.requestId || "",
      componentType: "connector",
      soylentId: order?.soylentEmail || ""
    });
    trackClientMetric("xsell_button_click");
    trackCustomGoal(
      TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF,
      TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS.CLICKED_CROSS_SELL_CONNECTOR
    );
  };

  if (isLoading) {
    return <SkeletonLoader className={styles.skeleton} variant="box" aspectRatio="5:0.4" />;
  }

  if (cityName && inCityName && showConnector && crossSell?.searchResults.target) {
    if (!connectorRenderedSuccesfully) setConnectorRenderedSuccesfully(true);
    return (
      <>
        <Divider vertical className={styles.verticalDivider} />
        <a
          href={crossSell?.searchResults.target}
          className={styles.searchLink}
          onClick={() => {
            handleConnectorClick(crossSell?.searchResults.target);
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Card className={styles.cardWrapper} data-testid={"connector-card"}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" className={styles.wrapper}>
              <Stack direction="row" justifyContent="start" alignItems="center" className={styles.info}>
                <Icon svg={BedIcon} size="medium" ariaLabel="Genius" />
                <Text variant="body_1">
                  {i18n.trans(
                    t("flights_xsell_stay_connector", {
                      variables: {
                        in_city_name: inCityName,
                        city_name: cityName
                      }
                    })
                  )}
                </Text>
              </Stack>
              {isMobile ? <MobileConnector /> : <DesktopPopover />}
            </Stack>
          </Card>
        </a>
        {isMobile ? (
          <SheetContainer
            title={i18n.trans(t("flights_xsell_stay_connector_feedback_title"))}
            closeAriaLabel={i18n.trans(t("a11y_flights_xsell_stay_connector_feedback_close"))}
            active={showFeedbackPopover}
            lockClose={false}
            onCloseTrigger={() => {
              setShowFeedbackPopover(false);
            }}
            lockCloseOnOutsideClick={true}
          >
            <InputRadioGroup
              name="rate"
              value={feedbackOption}
              onChange={(event) => {
                event.event?.stopPropagation();
                const { value } = event;
                value && setFeedbackOption(value);
              }}
            >
              <Stack>
                {FEEDBACK_OPTIONS.map((option) => {
                  return (
                    <InputRadio
                      className={styles.radioOptions}
                      key={option.text}
                      label={option.text}
                      value={String(option.customGoal)}
                    />
                  );
                })}
              </Stack>
            </InputRadioGroup>
            <Button
              className={styles.sendFeedback}
              disabled={!feedbackOption}
              onClick={() => {
                trackFeedbackOptionClick(Number(feedbackOption));
                setShowFeedbackPopover(false);
                setShowConnector(false);
                trackDismissal();
              }}
              text={i18n.trans(t("flights_xsell_stay_connector_feedback_cta"))}
              variant="primary"
              type="button"
              size="medium"
              wide={true}
            />
          </SheetContainer>
        ) : null}
      </>
    );
  }

  return null;
};

export default ConnectorCard;
