import { Stack } from "@bookingcom/bui-react";
import React from "react";
import { UIFlightData } from "@flights/types";
import FareCard from "../../FlightCard/components/FareCard";
import { SkeletonFlightCard } from "../../Skeleton/SkeletonFlightCard";
import useMeasureBrandedFaresWaitTime from "../hooks/useMeasureBrandedFaresWaitTime";
import { convertFlightToFare } from "../utils";
import { useI18n } from "@bookingcom/lingojs-react";
import useBrandedFares from "../hooks/useBrandedFares";
import Frame from "components/elements/Frame";
import useTrackFirstFarePriceMismatch from "components/elements/FareSelector/hooks/useTrackFirstFarePriceMismatch";
import { trackChildBfOffersStages } from "utils/experiments/apex/flights_apex_web_compact_branded_fares_for_mdot_sr";

type Props = {
  flight: UIFlightData;
};

const FareSelector = ({ flight }: Props) => {
  const i18n = useI18n();
  const { fetchStatus, brandedFareOffers } = useBrandedFares(flight.token);
  const hasBrandedFareOffers = !!brandedFareOffers && brandedFareOffers.length > 0;

  trackChildBfOffersStages(brandedFareOffers);

  useMeasureBrandedFaresWaitTime(fetchStatus, hasBrandedFareOffers);
  useTrackFirstFarePriceMismatch(flight, brandedFareOffers);

  return (
    <Frame ml={4} mr={4} mb={4}>
      <Stack gap={1}>
        {hasBrandedFareOffers ? (
          brandedFareOffers.map((brandedFareOffer, i) => (
            <FareCard
              key={brandedFareOffer.token}
              index={i}
              fareOffer={brandedFareOffer}
              baseOffer={flight}
              fetchStatus={fetchStatus}
            />
          ))
        ) : (
          <>
            <FareCard fareOffer={convertFlightToFare(flight, i18n)} baseOffer={flight} fetchStatus={fetchStatus} />
            {fetchStatus === "loading" && <SkeletonFlightCard />}
          </>
        )}
      </Stack>
    </Frame>
  );
};

export default FareSelector;
