import React, { FC } from "react";

import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { Divider, HiddenVisually, Text } from "@bookingcom/bui-react";

import { UIFlightSegment } from "@flights/types";
import formatting from "../../../../utils/formatting";
import useLocaleContext from "../../../../hooks/useLocaleContext";
import getTechnicalStops from "utils/get-technical-stops";
import { trackExperiment } from "utils/et";
import Frame from "components/elements/Frame";
import styles from "./FlightCardSegment.module.css";
import useUserAgent from "../../../../hooks/useUserAgent";
import { t } from "@bookingcom/lingojs-core";
import useGlobalContext from "hooks/useGlobalContext";
import trackDirectToNonStopEnUs from "../../../../utils/experiments/funnel/flights_web_direct_to_nonstop_en_us";
const FlightCardSegment: FC<{ segment: UIFlightSegment; idx: number }> = ({ segment, idx }) => {
  const i18n = useI18n() as I18nChildContext;
  const { isRTL } = useLocaleContext();
  if (
    !segment.departureTime ||
    !segment.departureAirport ||
    !segment.arrivalTime ||
    !segment.arrivalAirport ||
    !segment.totalTime
  ) {
    throw new Error("Invalid flight data in FlightCard");
  }

  const technicalStopsCount = getTechnicalStops.fromSegment(segment).length;

  return (
    <Frame>
      <Frame direction="row" justifyContent="space-between">
        <TimeAndDateBlock
          airport={segment.departureAirport.code}
          timestamp={segment.departureTime}
          alignment={isRTL ? "right" : "left"}
          showWarning={segment.showWarningOriginAirport}
          testAttributes={{
            time: `flight_card_segment_departure_time_${idx}`,
            date: `flight_card_segment_departure_date_${idx}`,
            aiport: `flight_card_segment_departure_airport_${idx}`
          }}
        />
        <Stops
          totalBoundDuration={formatting.flightDuration(segment.totalTime / 60, i18n)}
          durationAriaLabel={formatting.flightDurationAria(segment.totalTime / 60, i18n)}
          count={segment.legs.length - 1}
          technicalStopsCount={technicalStopsCount}
          dataTestId={{
            duration: `flight_card_segment_duration_${idx}`,
            stops: `flight_card_segment_stops_${idx}`
          }}
        />

        <TimeAndDateBlock
          airport={segment.arrivalAirport.code}
          timestamp={segment.arrivalTime}
          alignment={isRTL ? "left" : "right"}
          showWarning={segment.showWarningDestinationAirport}
          testAttributes={{
            time: `flight_card_segment_destination_time_${idx}`,
            date: `flight_card_segment_destination_date_${idx}`,
            aiport: `flight_card_segment_destination_airport_${idx}`
          }}
        />
      </Frame>
    </Frame>
  );
};

const TimeAndDateBlock: FC<{
  timestamp: string;
  airport: string;
  alignment: "left" | "right";
  showWarning?: boolean;
  testAttributes?: {
    time: string;
    aiport: string;
    date: string;
  };
}> = ({ timestamp, airport, alignment, showWarning, testAttributes }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  return (
    <Frame
      grow={0}
      attributes={{
        style: { textAlign: alignment, flexShrink: trackExperiment("flights_web_sr_full_luggage_data") ? 0 : undefined }
      }}
    >
      <Text variant="strong_1" attributes={{ "data-testid": `${testAttributes?.time}` }}>
        {formats.flightTime(timestamp)}
      </Text>
      <Frame direction="row" justifyContent={alignment === "left" ? "flex-start" : "flex-end"}>
        <Text
          variant="small_1"
          className={showWarning ? styles.warning : ""}
          attributes={{ "data-testid": `${testAttributes?.aiport}` }}
        >
          {airport}
        </Text>
        <Text variant="small_1" className={styles.iataDateSeparator}>
          {" . "}
        </Text>
        <Text variant="small_1" attributes={{ "data-testid": `${testAttributes?.date}` }}>
          {formats.flightDate(timestamp)}
        </Text>
      </Frame>
    </Frame>
  );
};

const Stops: FC<{
  totalBoundDuration: string;
  count: number;
  technicalStopsCount: number;
  dataTestId?: {
    duration: string;
    stops: string;
  };
  durationAriaLabel?: string;
}> = ({ durationAriaLabel, totalBoundDuration, count, dataTestId, technicalStopsCount }) => {
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();
  const { locale } = useGlobalContext();
  const showNonStop = !!trackDirectToNonStopEnUs.variant() && locale === "en-us";

  return (
    <Frame
      justifyContent="space-between"
      alignItems="center"
      pr={trackExperiment("flights_web_sr_full_luggage_data") ? 1 : undefined}
      pl={trackExperiment("flights_web_sr_full_luggage_data") ? 1 : undefined}
      attributes={{ style: { width: isMobile ? "40%" : "50%" } }}
    >
      <Text
        variant="small_1"
        className={styles.stopsTextStyle}
        attributes={{ "data-testid": dataTestId?.duration, "aria-hidden": "true" }}
      >
        {totalBoundDuration}
      </Text>
      <HiddenVisually>{durationAriaLabel}</HiddenVisually>
      <StopsDivider count={count} />
      <Text variant="small_1" className={styles.stopsTextStyle} attributes={{ "data-testid": dataTestId?.stops }}>
        {count === 0
          ? showNonStop
            ? i18n.trans(t("flights_stops_nonstop_us"))
            : i18n.trans(
                t("flights_filter_stops_none", {
                  num_exception: count,
                  variables: { num_stops: count }
                })
              )
          : i18n.trans(
              t("flights_route_num_stops", {
                num_exception: count,
                variables: { num_stops: count }
              })
            )}
        {!!technicalStopsCount && (
          <Text tagName="span" variant="small_1">
            {` · ${i18n.trans(
              t("flights_sr_technical_stop", {
                variables: {
                  num_stop: technicalStopsCount,
                  num_exception: technicalStopsCount
                }
              })
            )}`}
          </Text>
        )}
      </Text>
    </Frame>
  );
};

const StopsDivider: FC<{ count: number }> = ({ count }) => {
  return (
    <Frame
      direction="row"
      attributes={{ style: { width: "100%", position: "relative" } }}
      justifyContent="center"
      alignItems="center"
    >
      <Divider className={styles.stopsDivider} />
      {count > 0 && (
        <Frame direction="row" attributes={{ style: { position: "absolute", margin: "0 auto" } }}>
          {Array.from({ length: count }).map((i, index) => (
            <span className={styles.stopsSeparator} key={index} />
          ))}
        </Frame>
      )}
    </Frame>
  );
};

export default FlightCardSegment;
