import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_ace_web_cfar_target_markets",
  stages: {
    cfar_available: 1, // main
    cfar_ticket_type_page: 2,
    cfar_baggage_extras_page: 3, // (or next in base)
    germany: 4,
    italy: 5,
    sweden: 6,
    france: 7,
    mdot: 8,
    have_price_on_top: 9
  },
  goals: {
    flexibility_selection_error: 1,
    click_on_cancel_bookings_with_cfar: 2,
    click_on_cancel_bookings: 3
  },
  goalsWithValue: []
});

const trackStages = (isTicketTypePage: boolean, isMobile: boolean, pos?: string) => {
  utils.stages.cfar_available();
  if (isTicketTypePage) {
    utils.stages.have_price_on_top();
    utils.stages.cfar_ticket_type_page();
  } else {
    utils.stages.cfar_baggage_extras_page();
  }
  if (pos === "de") {
    utils.stages.germany();
  } else if (pos === "it") {
    utils.stages.italy();
  } else if (pos === "se") {
    utils.stages.sweden();
  } else if (pos === "fr") {
    utils.stages.france();
  }
  if (isMobile) {
    utils.stages.mdot();
  }
};

export default { trackStages, ...utils };
