import Debug from "debug";

import clientFetch from "../utils/client-fetch";
import {
  ETTrackingRequest,
  Stage,
  Goal,
  CustomGoal,
  GoalValue,
  ClientSideExperiment,
  ExperimentVariant,
  ClientSideTrackingOnlyExperiment
} from "@flights/types";
import * as watchEt from "./watch-et";

const debug = Debug("utils:et");

type TrackedStages = {
  [tag: string]: {
    [stage: number]: boolean;
  };
};

const buffer: ETTrackingRequest[] = [];

export const ET_REPORT_INTERVAL = 500; // ms

const trackedStages: TrackedStages = {};

const cacheStage = (tag: ClientSideExperiment, stage: Stage) => {
  if (!trackedStages[tag]) {
    trackedStages[tag] = {};
  }
  trackedStages[tag][stage] = true;
};

const initETTracker = () => {
  setInterval(sendTrackingRequests, ET_REPORT_INTERVAL);
};

const sendTrackingRequests = () => {
  if (buffer.length <= 0) {
    return;
  }
  if (process.env.BUILD_TARGET === "server") {
    return;
  }
  if (window?.__GLOBAL_CONTEXT__?.shouldNotTrack) {
    return;
  }
  // Waiting for the order response for getting soylentEmail (only applicable to post booking screens)
  if (!!window?.__GLOBAL_CONTEXT__?.orderLoading) {
    return;
  }
  const flushedETRequests = buffer.splice(0); // also empties `buffer`

  clientFetch("/track/et", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-Soylent-Email": window.__GLOBAL_CONTEXT__.soylentEmail ? window.__GLOBAL_CONTEXT__.soylentEmail : ""
    },
    body: JSON.stringify({
      requests: flushedETRequests
    }),
    credentials: "include",
    referrerPolicy: "strict-origin-when-cross-origin"
  })
    .then(() => {
      debug("Successfully reported", flushedETRequests);
    })
    .catch((error) => {
      buffer.unshift(...flushedETRequests);
      debug("Failed to report", flushedETRequests, "with error", error);
    });

  watchEt.log(flushedETRequests);
};

const queueTrackingRequest = (request: ETTrackingRequest) => {
  buffer.push(request);
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use etV2.track instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import etV2 from "utils/etV2";
 * etV2.track("exp_name");
 */
const trackExperiment = (tag: ClientSideExperiment): ExperimentVariant => {
  /**
   * This function doesn't track, but just retrieves the variant from the global context.
   * Tracking only happens on the BE.
   */
  debug("Tracking experiment", tag);
  watchEt.dispatchName(tag);
  const experiments = window?.__GLOBAL_CONTEXT__?.experiments;
  return experiments?.[tag] || 0;
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use etV2.stage instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import etV2 from "utils/etV2";
 * etV2.stage("exp_name", 1);
 */
const trackExperimentStage = (tag: ClientSideExperiment | ClientSideTrackingOnlyExperiment, stage: Stage) => {
  debug("Tracking stage", stage, "for experiment", tag);
  if (trackedStages[tag]?.[stage]) {
    return true;
  }
  cacheStage(tag, stage);
  queueTrackingRequest({ type: "stage", name: tag, value: stage });
  return true;
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use etV2.customGoal instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import etV2 from "utils/etV2";
 * etV2.customGoal("exp_name", 1);
 */
const trackCustomGoal = (tag: ClientSideExperiment | ClientSideTrackingOnlyExperiment, customGoal: CustomGoal) => {
  debug("Tracking custom goal", customGoal, "for experiment", tag);
  queueTrackingRequest({ type: "customGoal", name: tag, value: customGoal });
  return true;
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use etV2.goal instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import etV2 from "utils/etV2";
 * etV2.goal("goal_name");
 */
const trackGoal = (goal: Goal) => {
  debug("Tracking goal", goal);
  queueTrackingRequest({ type: "goal", name: goal });
  return true;
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use etV2.goalWithValue instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import etV2 from "utils/etV2";
 * etV2.goalWithValue("goal_name", 1);
 */
const trackGoalWithValue = (goal: Goal, value: GoalValue) => {
  debug("Tracking goal with value", goal, "with value", value);
  queueTrackingRequest({ type: "goalWithValue", name: goal, value });
  return true;
};

export { trackExperiment, trackExperimentStage, trackGoal, trackGoalWithValue, trackCustomGoal, initETTracker };
