import { UIOrder, UIClientFetchError, UIOrderCreated, UIAddOnOrder, UIPrice } from "@flights/types";
import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";

export enum ActionTypes {
  fetch = "order/fetch",
  fetchSuccess = "order/fetchSuccess",
  fetchError = "order/fetchError",
  reset = "order/reset",
  setOrderCreated = "order/setOrderCreated",
  setAddOnOrder = "order/setAddOnOrder",
  updateInstalmentsPrice = "order/updateInstalmentsPrice"
}

export const actions = {
  fetch: createAction(() => {
    window.__GLOBAL_CONTEXT__.orderLoading = true;
    return {
      type: ActionTypes.fetch,
      payload: {}
    };
  }),

  fetchSuccess: createAction((order: UIOrder) => {
    window.__GLOBAL_CONTEXT__.soylentEmail = order?.soylentEmail;
    window.__GLOBAL_CONTEXT__.orderLoading = false;
    return {
      type: ActionTypes.fetchSuccess,
      payload: { order }
    };
  }),

  fetchError: createAction((error: UIClientFetchError) => {
    window.__GLOBAL_CONTEXT__.orderLoading = false;
    return {
      type: ActionTypes.fetchError,
      payload: { error }
    };
  }),

  setOrderCreated: createAction((payload: { orderCreated: UIOrderCreated; orderCreatedByPriceChange?: boolean }) => ({
    type: ActionTypes.setOrderCreated,
    payload
  })),

  setAddOnOrder: createAction((addOnOrder: UIAddOnOrder) => ({
    type: ActionTypes.setAddOnOrder,
    payload: { addOnOrder }
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  })),

  updateInstalmentsPrice: createAction((payload: { instalmentsFees?: UIPrice; totalWithInstalments?: UIPrice }) => ({
    type: ActionTypes.updateInstalmentsPrice,
    payload
  }))
};

export type Actions = ActionsUnion<typeof actions>;
