import ErrorReporter, { defaultTransformer } from "@bookingcom/error-reporter";
export function transformErrorToJSError(error) {
    /**
     * In reportError, I am using the defaultTransformer which is the only transformer which allows
     * the passing of ref_action and pid.
     */
    return {
        name: "js_errors",
        file: error.be_file,
        message: error.be_message,
        column: error.be_column,
        line: error.be_line,
        ref_action: error.ref_action,
        pid: error.pid,
        url: error.url,
        stack: error.be_stack
    };
}
export function flightsClientsideErrorReporter(action, pageViewId, experimentContext) {
    const headersParams = {};
    if (experimentContext) {
        headersParams["X-Booking-Experiment-State-Blob"] = experimentContext;
    }
    return new ErrorReporter({
        payloadContentType: "application/json",
        requestHeaders: headersParams,
        handleWindowErrors: false,
        transform: (error) => transformErrorToJSError(defaultTransformer(error, action, pageViewId))
    });
}
export default function reportError(error, action, pageViewId, experimentContext) {
    // `message` is expected in the payload. Server will responded with 400 otherwise.
    // Typically, every exception would have `message` set, if it is an instance of Error,
    // but if clintFetch gets 200 response from the server with an `error` object in it, it will
    // simply throw that object. That's all right, UI needs to handle it, but we don't need to report it
    // as JS error anyway (since in this case it is already reported in Flog).
    if (error && error.message) {
        const reporter = flightsClientsideErrorReporter(action, pageViewId, experimentContext);
        reporter.sendError(error);
    }
}
/**
 * Singleton class to return error report instance, update its property and send error.
 */
export class FlightsErrorReporter {
    static flightsErrorReporter;
    errorReporter;
    action;
    pageViewId;
    errorSeenSoFar = 0;
    constructor() {
        this.errorReporter = new ErrorReporter({
            payloadContentType: "application/json",
            handleWindowErrors: true,
            maxErrorsToReport: 20,
            transform: (error) => this.flightsJSErrorsTransformer(error)
        });
    }
    /**
     * This will return singleton instance of FlightsErrorReporter which contain a instance of ErrorReporter
     * @returns {FlightsErrorReporter} singleton instance of FlightsErrorReporter
     */
    static getInstance() {
        if (!FlightsErrorReporter.flightsErrorReporter) {
            FlightsErrorReporter.flightsErrorReporter = new FlightsErrorReporter();
        }
        return FlightsErrorReporter.flightsErrorReporter;
    }
    /**
     * Reset the pageViewId, pageView, error count and experiment context. This can be used when page view changes in SPA
     * @param {string} action?:string
     * @param {string} pageViewId?:string
     * @param {string} experimentContext?:string
     * @returns {void}
     */
    resetErrorReporter(action, pageViewId, experimentContext) {
        this.action = action;
        this.pageViewId = pageViewId;
        this.errorSeenSoFar = 0;
        this.errorReporter.setErrorsReportedSoFar = 0;
        this.errorReporter.setRequestHeaders = { "X-Booking-Experiment-State-Blob": experimentContext || "" };
    }
    /**
     * Send the error to server side API
     * @param {Error} error:Error
     * @returns {void}
     */
    sendError(error) {
        if (error && error.message) {
            this.errorSeenSoFar = this.errorSeenSoFar + 1;
            this.errorReporter.sendError(error);
        }
    }
    flightsJSErrorsTransformer(error) {
        return {
            name: "js_errors",
            file: error.url || document.location.href,
            message: error.message || "",
            column: error.colno || 0,
            line: error.lno || 0,
            ref_action: this.action,
            pid: this.pageViewId || 1,
            url: error.url,
            stack: error.stack || "",
            errc: this.errorSeenSoFar,
            errp: this.errorSeenSoFar - 1
        };
    }
}
