/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useRef, useState, useMemo } from "react";
import FlightCardMobile from "./components/FlightCardBound";
import FlightCardDesktop, { FlightCardBoundProps } from "./components/FlightCardBound.desktop";
import useUserAgent from "../../../hooks/useUserAgent";
import { UIFlightData } from "@flights/types";
import { SkeletonSearchContent } from "../Skeleton/SkeletonPages/SkeletonSearch.desktop";
import { useClientFetch } from "hooks/useClientFetch";
import { Button, EmptyState, HiddenVisually, Image } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { OUTBOUND_FLIGHTS_SEARCH_PARAMS } from "../../../constants";
import { createPortal } from "react-dom";
import { useSearchAPIParams } from "hooks/useSearchAPIParams";
import { trackCustomGoal, trackGoalWithValue } from "utils/et";
import { useActions } from "../../../store";
import { actions } from "store/searchResults/actions";
import Frame from "../Frame";
import styles from "./FlightCardToggle.module.css";

const INITIAL_PAGE_LIMIT = 5;

const useGenerateUrlToFetch = (token: string, options?: { page?: number; limit?: number }) => {
  const query = useSearchAPIParams();
  query.delete("outboundOnly");
  query.delete(OUTBOUND_FLIGHTS_SEARCH_PARAMS);
  query.set("inboundFor", token);

  if (options?.limit) query.set("limit", `${options?.limit}`);

  if (options?.page) query.set("page", `${options.page}`);

  return `/api/flights/?${query}`;
};

const useFetchInboundFlights = ({
  token,
  onFetch,
  onError,
  onSuccess
}: {
  token: string;
  onFetch: (page: number) => void;
  onError: (page: number) => void;
  onSuccess: (data: any, page: number) => void;
}) => {
  const [{ page, limit }, setState] = useState({ page: 1, limit: INITIAL_PAGE_LIMIT });
  const nextPageLimitRef = useRef(0);
  const url = useGenerateUrlToFetch(token, { limit, page });

  useClientFetch(url, {
    fetch: () => onFetch(page),
    error: () => onError(page),
    success: (data) => {
      if (page === 1) {
        const remainingCount = data.aggregation.totalCount - INITIAL_PAGE_LIMIT;

        if (remainingCount > 0) {
          nextPageLimitRef.current = remainingCount;
        }
      }

      onSuccess(data, page);
    }
  });

  const nextPage = useCallback(
    () => setState((prev) => ({ page: prev.page + 1, limit: nextPageLimitRef.current })),
    []
  );

  return { nextPage };
};

type InboundFlightsListProps = Omit<FlightCardBoundProps, "flight"> & {
  flights: UIFlightData[];
  loadingCount: number;
  page: number;
  outboundIndex: number;
};

const InboundFlightsList = (props: InboundFlightsListProps) => {
  const { flights, loadingCount, page, outboundIndex, ...restProps } = props;
  const { isMobile } = useUserAgent();

  const FlightCard = isMobile ? FlightCardMobile : FlightCardDesktop;

  if (!flights.length) {
    return <SkeletonSearchContent count={loadingCount} />;
  }

  return (
    <>
      {flights.map((flight, i) => (
        <Frame key={i} pt={2} pb={2}>
          <FlightCard
            {...restProps}
            outboundIndex={outboundIndex}
            index={i}
            flight={flight}
            variant="condensed"
            inboundFlight={true}
            forceButtonFocusOnMount={page !== 1 && i === 0}
          />
        </Frame>
      ))}
    </>
  );
};

type InboundFlightsProps = FlightCardBoundProps & {
  onRefetch: VoidFunction;
  onFetchStart: VoidFunction;
  onFetchFinish: VoidFunction;
};

const InboundFlights = (props: InboundFlightsProps) => {
  const { onRefetch, onFetchStart, onFetchFinish, ...restProps } = props;
  const i18n = useI18n();
  const [offersList, setOffersList] = useState<Array<UIFlightData[]>>([[]]);
  const [error, setError] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [ariaBusy, setAriaBusy] = useState(false);
  const remainingDataRef = useRef(0);
  const searchResultsActions = useActions(actions);

  const offersListWithReturnSegment = useMemo(
    () =>
      offersList.map((list) =>
        list.map((offer: UIFlightData) => ({
          ...offer,
          segments: [offer.segments[1]]
        }))
      ),
    [offersList]
  );

  const { nextPage } = useFetchInboundFlights({
    token: props.flight.outboundFlightInfo?.token || "",
    onFetch: (page) => {
      if (page !== 1) setOffersList((prev) => [...prev, []]);
      setAriaBusy(true);
      onFetchStart();
    },
    onError: (page) => {
      if (page === 1) {
        setError(true);
        trackCustomGoal("flights_web_inbound_outbound_sr", 1);
        trackGoalWithValue("flights_web_inbound_outbound_load_error", 1);
      }
      setAriaBusy(false);
      onFetchFinish();
    },
    onSuccess: (data, page) => {
      // If remaining calculation goes wrong and fetching data returns
      // 0 offers, remove added empty array to stop loading UI state.
      if (data.flightOffers.length === 0) {
        setOffersList((prev) => {
          return prev.filter((_, i) => i !== prev.length - 1);
        });
      } else {
        setOffersList((prev) => {
          const updated = [...prev];
          updated[page - 1] = data.flightOffers;
          return updated;
        });

        const remaining = data.aggregation.totalCount - INITIAL_PAGE_LIMIT;
        if (page <= 1 && remaining > 0) {
          remainingDataRef.current = remaining;
          setHasMoreData(true);
        }
      }

      setAriaBusy(false);
      onFetchFinish();
    }
  });

  const handleLoadMore = useCallback(() => {
    nextPage();
    setHasMoreData(false);
  }, [nextPage]);

  const handleOnClick = useCallback(
    (flight: UIFlightData) => {
      // Get flight with both segments for Flight Details
      const selectedFlight = offersList.flat().find((f) => f.token === flight.token);
      if (selectedFlight) {
        searchResultsActions.setSelectedInboundFlight(selectedFlight);
        return restProps.onClick(selectedFlight);
      }

      throw new Error("Missing selected inbound flight");
    },
    [searchResultsActions, offersList, restProps]
  );

  const errorContent = (
    <EmptyState
      attributes={{ "aria-live": "polite" }}
      topIllustration={
        <Image
          asset={{
            setName: "illustrations-traveller",
            assetName: "FlightsNoResults"
          }}
        />
      }
      text={i18n.trans(t("flights_sr_rt_return_loading_error"))}
      button={{
        text: i18n.trans(t("flights_sr_rt_return_loading_error_cta")),
        className: styles.button,
        onClick: onRefetch
      }}
    />
  );

  const loadingCount = useMemo(() => {
    const inboundFlightsCount = props.flight.outboundFlightInfo?.inboundFlightsCount || INITIAL_PAGE_LIMIT;
    return inboundFlightsCount >= INITIAL_PAGE_LIMIT ? INITIAL_PAGE_LIMIT : inboundFlightsCount;
  }, [props.flight.outboundFlightInfo?.inboundFlightsCount]);

  return (
    <div id={`flight-outbound-card-${props.index}`} aria-busy={ariaBusy}>
      {error ? (
        errorContent
      ) : (
        <>
          {offersListWithReturnSegment.map((flights, index) => (
            <InboundFlightsList
              key={index}
              {...restProps}
              onClick={handleOnClick}
              outboundIndex={restProps.index || 0}
              flights={flights}
              page={index + 1}
              loadingCount={index === 0 ? loadingCount : remainingDataRef.current}
            />
          ))}
          {hasMoreData && (
            <Frame alignItems="center" mt={3}>
              <Button onClick={handleLoadMore} variant="secondary">
                {i18n.trans(t("flights_sr_rt_return_button_more"))}
              </Button>
            </Frame>
          )}
        </>
      )}
    </div>
  );
};

const _FlightCardToggle = (props: FlightCardBoundProps) => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const [isInboundFlightsVisible, setIsInboundFlightsVisible] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get(OUTBOUND_FLIGHTS_SEARCH_PARAMS);
    return !!(token && token === props.flight.token);
  });
  const [keyToForceRefetch, setKeyToForceRefetch] = useState(Math.random());
  const [ariaStatusMessage, setAriaStatusMessage] = useState<null | string>(null);

  const FlightCard = isMobile ? FlightCardMobile : FlightCardDesktop;

  const toggleFlights = useCallback(() => {
    const params = new URLSearchParams(window.location.search);

    if (isInboundFlightsVisible) {
      params.delete(OUTBOUND_FLIGHTS_SEARCH_PARAMS);
      setAriaStatusMessage(i18n.trans(t("a11y_flights_sr_rt_return_hidden")));
    } else {
      params.set(OUTBOUND_FLIGHTS_SEARCH_PARAMS, props.flight.token);
      setAriaStatusMessage(i18n.trans(t("a11y_flights_sr_rt_return_open")));
      trackCustomGoal("flights_web_inbound_outbound_sr", 3);
      trackGoalWithValue("flights_web_inbound_outbound_open_card", 1);
    }

    window.history.replaceState(null, "", `${location.pathname}?${params.toString()}`);

    setIsInboundFlightsVisible(!isInboundFlightsVisible);
  }, [i18n, isInboundFlightsVisible, props.flight.token]);

  const handleRefetch = useCallback(() => {
    setKeyToForceRefetch((prev) => prev + Math.random());
    trackCustomGoal("flights_web_inbound_outbound_sr", 2);
  }, []);
  const handleOnFetchStart = useCallback(
    () => setAriaStatusMessage(i18n.trans(t("a11y_flights_sr_rt_loading_state_start"))),
    [i18n]
  );
  const handleOnFetchFinish = useCallback(
    () => setAriaStatusMessage(i18n.trans(t("a11y_flights_sr_rt_loading_state_end"))),
    [i18n]
  );

  const outboundFlight = useMemo(() => {
    return {
      ...props.flight,
      segments: [props.flight.segments[0]],
      priceBreakdown: {
        ...props.flight.priceBreakdown,
        total: props.flight.outboundFlightInfo?.cheapestInboundPrice || props.flight.priceBreakdown.total
      },
      totalWithoutDiscount:
        props.flight.outboundFlightInfo?.cheapestInboundPrice || props.flight.priceBreakdown.totalWithoutDiscount
    };
  }, [props.flight]);

  return (
    <>
      <Frame mt={2} mb={4}>
        <FlightCard
          {...props}
          flight={outboundFlight}
          onClick={toggleFlights}
          variant="condensed"
          open={isInboundFlightsVisible}
          // "cheapestInboundPrice" is already per person so we don't need it to be calculated
          pricePerPerson={false}
          inboundFlightsCount={props.flight.outboundFlightInfo?.inboundFlightsCount}
          inboundFlightsContent={
            <InboundFlights
              {...props}
              key={keyToForceRefetch}
              onRefetch={handleRefetch}
              onFetchStart={handleOnFetchStart}
              onFetchFinish={handleOnFetchFinish}
            />
          }
        />
      </Frame>

      {ariaStatusMessage &&
        // This is using a portal to prevent users with screen readers to easily focus this element,
        // since its intent is solely to be announced by screen readers when loading is done.
        createPortal(
          <div aria-live="polite">
            <HiddenVisually>{ariaStatusMessage}</HiddenVisually>
          </div>,
          document.body
        )}
    </>
  );
};

const FlightCardToggle = React.memo(_FlightCardToggle);
export default FlightCardToggle;
