import { UIFlightSegment, UIOrder } from "@flights/types";
import { UIXc360Data, UIXc360Transform } from "./types";
import { getCookie, setCookie } from "utils/cookies";
import { C360_ACTION_VERSION } from "./constants";
import { addDays, format } from "date-fns";
import { SearchResultsURL, getSearchParams, SearchResultsParam } from "utils/searchResultsUrl";
import { MULTISTOP_IATA_LIST_SEPARATOR } from "utils/params";

export const percentageDiscount = (geniusLevel: number | undefined) =>
  geniusLevel === 3 ? "20" : geniusLevel === 2 ? "15" : "10";

export const isRoundTrip = (flightSegments: UIFlightSegment[]) => {
  if (flightSegments.length !== 2) return false;

  const departureAirport = flightSegments[0].departureAirport.code;
  const arrivalAirport = flightSegments[1].arrivalAirport.code;
  return departureAirport === arrivalAirport;
};

export const transformToc360CData = ({ type, accommodations, hotelId, index }: UIXc360Transform): UIXc360Data => {
  const content = {
    prediction_type: "hotel_id",
    use_case: "flightstech_web_flightsconfirmation_propcrosssell"
  };

  if (type === "clicked") {
    content["prediction"] = {
      value: hotelId?.toString(),
      rank: index
    };
  } else if (type === "viewed" && accommodations?.length) {
    content["predictions"] = accommodations.map((item, itemIndex) => ({
      value: item.id.toString(),
      rank: itemIndex
    }));
  }

  return {
    action_name: `recommendation_platform__prediction_${type}`,
    action_version: C360_ACTION_VERSION,
    content
  };
};

export const isXsellRecList = (orderId: string) => {
  const cookie = getCookie("xSellRecList");

  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    return parsedCookie.some((id: string) => id === orderId);
  }

  return false;
};

export const neverShowXsellRecList = (orderId: string) => {
  const oldCookieValue = getCookie("xSellRecList") || "[]";
  const newCookieValue = [...JSON.parse(oldCookieValue), orderId];
  try {
    setCookie("xSellRecList", JSON.stringify(newCookieValue), { maxAge: "1y" });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const roundOffNumberOfProperties = (numberOfProperties?: number) => {
  if (!numberOfProperties) return { numberOfProperties: 0, numberOfPropertiesWithSuffix: 0 };
  if (numberOfProperties > 100) {
    const roundOffValue = Math.floor(numberOfProperties / 100) * 100;
    return { numberOfProperties: roundOffValue, numberOfPropertiesWithSuffix: roundOffValue + "+" };
  } else {
    return { numberOfProperties: numberOfProperties, numberOfPropertiesWithSuffix: numberOfProperties };
  }
};

const getLocationData = (
  searchParams: SearchResultsURL,
  fromField: SearchResultsParam,
  toField: SearchResultsParam
) => {
  const fromFieldData = searchParams.params[fromField]?.split(MULTISTOP_IATA_LIST_SEPARATOR);
  const toFieldData = searchParams.params[toField]?.split(MULTISTOP_IATA_LIST_SEPARATOR);
  const from = fromFieldData && fromFieldData.length ? fromFieldData[0] : undefined; // to get first depature field in multiLeg flight
  const to =
    toFieldData && toFieldData.length
      ? toFieldData.length > 1
        ? toFieldData[toFieldData.length - 1]
        : toFieldData[0]
      : undefined; //to get last destination field in multiLeg flight
  return { from, to };
};

export const createSearchLink = (searchUrl: SearchResultsURL) => {
  if (searchUrl) {
    const domain = `${window.location.protocol}//${window.location.host}`;
    const { origin, destination, params } = searchUrl;
    const searchParams = new URLSearchParams(params);
    return domain + `/flights/${origin}-${destination}?${searchParams}`;
  }
  return undefined;
};

export const buildReturnFlightsSearchURl = (order?: UIOrder) => {
  const searchParams = getSearchParams(order);
  if (!searchParams) return;

  // to Get last leg in case of multileg flight
  const lastLeg = order?.airOrder.flightSegments[order?.airOrder.flightSegments.length - 1];

  const { from: departureCity, to: arrivalCity } = getLocationData(searchParams, "from", "to");
  const { from: departureCountry, to: arrivalCountry } = getLocationData(searchParams, "fromCountry", "toCountry");
  const { from: departureLocationName, to: arrivalLocationName } = getLocationData(
    searchParams,
    "fromLocationName",
    "toLocationName"
  );
  if (departureCity && arrivalCity && searchParams && lastLeg) {
    const arrivalDate = new Date(lastLeg.arrivalTime);
    const departureDate = format(addDays(arrivalDate, 7), "yyyy-MM-dd");
    // Swapping arrival to departure fields as it's return flight
    const { params } = searchParams;

    searchParams.origin = arrivalCity;
    params.from = arrivalCity;
    params.fromCountry = arrivalCountry;
    params.fromLocationName = arrivalLocationName;

    searchParams.destination = departureCity;
    params.to = departureCity;
    params.toCountry = departureCountry;
    params.toLocationName = departureLocationName;

    params.type = "ONEWAY"; // Creating only Oneway return flights link
    params.depart = departureDate;
    delete params.multiStopDates;
    delete params.return;

    const link = createSearchLink(searchParams);
    return link;
  }

  return undefined;
};

export enum CsxpPlacementPages {
  FLIGHT_MANAGE_BOOKING = "FLIGHT__MANAGE_BOOKING",
  FLIGHTS_CONFIRMATION = "FLIGHT__CONFIRMATION",
  FLIGHTS_SEARCHRESULTS = "FLIGHT__SEARCHRESULTS",
  FLIGHTS_FLIGHTDETAILS = "FLIGHT__FLIGHTDETAILS",
  FLIGHTS_CHECKOUT_TICKET_TYPE = "FLIGHT__CHECKOUT_TICKET_TYPE",
  FLIGHTS_CHECKOUT3 = "FLIGHT__CHECKOUT"
}

export enum CsxpVerticals {
  FLIGHTS = "FLIGHT"
}

export enum CsxpPlacementNames {
  PLAN_AHEAD = "PLAN_AHEAD",
  MODAL = "MODAL",
  CONNECTOR = "CONNECTOR",
  INLINE_BANNER_INFO = "INLINE_BANNER_INFO"
}

export const getCsxpSkeletonItemsByPlacementPlatform = function (
  placementPage: CsxpPlacementPages | undefined,
  placementName: CsxpPlacementNames | undefined,
  platform: string
): number {
  switch (true) {
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "MDOT":
      return 2;
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "MDOT":
      return 2;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "MDOT":
      return 2;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "MDOT":
      return 2;
    default:
      return 1;
  }
};

type GetCsxpPlacementPageByRouteNameReturnType = {
  placementPage: CsxpPlacementPages;
  placementName: CsxpPlacementNames;
};

export const getCsxpPlacementPageByRouteName = function (
  routeName: string,
  isModal?: boolean,
  variant?: string
): GetCsxpPlacementPageByRouteNameReturnType | undefined {
  const isConnectorVariant = variant === "connector";

  switch (routeName) {
    case "confirmation":
      return {
        placementPage: CsxpPlacementPages.FLIGHTS_CONFIRMATION,
        placementName: isConnectorVariant
          ? CsxpPlacementNames.CONNECTOR
          : isModal
          ? CsxpPlacementNames.MODAL
          : CsxpPlacementNames.PLAN_AHEAD
      };

    case "pb-order-details":
      return {
        placementPage: CsxpPlacementPages.FLIGHT_MANAGE_BOOKING,
        placementName: isConnectorVariant
          ? CsxpPlacementNames.CONNECTOR
          : isModal
          ? CsxpPlacementNames.MODAL
          : CsxpPlacementNames.PLAN_AHEAD
      };

    case "searchresults":
      return {
        placementPage: CsxpPlacementPages.FLIGHTS_SEARCHRESULTS,
        placementName: CsxpPlacementNames.PLAN_AHEAD
      };
    case "flightdetails":
      return {
        placementPage: CsxpPlacementPages.FLIGHTS_FLIGHTDETAILS,
        placementName: CsxpPlacementNames.PLAN_AHEAD
      };
    case "checkout-ticket-type":
      return {
        placementPage: CsxpPlacementPages.FLIGHTS_CHECKOUT_TICKET_TYPE,
        placementName: CsxpPlacementNames.PLAN_AHEAD
      };
    case "checkout3":
      return {
        placementPage: CsxpPlacementPages.FLIGHTS_CHECKOUT3,
        placementName: isModal ? CsxpPlacementNames.MODAL : CsxpPlacementNames.PLAN_AHEAD
      };
    default:
      return undefined;
  }
};
