/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC } from "react";

import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { Stack, Text, Icon } from "@bookingcom/bui-react";
import { InfoSignIcon, TravelInsuranceIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { UIFlightSegment } from "@flights/types";
import useVirutalInterlining from "../../../hooks/useVirutalInterlining";
import { Alert } from "../../atoms/Alert";
import Frame from "../Frame";
import { trackExperiment } from "utils/et";
import useRouteName from "hooks/useRouteName";

interface Props {
  segments: UIFlightSegment | UIFlightSegment[];
  align: "left" | "center";
  variant?: TextVariant;
  className?: string;
  isSearchResults?: boolean;
}

type TextVariant = "small_1" | "small_2" | "body_2" | "emphasized_2";

// While migrating this component to BUI for a more consistent user experience,
// funnel team did not want to change the implementation of this component in the search results.
// Therefore we have kept the old implementation only for search results flight cards.

const VIAlert: FC<Props> = ({ segments, align, variant, className, isSearchResults }) => {
  const { isVirtualInterliningFlight } = useVirutalInterlining();

  if (!isVirtualInterliningFlight(segments) || (Array.isArray(segments) && segments.length === 0)) {
    return null;
  }

  return (
    <Frame className={className} direction="column" alignItems={align}>
      {/* Search results flight card with old alert implementation */}
      {isSearchResults ? (
        Array.isArray(segments) ? (
          <VIAlertForFlightSearchResults segments={segments} variant={variant} />
        ) : (
          <VIAlertForSegmentSearchResults segment={segments} variant={variant} />
        )
      ) : (
        // New implementation with BUI for checkout and post booking pages
        <VIAlertForSegmentOrFlight segments={segments} />
      )}
    </Frame>
  );
};

const VIAlertForSegmentOrFlight: FC<{ segments: UIFlightSegment | UIFlightSegment[] }> = ({ segments }) => {
  const i18n = useI18n();
  const { isChangeOfAirportSegment } = useVirutalInterlining();
  const isPostBook = ["confirmation", "pb-order-details"].includes(useRouteName());

  const segmentsArr = Array.isArray(segments) ? segments : [segments];
  const areChangeOfAirportSegments = segmentsArr.some((segment) => isChangeOfAirportSegment(segment));

  return trackExperiment("flights_web_less_alarming_vi") ? (
    <Stack alignItems="center" direction="row" wrap="nowrap">
      <Icon color={isPostBook ? "callout" : "neutral_alt"} svg={TravelInsuranceIcon} />
      <Text color={isPostBook ? "callout" : "neutral_alt"}>
        {isPostBook
          ? areChangeOfAirportSegments
            ? i18n.trans(t("flights_self_transfer_airport_label"))
            : i18n.trans(t("flights_self_transfer_label"))
          : i18n.trans(t("flights_selftransfer_guarantee"))}
      </Text>
    </Stack>
  ) : (
    <Stack alignItems="center" direction="row">
      <Icon color="callout" svg={InfoSignIcon} />
      <Text color="callout">
        {areChangeOfAirportSegments
          ? i18n.trans(t("flights_self_transfer_airport_label"))
          : i18n.trans(t("flights_self_transfer_label"))}
      </Text>
    </Stack>
  );
};

const VIAlertForFlightSearchResults: FC<{ segments: UIFlightSegment[]; variant?: TextVariant }> = ({
  segments,
  variant
}) => {
  const i18n = useI18n();
  const { isChangeOfAirportSegment } = useVirutalInterlining();
  const areChangeOfAirportSegments = segments.some((segment) => isChangeOfAirportSegment(segment));

  return areChangeOfAirportSegments ? (
    <Alert text={i18n.trans(t("flights_self_transfer_airport_label"))} variant={variant} />
  ) : (
    <Alert text={i18n.trans(t("flights_self_transfer_label"))} variant={variant} />
  );
};

const VIAlertForSegmentSearchResults: FC<{ segment: UIFlightSegment; variant?: TextVariant }> = ({
  segment,
  variant
}) => {
  const i18n = useI18n();
  const { isChangeOfAirportSegment } = useVirutalInterlining();

  return isChangeOfAirportSegment(segment) ? (
    <Alert text={i18n.trans(t("flights_self_transfer_airport_label"))} variant={variant} />
  ) : (
    <Alert text={i18n.trans(t("flights_self_transfer_label"))} variant={variant} />
  );
};

export default VIAlert;
