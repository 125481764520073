/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { Text } from "@bookingcom/bui-react";
import { UIFlightData } from "@flights/types";
import React from "react";
import { useStore } from "../../../../store";
import { t, useI18n } from "@bookingcom/lingojs-react";

const companyName = "Booking.com";

type Props = {
  flight: UIFlightData;
};

const FlightAvailableSeats = ({ flight }: Props) => {
  const i18n = useI18n();
  const { searchCriteria } = useStore();
  const { adults, children } = searchCriteria;

  const travellersCount = adults + children.length;

  if (flight.seatAvailability && flight.seatAvailability.numberOfSeatsAvailable >= travellersCount) {
    const translationVariables = {
      num_left: flight.seatAvailability.numberOfSeatsAvailable,
      b_companyname: companyName
    };

    return (
      <Text variant="small_1" color="callout">
        {i18n.trans(
          t("flights_sr_last_left", {
            variables: translationVariables
          })
        )}
      </Text>
    );
  }

  return null;
};

export default FlightAvailableSeats;
