import { UIOrder, UIFlightSegment } from "@flights/types";
import { isAfter, parseISO } from "date-fns";

/* ================================================
  Check if booking is in the past
  A past booking is identified once the last flight
  leg has landed for a confirmed order.

  arrival time is in airport local time, we are adding
  1 day offset to it to make sure the date comparison
  is valid even if there is timezone difference.
=================================================== */

const addOneDay = (date: Date): Date => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + 1);
  return copy;
};

const isPastBooking = (order?: UIOrder): boolean => {
  const isConfirmed = order?.orderStatus === "CONFIRMED";
  const segments = order?.airOrder?.flightSegments || [];
  const lastSegment = segments[segments.length - 1] || {};
  return isConfirmed && isFlightSegmentInThePast(lastSegment);
};

export const isFlightSegmentInThePast = (segment: UIFlightSegment): boolean => {
  const legs = segment.legs || [];
  const lastLeg = legs[legs.length - 1] || {};

  if (lastLeg.arrivalTimeTz) {
    return isAfter(new Date(), parseISO(lastLeg.arrivalTimeTz));
  } else {
    return isAfter(new Date(), addOneDay(parseISO(lastLeg.arrivalTime)));
  }
};

export const isFlightSegmentDepartureInThePast = (segment: UIFlightSegment): boolean => {
  const legs = segment.legs || [];
  const firstLeg = legs[0] || {};

  if (firstLeg.departureTimeTz) {
    return isAfter(new Date(), parseISO(firstLeg.departureTimeTz));
  } else {
    return isAfter(new Date(), addOneDay(parseISO(firstLeg.departureTime)));
  }
};

export default isPastBooking;
