import * as React from "react";
import { useReducer, useMemo, useContext, createContext } from "react";
export const createUseStore = (initialState, reducer) => {
    const INIT_STATE = "@@INIT_STATE";
    const REPLACE_STATE = "@@REPLACE_STATE";
    let DevTools; // eslint-disable-line
    const isClient = typeof process !== "undefined" && process?.env?.BUILD_TARGET === "client";
    if (isClient) {
        const extension = window.__REDUX_DEVTOOLS_EXTENSION__ || window.top?.__REDUX_DEVTOOLS_EXTENSION__;
        DevTools = extension && extension.connect();
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const StoreContext = createContext(initialState);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const DispatchContext = createContext(() => undefined);
    const rootReducer = function (state, action) {
        let newState = state;
        if (action.type === REPLACE_STATE) {
            newState = action.payload;
        }
        else {
            newState = reducer(state, action);
            DevTools && DevTools.send(action, newState);
        }
        return newState;
    };
    const useStoreReducer = () => {
        const [state, dispatch] = useReducer(rootReducer, initialState);
        return [state, dispatch];
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const StoreProvider = (props) => {
        const [state, dispatch] = useStoreReducer();
        useMemo(() => {
            if (DevTools) {
                DevTools.send({ type: INIT_STATE }, initialState);
                // eslint-disable-next-line
                return DevTools.subscribe((message) => {
                    if (message.type === "DISPATCH" && message.state) {
                        const newState = JSON.parse(message.state);
                        dispatch({ type: REPLACE_STATE, payload: newState });
                    }
                });
            }
        }, []); // eslint-disable-line
        return (React.createElement(StoreContext.Provider, { value: props.value || state },
            React.createElement(DispatchContext.Provider, { value: dispatch }, props.children)));
    };
    const useStore = () => {
        const state = useContext(StoreContext);
        return state;
    };
    const useActions = (actions) => {
        const dispatch = useContext(DispatchContext);
        const actionsWithDispatch = useMemo(() => {
            const mapped = Object.keys(actions).reduce((a, key) => {
                const action = actions[key];
                const actionWithDispatch = (...args) => dispatch(action(...args));
                a[key] = actionWithDispatch;
                return a;
            }, {});
            return mapped;
        }, []); // eslint-disable-line
        return actionsWithDispatch;
    };
    return { StoreProvider, useStore, useActions };
};
