import { UIFetchStatus, UIFlightData } from "@flights/types";
import { createTrackingUtilsV2 } from "utils/experiments";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useProfiler from "hooks/useProfiler";

const expConfig = {
  stages: {
    main: 1,
    direct: 2,
    meta: 3,
    solo: 4,
    non_solo: 5,
    cabin_bags_only: 6,
    checked_bags_only: 7,
    cabin_and_checked_bags: 8,
    ancillaries_seen_twice: 9
  },
  goals: {
    no_wait_full_fetch: 1,
    any_baggage_checkbox_clicked: 2,
    open_price_breakdown: 3,
    close_fd: 4
  },
  goalsWithValue: []
};

const desktop = createTrackingUtilsV2({
  ...expConfig,
  name: "flights_web_fd_select_baggage_desktop"
});

const mDot = createTrackingUtilsV2({
  ...expConfig,
  name: "flights_web_fd_select_baggage_mdot"
});

function getExp(isMobile: boolean) {
  return isMobile ? mDot : desktop;
}

function trackStages(isMobile: boolean, flight: UIFlightData, profiler: ReturnType<typeof useProfiler>) {
  if (flight?.brandedFareOffers) {
    return;
  }

  const { stages } = getExp(isMobile);
  const { ancillaries } = flight;
  const { isSolo } = profiler;

  stages.main();
  isOfMetaOrigin() ? stages.meta() : stages.direct();
  isSolo() ? stages.solo() : stages.non_solo();

  if (ancillaries?.cabinBaggagePerTraveller || ancillaries?.checkedInBaggage) {
    !ancillaries?.cabinBaggagePerTraveller && stages.checked_bags_only();
    !ancillaries?.checkedInBaggage && stages.cabin_bags_only();

    ancillaries?.cabinBaggagePerTraveller && ancillaries?.checkedInBaggage && stages.cabin_and_checked_bags();
  }
}

function trackNoWaitFullFetchGoal(isMobile: boolean, fetchStatus: UIFetchStatus) {
  if (fetchStatus === "loading") {
    getExp(isMobile).goals.no_wait_full_fetch();
  }
}

export default {
  getExp,
  trackStages,
  trackNoWaitFullFetchGoal
};
