export var FlightBadgeVariant;
(function (FlightBadgeVariant) {
    FlightBadgeVariant["ACCENT"] = "ACCENT";
    FlightBadgeVariant["BRAND_PRIMARY"] = "BRAND_PRIMARY";
    FlightBadgeVariant["CALLOUT"] = "CALLOUT";
    FlightBadgeVariant["CONSTRUCTIVE"] = "CONSTRUCTIVE";
    FlightBadgeVariant["DESTRUCTIVE"] = "DESTRUCTIVE";
    FlightBadgeVariant["GENIUS"] = "GENIUS";
    FlightBadgeVariant["MEDIA"] = "MEDIA";
    FlightBadgeVariant["NEUTRAL"] = "NEUTRAL";
    FlightBadgeVariant["OUTLINE"] = "OUTLINE";
    FlightBadgeVariant["PRIMARY"] = "PRIMARY";
})(FlightBadgeVariant || (FlightBadgeVariant = {}));
export const ORDER_TRANSFORM_KEYS = [
    "order",
    "checkInInfo",
    "manageBookingActions",
    "estimatedCancellationOptions",
    "rebookEligibility"
];
export var UIFareRulePolicy;
(function (UIFareRulePolicy) {
    UIFareRulePolicy[UIFareRulePolicy["REFUNDABLE_AND_CHANGEABLE"] = 1] = "REFUNDABLE_AND_CHANGEABLE";
    UIFareRulePolicy[UIFareRulePolicy["REFUNDABLE"] = 2] = "REFUNDABLE";
    UIFareRulePolicy[UIFareRulePolicy["CHANGEABLE"] = 3] = "CHANGEABLE";
    UIFareRulePolicy[UIFareRulePolicy["NOT_REFUNDABLE_OR_CHANGEABLE"] = 4] = "NOT_REFUNDABLE_OR_CHANGEABLE";
    UIFareRulePolicy[UIFareRulePolicy["NOT_REFUNDABLE"] = 5] = "NOT_REFUNDABLE";
    UIFareRulePolicy[UIFareRulePolicy["NOT_CHANGEABLE"] = 6] = "NOT_CHANGEABLE";
})(UIFareRulePolicy || (UIFareRulePolicy = {}));
