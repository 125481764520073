import { useEffect, useState } from "react";
import { getAccommodationsSummary } from "../api/getAccommodationsSummary";
import { UIXIndicativeData } from "../types";
import { UIFetchStatus } from "@flights/types";
import { useIndicativeSearchData } from "./useIndicativeSearchData";
import useContextName from "../../hooks/useContextName";
import useEvents from "../../hooks/useEvents";
import useRouteName from "../../hooks/useRouteName";

const initialState = {
  numPropertiesAvailable: 0,
  cityImageUrl: "",
  cityName: "",
  numberAdults: 0,
  numberChildren: 0,
  numberNights: 0,
  target: ""
};

const useAccommodationsSummary = () => {
  const [data, setData] = useState<UIXIndicativeData>(initialState);
  const [status, setStatus] = useState<UIFetchStatus>("initial");

  const routeName = useRouteName();
  const searchParamsData = useIndicativeSearchData(routeName === "checkout3");
  const contextName = useContextName();
  const { grumble } = useEvents();

  useEffect(() => {
    if (searchParamsData && status === "initial") {
      const headers = {
        "X-Flights-Context-Name": contextName
      };
      setStatus("loading");
      getAccommodationsSummary(searchParamsData, headers)
        .then((res: any) => {
          setData(res);
          setStatus("success");
        })
        .catch((err) => {
          grumble("Indicative: Fetch error", err);
          setStatus("failed");
        });
    }
  }, [contextName, data, grumble, searchParamsData, status]);

  return { data, status };
};

export default useAccommodationsSummary;
