import React, { useEffect, useMemo } from "react";
import { useI18n } from "@bookingcom/lingojs-react";
import { Divider } from "@bookingcom/bui-react";
import { UIAvailableExtraProducts, UIBrandedFareInfo, UIIncludedProductBySegmentCompressed } from "@flights/types";
import { getFeaturesToRender } from "../utils/features";
import { t } from "@bookingcom/lingojs-core";
import useTrackInconsistentBaggageFeatures from "hooks/ancillaries/useTrackInconsistentBaggageFeatures";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";
import { getBaggageFeaturesToRender } from "utils/baggage";
import { FareFeature } from "./FareFeature";
import styles from "./FareSimpleFeatures.module.css";
import Frame from "components/elements/Frame";
import FareFeatureHeading from "components/elements/CheckoutFareInner/components/FareFeatureHeading";
import flights_apex_web_move_branded_fare_price_to_top from "utils/experiments/apex/flights_apex_web_move_branded_fare_price_to_top";
import flights_apex_web_bf_icons_blackout from "utils/experiments/apex/flights_apex_web_bf_icons_blackout";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";

type Props = {
  brandedFareInfo: UIBrandedFareInfo;
  includedProducts?: UIIncludedProductBySegmentCompressed;
  ancillaries?: UIAvailableExtraProducts;
  sellableFeaturesRequiredByAirline?: boolean;
  isPreCheckAndPay?: boolean;
  showDivider?: boolean;
  showHeader?: boolean;
  showDescriptions?: boolean;
  showSubtitles?: boolean;
  isCompact?: boolean;
  compactHeight?: boolean;
};

const FareFeatures = ({
  brandedFareInfo,
  includedProducts,
  ancillaries,
  sellableFeaturesRequiredByAirline,
  showHeader,
  showDivider,
  isPreCheckAndPay,
  showDescriptions,
  showSubtitles,
  compactHeight = false,
  isCompact
}: Props) => {
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const route = useRouteName();

  useTrackInconsistentBaggageFeatures(includedProducts);

  if (!brandedFareInfo) throw new Error("FareSwitcher: missing branded fare info");

  const includeNonIncludedBaggage = sellableFeaturesRequiredByAirline && isPreCheckAndPay;

  const featuresToRender = useMemo(() => {
    return getFeaturesToRender(brandedFareInfo, {
      includeSellableFeatures: sellableFeaturesRequiredByAirline,
      isPreCheckAndPay
    });
  }, [brandedFareInfo, sellableFeaturesRequiredByAirline, isPreCheckAndPay]);

  const baggageFeaturesToRender = useMemo(() => {
    return !!flights_apex_web_move_baggage_features_to_server.variant()
      ? { includedBaggageFeatures: [], excludedBaggageFeatures: [] }
      : getBaggageFeaturesToRender(i18n, includedProducts, ancillaries, { includeNonIncludedBaggage });
  }, [includeNonIncludedBaggage, includedProducts, ancillaries, i18n]);

  const includedAndSellableFeatures = useMemo(() => {
    return [...baggageFeaturesToRender.includedBaggageFeatures, ...featuresToRender.includedFeatures];
  }, [baggageFeaturesToRender.includedBaggageFeatures, featuresToRender.includedFeatures]);

  const excludedFeatures = useMemo(() => {
    return [...baggageFeaturesToRender.excludedBaggageFeatures, ...featuresToRender.nonIncludedFeatures];
  }, [baggageFeaturesToRender.excludedBaggageFeatures, featuresToRender.nonIncludedFeatures]);

  const hasIncludedOrSellableFeatures = includedAndSellableFeatures.length > 0;
  const hasExcludedFeatures = excludedFeatures.length > 0;

  const hasSellableFeatures = useMemo(
    () => includedAndSellableFeatures.some((f) => f.availability === "SELLABLE"),
    [includedAndSellableFeatures]
  );

  useEffect(() => {
    if (route !== "checkout-fare") return;
    if (hasExcludedFeatures) flights_apex_web_bf_icons_blackout.stages.has_exclusions();
    if (hasSellableFeatures) flights_apex_web_bf_icons_blackout.stages.has_sellables();

    if (!isMobile && (hasExcludedFeatures || hasSellableFeatures))
      flights_apex_web_move_branded_fare_price_to_top.stages.with_exclusions_or_sellable();
  }, [route, hasExcludedFeatures, hasSellableFeatures, isMobile]);

  useEffect(() => {
    flights_apex_web_move_baggage_features_to_server.stages.main();
    if (sellableFeaturesRequiredByAirline) flights_apex_web_move_baggage_features_to_server.stages.sellable_required();
  }, [sellableFeaturesRequiredByAirline]);

  if (!hasIncludedOrSellableFeatures && !hasExcludedFeatures) return null;

  return (
    <>
      {showHeader && hasIncludedOrSellableFeatures && (
        <FareFeatureHeading>{i18n.trans(t("flight_fare_included"))}</FareFeatureHeading>
      )}

      {includedAndSellableFeatures.map((feature) => (
        <FareFeature
          key={feature.name}
          IconSVG={feature.icon}
          content={feature.label}
          description={showDescriptions ? feature.description : undefined}
          subtitle={showSubtitles ? feature.subtitle : undefined}
          availability={feature.availability}
          isCompact={isCompact}
          compactHeight={compactHeight}
        />
      ))}

      {showDivider && hasExcludedFeatures && hasIncludedOrSellableFeatures && (
        <Frame mb={4}>
          <Divider className={styles.divider} attributes={{ "data-testid": "fareFeatureDivider" }} />
        </Frame>
      )}

      {excludedFeatures.map((feature) => (
        <FareFeature
          key={feature.name}
          content={feature.label}
          availability={feature.availability}
          IconSVG={feature.icon}
          compactHeight={compactHeight}
        />
      ))}
    </>
  );
};

export default FareFeatures;
