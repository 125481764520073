import { UIFlightData } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useActions, useStore } from "../../../../store";
import { trackExperiment, trackCustomGoal } from "utils/et";
import { PRE_OPTED_ANCILLARIES_QUERY_PARAMS } from "../../../../constants";
import { actions as extraProductActions } from "../../../../store/extraProducts/actions";

export default function useGoToFlightDetails() {
  const { search } = useStore();
  const params = useParams<{ airports: string }>();
  const { createUrl } = useCreateUrl();
  const history = useHistory();
  const actions = useActions(extraProductActions);

  return useCallback(
    (flightToken: string, baseOffer: UIFlightData, withFlexTicket?: boolean, withCfarTicket?: Boolean) => {
      const isBrandedFare = baseOffer.token !== flightToken;

      const locationParams =
        search.type === "ROUNDTRIP" && trackExperiment("flights_web_inbound_outbound_sr")
          ? window.location.search
          : location.search;

      trackCustomGoal("flights_apex_branded_fare_from_cache_web", 3); // Click fare card
      const urlParams = new URLSearchParams(locationParams);

      urlParams.set("baseOfferToken", baseOffer.token);

      if (withFlexTicket && !isBrandedFare) {
        urlParams.set(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.flexibleTicket, "true");
        actions.addPreOptInProduct("flexibleTicket", true);
      } else if (withCfarTicket && !isBrandedFare) {
        urlParams.set(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.cancelForAnyReason, "true");
        actions.addPreOptInProduct("cancelForAnyReason", true);
      } else {
        urlParams.delete(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.flexibleTicket);
        urlParams.delete(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.cancelForAnyReason);
        actions.removePreOptInProduct("flexibleTicket");
        actions.removePreOptInProduct("cancelForAnyReason");
      }

      history.push(createUrl(`/flights/${params.airports}/${flightToken}/?${urlParams}`));
    },
    [actions, createUrl, history, params.airports, search.type]
  );
}
