import { SBSearchType, SBSortingOrder } from "@bookingcom/flights-searchbox/@types/client";

import {
  UIOccupancyAndCabinClass,
  UILocation,
  UIFetchStatus,
  UIDateFares,
  UIClientFetchError,
  UIFlightTimesAgg
} from "@flights/types";

export const SEARCH_DATE_FORMAT = "yyyy-MM-dd";

export type UISearchSegment = {
  from: UILocation[];
  to: UILocation[];
  departureDate: string | undefined;
  returnDate?: string;
};

export type UIFareCalendarFare = {
  fetchStatus: UIFetchStatus;
  dateFares: UIDateFares[] | [];
  error?: UIClientFetchError;
};

export type State = {
  type: SBSearchType;
  searchSegments: UISearchSegment[];
  sort: SBSortingOrder;
  page: number;
  filters: {
    stops: number | undefined;
    /* start - flights_web_stops_filter_checkbox */
    multiSelectStops: number[] | undefined;
    /* end - flights_web_stops_filter_checkbox */
    duration: number | undefined;
    airlines: string[] | undefined;
    departureIntervals: number[][];
    arrivalIntervals: number[][];
    flightTimes: UIFlightTimesAgg;
    shortLayoverConnection?: boolean;
    includedBaggage?: string[];
    maxBudget?: number;
    maxBudgetPerAdult?: number;
    airports?: string[];
    preferSameAirport?: string;
    maxLayoverDuration?: number;
  };
  filtersDraft: {
    stops: number | undefined;
    /* start - flights_web_stops_filter_checkbox */
    multiSelectStops: number[] | undefined;
    /* end - flights_web_stops_filter_checkbox */
    duration: number | undefined;
    airlines: string[] | undefined;
    departureIntervals: number[][];
    arrivalIntervals: number[][];
    flightTimes: UIFlightTimesAgg;
    shortLayoverConnection?: boolean;
    includedBaggage?: string[];
    maxBudget?: number;
    maxBudgetPerAdult?: number;
    airports?: string[];
    preferSameAirport?: string;
    maxLayoverDuration?: number;
  };
  isDirect: boolean;
  fareCalendar: UIFareCalendarFare;
} & UIOccupancyAndCabinClass;

export const getInitialState = (): State => ({
  type: "ROUNDTRIP",
  searchSegments: [
    {
      from: [],
      to: [],
      departureDate: undefined,
      returnDate: undefined
    }
  ],
  adults: 1,
  children: [],
  cabinClass: "ECONOMY",
  sort: "BEST",
  page: 1,
  filters: {
    stops: undefined,
    /* start - flights_web_stops_filter_checkbox */
    multiSelectStops: undefined,
    /* end - flights_web_stops_filter_checkbox */
    duration: undefined,
    airlines: undefined,
    departureIntervals: [],
    arrivalIntervals: [],
    maxBudgetPerAdult: undefined,
    flightTimes: []
  },
  filtersDraft: {
    stops: undefined,
    /* start - flights_web_stops_filter_checkbox */
    multiSelectStops: undefined,
    /* end - flights_web_stops_filter_checkbox */
    duration: undefined,
    airlines: undefined,
    departureIntervals: [],
    arrivalIntervals: [],
    maxBudgetPerAdult: undefined,
    flightTimes: []
  },
  isDirect: false,
  fareCalendar: {
    fetchStatus: "initial",
    dateFares: [],
    error: undefined
  }
});
