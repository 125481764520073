/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect } from "react";
import { Card, Icon, Image, Stack, Text, useViewport, Link } from "@bookingcom/bui-react";
import { Trans, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { CheckmarkFillIcon, PopoutIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { useStore } from "../../../store";
import { percentageDiscount } from "../../utils";
import useAccommodationsSummary from "../../hooks/useAccommodationsSummary";
import { getHigherResImage } from "../../../hooks/useHigherResolutionImages";
import useLoader from "../../hooks/useLoader";
import { useClickHandlers } from "cross-sell/hooks/useClickHandlers";
import styles from "./IndicativeCard.module.css";
import { trackCustomGoal } from "utils/et";
import {
  TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
  TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_GOALS
} from "constants/experiments";

const IndicativeCard = () => {
  const { isSmall } = useViewport();
  const i18n = useI18n();
  const store = useStore();
  const loader = useLoader("indicative");

  const { data, status } = useAccommodationsSummary();
  const { handleButtonClick } = useClickHandlers("ConfirmationLoader");

  const { userInfo } = store.user;
  const geniusLevel = userInfo?.geniusLevel;
  const { cityName } = data;

  const imageUrl = getHigherResImage(data?.cityImageUrl, "square200", isSmall ? "343x184" : "212x132");

  useEffect(() => {
    if (status === "success") {
      trackCustomGoal(
        TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
        TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_GOALS.VALID_RESPONSE_RECEIVED
      );
    }
    if (status === "failed") {
      trackCustomGoal(
        TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
        TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_GOALS.EMPTY_OR_ERROR_RESPONSE_RECEIVED
      );
    }
  }, [status]);

  const usp1 =
    userInfo && geniusLevel ? (
      <Trans
        className={styles.wrap}
        tag="flights_xsell_booking_modal_abu_genius_usp_1_logged_in"
        components={[<strong key={0} />]}
        variables={{
          start_bold: "<0>",
          end_bold: "</0>",
          num_percent: percentageDiscount(geniusLevel),
          genius_lev_num: geniusLevel
        }}
      />
    ) : (
      <Trans
        className={styles.wrap}
        tag="flights_xsell_booking_modal_abu_genius_usp_1_logged_out"
        components={[<strong key={0} />]}
        variables={{
          start_bold: "<0>",
          end_bold: "</0>"
        }}
      />
    );

  if (status === "initial" || status === "loading") {
    return loader;
  }

  const fallbackImage = (
    <div className={styles.fallbackImgWrapper}>
      <Image
        className={styles.fallbackImg}
        contentMode="fill"
        width={isSmall ? "190px" : "140px"}
        asset={{
          setName: "illustrations-traveller",
          assetName: "TripsAccomodationsBed"
        }}
      />
    </div>
  );

  return (
    <div data-small={isSmall ? "true" : "false"} className={styles.wrapper} role="presentation">
      <Card fill className={styles.root}>
        <Stack direction={isSmall ? "column" : "row"} wrap={isSmall ? "wrap" : "nowrap"}>
          {!imageUrl ? (
            fallbackImage
          ) : (
            <div
              className={styles.left}
              style={{
                background: `url(${imageUrl}) center no-repeat, url(${data.cityImageUrl}) center no-repeat`
              }}
            />
          )}
          <Stack.Item className={styles.right}>
            <Stack direction="column" wrap="wrap" gap={3}>
              <Stack.Item>
                {cityName ? (
                  <Text variant="headline_3">
                    {i18n.trans(
                      t("flights_xsell_booking_modal_abu_usp_headline", {
                        variables: { in_city_name: cityName, city_name: cityName }
                      })
                    )}
                  </Text>
                ) : null}
              </Stack.Item>
              <Stack.Item className={styles.textRow}>
                <Icon svg={CheckmarkFillIcon} color="constructive" size="medium" className={styles.icon} />
                {usp1}
              </Stack.Item>
              <Stack.Item className={styles.textRow}>
                <Icon svg={CheckmarkFillIcon} color="constructive" size="medium" className={styles.icon} />
                <Trans
                  className={styles.wrap}
                  tag="flights_xsell_booking_modal_abu_free_cancellation_usp_2"
                  components={[<strong key={0} />]}
                  variables={{
                    start_bold: "<0>",
                    end_bold: "</0>"
                  }}
                />
              </Stack.Item>
              <Link
                className={styles.uspLink}
                href={data.target}
                onClick={() => {
                  trackCustomGoal(
                    TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
                    TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_GOALS.CLICKED_ON_LINK
                  );
                  handleButtonClick(data.target, i18n.trans(t("flights_xsell_booking_modal_abu_usp_cta")));
                }}
                attributes={{
                  "aria-label": i18n.trans(t("flights_xsell_booking_modal_abu_usp_cta")),
                  rel: "noopener noreferrer",
                  target: "_blank"
                }}
              >
                <Stack.Item className={styles.textRow}>
                  <Icon
                    ariaLabel={i18n.trans(t("ally_flights_xsell_booking_modal_abu_usp_cta_icon"))}
                    svg={PopoutIcon}
                    color="action"
                    size="medium"
                    className={styles.icon}
                  />
                  {i18n.trans(t("flights_xsell_booking_modal_abu_usp_cta"))}
                </Stack.Item>
              </Link>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
    </div>
  );
};

export default IndicativeCard;
