/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect } from "react";
import AirlinesModal from "../SearchFilters/Filters/AirlinesModal";
import BudgetPerAdultModal from "../SearchFilters/Filters/BudgetPerAdultModal";
import DurationModal from "../SearchFilters/Filters/DurationModal";
import FlightTimesModal from "../SearchFilters/Filters/FlightTimesModal";
import StopsModal from "../SearchFilters/Filters/StopsModal";
import SortModal from "../SearchFilters/Filters/SortModal";
import { useStore } from "../../../store";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useSearchFilters from "hooks/useSearchFilters";
import { Button, SheetContainerProps, SkeletonLoader } from "@bookingcom/bui-react";
import useSearchFiltersController from "../SearchFilters/useSearchFiltersController";
import styles from "./FiltersToolbar.module.css";
import { trackCustomGoal } from "utils/et";

export type FiltersToolbarProps = {
  position?: SheetContainerProps["position"];
};

export default function FiltersToolbar(props: FiltersToolbarProps) {
  const { position } = props;
  const store = useStore();
  const i18n = useI18n();
  const { applyFilters } = useSearchFilters();
  const { resetAllFilters, appliedFiltersCount } = useSearchFiltersController();

  const {
    search: { filtersDraft },
    searchResults: { fetchStatus, flights }
  } = store;

  const handleClearAllFilters = useCallback(() => {
    trackCustomGoal("flights_web_ddot_sr_wide_page_layout", 5);
    resetAllFilters();
  }, [resetAllFilters]);

  useEffect(() => {
    applyFilters(filtersDraft);
  }, [applyFilters, filtersDraft]);

  if (fetchStatus === "initial" || fetchStatus === "loading")
    return (
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <SkeletonLoader className={styles.loader} />
        </div>
      </div>
    );

  if (!flights.length) return null;

  return (
    <div aria-label={i18n.trans(t("a11y_flights_filters_intro_hidden_heading"))} className={styles.wrapper}>
      <div className={styles.inner}>
        <SortModal position={position} />
        <StopsModal position={position} />
        <BudgetPerAdultModal position={position} />
        <AirlinesModal position={position} />
        <FlightTimesModal position={position} />
        <DurationModal position={position} />
        {appliedFiltersCount > 0 ? (
          <Button
            variant="tertiary"
            text={i18n.trans(t("flights_mdot_filters_remove_cta"))}
            onClick={handleClearAllFilters}
          />
        ) : null}
      </div>
    </div>
  );
}
