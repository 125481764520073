/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Banner } from "@bookingcom/bui-react";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { useStore } from "../../../../store";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import Frame from "components/elements/Frame";

const NoDirectFlightsBanner = () => {
  const i18n = useI18n();
  const {
    searchResults: { directFlightsOnlyFilterIgnored }
  } = useStore();
  if (!directFlightsOnlyFilterIgnored) return null;
  return (
    <Frame mb={4}>
      <Banner
        dismissible={false}
        variant="callout"
        startIcon={InfoSignIcon}
        attributes={{ "data-testid": "no_direct_flights_banner" }}
      >
        {i18n.trans(t("flights_search_results_no_direct_flights_options_banner"))}
      </Banner>
    </Frame>
  );
};

export default NoDirectFlightsBanner;
