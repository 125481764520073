import { BrandedFareClientFeature, BrandedFareFeatureConfig } from "@flights/types";
import {
  CHANGEABLE_TICKET_FEATURES,
  REFUNDABLE_TICKET_FEATURES,
  BRANDED_FARE_FEATURES_CONFIG
} from "constants/brandedFares";
import { UIBrandedFareFeature, UIBrandedFareFeatureAvailability, UIBrandedFareInfo } from "@flights/types";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";

type RenderFeaturesOptions = {
  includeSellableFeatures?: boolean;
  isPreCheckAndPay?: boolean;
};

export const getFeaturesToRender = (brandedFareInfo: UIBrandedFareInfo, options: RenderFeaturesOptions = {}) => {
  const sellableFeatures = options.includeSellableFeatures
    ? getWithAvailability(brandedFareInfo.sellableFeatures, "SELLABLE")
    : [];
  const nonIncludedFeatures = getWithAvailability(brandedFareInfo.nonIncludedFeatures, "NOT_INCLUDED");
  const included = getWithAvailability(brandedFareInfo.features, "INCLUDED");
  const includedFeatures = filterRenderableFeatures(included, sellableFeatures, options);

  return { includedFeatures, nonIncludedFeatures: filterRenderableFeatures(nonIncludedFeatures, [], options) };
};

const getWithAvailability = <T extends Pick<UIBrandedFareFeature, "availability">>(
  features: T[] | undefined,
  availability: UIBrandedFareFeatureAvailability
) => (features || []).filter((f) => f.availability === availability);

const filterRenderableFeatures = (
  features: UIBrandedFareFeature[],
  alternativeFeatures: UIBrandedFareFeature[] = [],
  options: RenderFeaturesOptions
) => {
  const compressed = compressSimilarFeatures(features);
  const alternativeCompressed = compressSimilarFeatures(alternativeFeatures);
  const renderableFeatures: BrandedFareClientFeature[] = [];

  for (const config of BRANDED_FARE_FEATURES_CONFIG) {
    const { name, group, icon } = config;

    if (!flights_apex_web_move_baggage_features_to_server.variant() && group === "BAGGAGE") continue;

    let foundFeatures = findFeatures(config, compressed);
    if (foundFeatures.length === 0) foundFeatures = findFeatures(config, alternativeCompressed);

    for (const found of foundFeatures) {
      if (!found?.label) continue;

      const { label, availability, description, subtitle } = found;

      if (group === "BAGGAGE" && availability !== "INCLUDED" && !options.isPreCheckAndPay) continue;
      if (
        availability === "SELLABLE" &&
        (!options.includeSellableFeatures || (group !== "FLEXIBILITY" && !options.isPreCheckAndPay))
      )
        continue;

      renderableFeatures.push({ name, label, group, icon, availability, description, subtitle });
    }
  }

  return renderableFeatures;
};

const findFeatures = (config: BrandedFareFeatureConfig, features: UIBrandedFareFeature[]) =>
  features.filter((f) => f.featureName === config.name);

const compressSimilarFeatures = (features: UIBrandedFareFeature[]): UIBrandedFareFeature[] => {
  const compressedFeatures: UIBrandedFareFeature[] = [];
  let changeable = false;
  let refundable = false;

  features.forEach((feature) => {
    const { featureName } = feature;
    if (CHANGEABLE_TICKET_FEATURES.includes(featureName)) {
      if (!changeable) {
        compressedFeatures.push(feature);
        changeable = true;
      }
    } else if (REFUNDABLE_TICKET_FEATURES.includes(featureName)) {
      if (!refundable) {
        compressedFeatures.push(feature);
        refundable = true;
      }
    } else {
      compressedFeatures.push(feature);
    }
  });

  return compressedFeatures;
};
