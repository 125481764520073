import {
  BrandedFareClientFeature,
  Entries,
  UIAvailableExtraProducts,
  UIBrandedFareFeature,
  UIIncludedProductBySegmentCompressed,
  UILuggageType
} from "@flights/types";
import formatting from "./formatting";
import { I18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { getProductsIncludedInAllSegments } from "utils/includedProducts";
import { getFeatureConfigsByName } from "./brandedFares";
import { BAGGAGE_FEATURE_MAP } from "constants/ancillaries";

type BaggageFeaturesToRenderOptions = {
  includeNonIncludedBaggage?: boolean;
};

export const getBaggageFeaturesToRender = (
  i18n: I18nChildContext,
  includedProducts?: UIIncludedProductBySegmentCompressed,
  ancillaries?: UIAvailableExtraProducts,
  options?: BaggageFeaturesToRenderOptions
) => {
  const baggageFeaturesToRender = getBaggageFeatures(i18n, includedProducts, ancillaries, {
    includeIncludedBaggage: true,
    includeNonIncludedBaggage: options?.includeNonIncludedBaggage
  });
  const includedBaggageFeatures = baggageFeaturesToClientFeatures(
    baggageFeaturesToRender.includedAndSellableBaggageFeatures
  );
  const excludedBaggageFeatures = baggageFeaturesToClientFeatures(baggageFeaturesToRender.excludedBaggageFeatures);

  return { includedBaggageFeatures, excludedBaggageFeatures };
};

/**
 * This function is temporarily neccesary during the migration of baggage features to node layer.
 * The mapping for the new server side baggage features will be be handled by getFeaturesToRender().
 */
const baggageFeaturesToClientFeatures = (features: UIBrandedFareFeature[]) => {
  const clientFeatures: BrandedFareClientFeature[] = [];
  const featureConfigs = getFeatureConfigsByName();

  for (const feature of features) {
    const featureConfig = featureConfigs[feature.featureName];

    if (!feature.label || !featureConfig) continue;

    clientFeatures.push({
      name: feature.featureName,
      label: feature.label,
      availability: feature.availability,
      icon: featureConfig.icon,
      group: featureConfig.group
    });
  }

  return clientFeatures;
};

type BaggageFeaturesOptions = {
  includeIncludedBaggage?: boolean;
  includeNonIncludedBaggage?: boolean;
};

export const getBaggageFeatures = (
  i18n?: I18nChildContext | I18n,
  includedProducts?: UIIncludedProductBySegmentCompressed,
  ancillaries?: UIAvailableExtraProducts,
  options: BaggageFeaturesOptions = {}
) => {
  const includedBaggageFeatures: UIBrandedFareFeature[] = [];
  const includedAndSellableBaggageFeatures: UIBrandedFareFeature[] = [];
  const sellableBaggageFeatures: UIBrandedFareFeature[] = [];
  const excludedBaggageFeatures: UIBrandedFareFeature[] = [];
  const luggageItems = getLuggageItems(includedProducts);
  const result = {
    includedBaggageFeatures,
    includedAndSellableBaggageFeatures,
    sellableBaggageFeatures,
    excludedBaggageFeatures
  };

  if (!i18n || !luggageItems) return result;

  for (const [type, baseFeature] of Object.entries(BAGGAGE_FEATURE_MAP) as Entries<typeof BAGGAGE_FEATURE_MAP>) {
    const includedBaggage = luggageItems.filter((item) => item.luggageType === type);

    if (options.includeIncludedBaggage) {
      for (const item of includedBaggage) {
        const feature: UIBrandedFareFeature = {
          ...baseFeature,
          label: formatting.piecesOfLuggageWithWeight(item, i18n),
          availability: "INCLUDED"
        };
        includedBaggageFeatures.push(feature);
        includedAndSellableBaggageFeatures.push(feature);
      }
    }

    if (includedBaggage.length > 0) continue;

    const isSellable = isLuggageTypeSellable(type, ancillaries);

    if (isSellable && options.includeNonIncludedBaggage) {
      const feature: UIBrandedFareFeature = {
        ...baseFeature,
        label: formatting.sellableLuggage(type, i18n),
        availability: "SELLABLE"
      };
      sellableBaggageFeatures.push(feature);
      includedAndSellableBaggageFeatures.push(feature);
    }

    if (!isSellable && options.includeNonIncludedBaggage) {
      excludedBaggageFeatures.push({
        ...baseFeature,
        label: formatting.excludedLuggage(type, i18n),
        availability: "NOT_INCLUDED"
      });
    }
  }

  return result;
};

const getLuggageItems = (
  includedProducts?: UIIncludedProductBySegmentCompressed,
  onlyShowIncludedInAllSegments?: boolean
) => {
  if (!includedProducts) return undefined;

  return onlyShowIncludedInAllSegments
    ? getProductsIncludedInAllSegments(includedProducts)
    : includedProducts.segments[0];
};

export const isLuggageTypeSellable = (type: UILuggageType, ancillaries?: UIAvailableExtraProducts): boolean => {
  switch (type) {
    case "HAND":
      return !!ancillaries?.cabinBaggage || !!ancillaries?.cabinBaggagePerTraveller;
    case "CHECKED_IN":
      return !!ancillaries?.checkedInBaggage?.options[0]?.luggageAllowance || !!ancillaries?.specialBags;
    default:
      return false;
  }
};
