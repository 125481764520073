import { UICartDetails, UIClientFetchError, UIFetchStatus } from "@flights/types";
import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";

export enum ActionTypes {
  fetch = "cartDetails/fetch",
  addProductFetch = "cartDetails/addProductFetch",
  fetchSuccess = "cartDetails/fetchSuccess",
  fetchError = "cartDetails/fetchError",
  reset = "cartDetails/reset"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),
  addProductFetch: createAction((fetchStatus: UIFetchStatus) => ({
    type: ActionTypes.addProductFetch,
    payload: fetchStatus
  })),

  fetchSuccess: createAction((cartDetails: UICartDetails) => ({
    type: ActionTypes.fetchSuccess,
    payload: { cartDetails }
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
