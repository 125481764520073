/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { forwardRef, HTMLAttributes } from "react";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import { Badge } from "@bookingcom/bui-react";
import GeniusBadge from "@bookingcom/bui-assets-react/genius/GeniusBadge";
import useExpCopyBestNewWord from "hooks/useExpCopyBestNewWord";
import { FlightBadgeVariant, UIFlightBadge } from "@flights/types";
import { FlightCardBadgeInteractiveWrapper } from "./FlightCardBadgeInteractiveWrapper";
import styles from "./FlightCardBadge.module.css";

type Props = {
  style?: typeof FlightBadgeVariant[keyof typeof FlightBadgeVariant];
  alternative?: boolean;
  text?: string;
  attributes?: HTMLAttributes<HTMLDivElement>;
  type?: UIFlightBadge["type"];
  hover?: {
    title: string;
    text: string;
  };
  isInteractive?: boolean;
};

export const FlightCardBadge = forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const {
    style = FlightBadgeVariant.PRIMARY,
    alternative = false,
    text,
    hover: _hover,
    attributes,
    type: badgeType,
    isInteractive = true
  } = props;
  const i18n = useI18n() as I18nChildContext;
  const expCopyBestNewWord = useExpCopyBestNewWord();

  const getVariant = (style: typeof FlightBadgeVariant[keyof typeof FlightBadgeVariant]) =>
    style === FlightBadgeVariant.BRAND_PRIMARY
      ? "brand-primary"
      : style === FlightBadgeVariant.PRIMARY
      ? "brand-primary"
      : style === FlightBadgeVariant.ACCENT
      ? "accent"
      : style === FlightBadgeVariant.CALLOUT
      ? "callout"
      : style === FlightBadgeVariant.CONSTRUCTIVE
      ? "constructive"
      : style === FlightBadgeVariant.DESTRUCTIVE
      ? "destructive"
      : style === FlightBadgeVariant.MEDIA
      ? "media"
      : style === FlightBadgeVariant.OUTLINE
      ? "outline"
      : style === FlightBadgeVariant.GENIUS
      ? "brand-genius-primary"
      : undefined;

  const hover = isInteractive ? _hover : undefined;

  if (style === FlightBadgeVariant.GENIUS) {
    return (
      <FlightCardBadgeInteractiveWrapper hover={hover}>
        <span
          className={styles.geniusBadge}
          aria-label={i18n.trans(t("a11y_flights_search_results_card_genius_label"))}
          role="img"
        >
          <GeniusBadge />
        </span>
      </FlightCardBadgeInteractiveWrapper>
    );
  }

  if (isNil(text) || isEmpty(text)) return null;

  return (
    <FlightCardBadgeInteractiveWrapper hover={hover}>
      <Badge
        className={getVariant(style)}
        text={expCopyBestNewWord.getLabel(text, badgeType) || text}
        variant={getVariant(style)}
        alternative={alternative}
        attributes={{ ref, "aria-hidden": attributes?.["aria-hidden"] }}
      />
    </FlightCardBadgeInteractiveWrapper>
  );
});

FlightCardBadge.displayName = "FlightCardBadge";
