import React from "react";
import {
  UIPointOfSale,
  MarketingTrackingVariables,
  UICampaignBanner,
  AvailableCurrenciesSymbols
} from "@flights/types";
import { LocaleState } from "@flights/types";
import { ClientSideExperiments } from "@flights/types";
import { ClientSideFeature } from "constants/features";
import { getI18nState } from "server/modules/i18nWithExp";

type I18NState = ReturnType<typeof getI18nState>;

declare global {
  interface Window {
    __GLOBAL_CONTEXT__: ContextProps;
    __LOCALE_STATE__: LocaleState;
    __I18N_STATE__: I18NState;
    gtag: any;
    loadTagManager?: Function;
    ga?: Function;
    riskifiedBeaconLoad: Function;
    dataLayer: any[];
  }
}

export type ContextProps = {
  requestId: string;
  userAgent: {
    isMobile?: boolean;
    isTablet?: boolean;
    isWebview?: boolean;
    isBot?: boolean;
    browser?: string;
  };
  isKnownBot?: boolean;
  locale?: string;
  availableLocales: string[];
  lang?: string;
  ipCountry?: UIPointOfSale;
  regionCode?: string;
  nearestAirport?: string;
  env?: typeof process.env.PLATFORM_ENV;
  payerId?: string;
  experiments: ClientSideExperiments;
  features: Partial<Record<ClientSideFeature, boolean>>;
  supplierFeatures: string[] | undefined;
  supportsChildrenTickets?: boolean;
  redirectToken?: string;
  isInternal?: boolean;
  showCopyTags?: boolean;
  envoyStaging?: {
    search?: boolean;
    cart?: boolean;
  };
  debugAllExtraProducts?: boolean;
  appVersion?: string;
  emkToken?: string;
  auth: {
    enabled: boolean;
    clientId: string;
    redirectUri: string;
    isAuthenticatedOnWeb: boolean;
  };
  marketingPartnershipsBrandingVariables?: MarketingPartnershipsBrandingVariables;
  marketingTrackingVariables?: MarketingTrackingVariables;
  uvi?: string;
  apiPrefix: string;
  shouldNotTrack: boolean;
  isFlightsDomain: boolean;
  myIp?: string;
  isPPC?: boolean;
  isOfMetaOrigin?: string;
  customerIp?: string;
  sessionId?: string;
  analyticSessionId?: string;
  offerInstanceId?: string;
  currencyCode?: AvailableCurrenciesSymbols;
  googleMapsApiKey?: string;
  searchHistory: SearchHistory[];
  clientsidePayload?: string;
  isExcludedFromPosMigrationPhaseOne?: boolean;
  voidExcludedCountries: string[];
  liveChatIncludedCountries: boolean;
  soylentEmail?: string;
  orderLoading?: boolean;
  etStateBlob?: string;
  contentStandardsGuidelines?: {
    message: string;
    linkLabel: string;
    linkUrl: string;
  };
  isCustomerServiceEnabledForPOS: boolean;
  tracingHeaders?: { [key: string]: string };
  indexPageCampaign?: UICampaignBanner;
  isPaymentCollectionPage?: boolean;
};

export default React.createContext<ContextProps>({
  requestId: "",
  userAgent: {},
  availableLocales: [],
  experiments: {},
  features: {},
  auth: { enabled: true, clientId: "", redirectUri: "", isAuthenticatedOnWeb: false },
  supplierFeatures: [],
  isFlightsDomain: true,
  shouldNotTrack: false,
  apiPrefix: "",
  myIp: "",
  searchHistory: [],
  voidExcludedCountries: [],
  liveChatIncludedCountries: false,
  isPaymentCollectionPage: false,
  isCustomerServiceEnabledForPOS: false
});
