import { UICarrier, UIFlightSegment, UIFlightCarrier } from "@flights/types";
import { useMemo } from "react";

interface CSSGridPosition {
  gridColumn: string;
  gridRow: string;
}

interface MJMLGridPosition {
  gridColumn: number;
  gridRow: number;
}

const CSS_GRID_MAPS = {
  1: {
    0: { gridColumn: "col1 / span 2", gridRow: "row1 / span 2" }
  },

  2: {
    0: { gridColumn: "col1", gridRow: "row1" },
    1: { gridColumn: "col2", gridRow: "row2" }
  },

  3: {
    0: { gridColumn: "col1", gridRow: "row1" },
    1: { gridColumn: "col1", gridRow: "row2" },
    2: { gridColumn: "col2", gridRow: "row2" }
  },

  4: {
    0: { gridColumn: "col1", gridRow: "row1" },
    1: { gridColumn: "col1", gridRow: "row2" },
    2: { gridColumn: "col2", gridRow: "row2" },
    3: { gridColumn: "col2", gridRow: "row1" }
  }
};

const MJML_COLUMN_MAPS = {
  1: {
    0: { gridColumn: 1, gridRow: 1 }
  },
  2: {
    0: { gridColumn: 1, gridRow: 1 },
    1: { gridColumn: 2, gridRow: 2 }
  },

  3: {
    0: { gridColumn: 1, gridRow: 1 },
    1: { gridColumn: 1, gridRow: 2 },
    2: { gridColumn: 2, gridRow: 2 }
  },

  4: {
    0: { gridColumn: 1, gridRow: 1 },
    1: { gridColumn: 1, gridRow: 2 },
    2: { gridColumn: 2, gridRow: 2 },
    3: { gridColumn: 2, gridRow: 1 }
  }
};

interface AirlineGridPositionEmail extends Pick<UICarrier, "logo"> {
  mjmlGridPosition: MJMLGridPosition;
}
interface AirlineGridPosition extends Pick<UICarrier, "logo"> {
  name: string;
  cssGridPosition: CSSGridPosition;
}

const useAirlineLogos = (segment: UIFlightSegment) => {
  const airlinesWithGridPosition = useMemo(() => {
    const uniqueLogos: { name: string; logo: string }[] = [];
    const logos: AirlineGridPosition[] = [];

    segment.legs.forEach((leg) => {
      const { carriersData, flightInfo } = leg;
      if (flightInfo.carrierInfo) {
        const { marketingCarrier } = flightInfo.carrierInfo;
        const carrier = carriersData.find((carrier) => carrier.code === marketingCarrier);
        // Ignore logos that we already added for non direct flights
        const isCarrierExisting = uniqueLogos.find((carrierInList) => carrierInList.name === carrier?.name);
        if (carrier && !isCarrierExisting) {
          uniqueLogos.push({ logo: carrier.logo, name: carrier.name });
        }
      }
    });

    const howManyAirlines = uniqueLogos.length > 4 ? 4 : uniqueLogos.length;
    let index = 0;
    uniqueLogos.forEach((uniqueLogo) => {
      if (index > 4) return;

      logos.push({
        logo: uniqueLogo.logo,
        name: uniqueLogo.name,
        cssGridPosition: CSS_GRID_MAPS[howManyAirlines][index]
      });

      index++;
    });

    return logos;
  }, [segment]);

  const selectFlightCarriers = useMemo(() => {
    const carrierDataForSegment: UIFlightCarrier[] = [];
    segment.legs.map((leg) => {
      const carrierForLeg: UIFlightCarrier = {};
      if (leg.carriers && leg.carriersData) {
        const operatingCarrierCode = leg.flightInfo.carrierInfo?.operatingCarrier;
        const marketingCarrierCode = leg.flightInfo.carrierInfo?.marketingCarrier;
        const operatingCarrierDisclosureText = leg.flightInfo.carrierInfo?.operatingCarrierDisclosureText;

        carrierForLeg.carrierData = leg.carriersData.find((o) => o.code === marketingCarrierCode);

        if (operatingCarrierCode !== marketingCarrierCode)
          carrierForLeg.operatingCarrierData = leg.carriersData.find(
            (carrier) => carrier.code === operatingCarrierCode
          );

        if (operatingCarrierDisclosureText) {
          carrierForLeg.operatingCarrierDisclosureText = operatingCarrierDisclosureText;
        }
      }
      !carrierDataForSegment.find((o) => o?.carrierData?.code === carrierForLeg.carrierData?.code) &&
        carrierDataForSegment.push(carrierForLeg);
    });
    return carrierDataForSegment;
  }, [segment]);

  const airlinesWithGridPositionForEmail = useMemo(() => {
    const uniqueLogos = new Set<string>();
    const logos: AirlineGridPositionEmail[] = [];

    segment.legs.forEach((leg) => {
      const { carriersData, flightInfo } = leg;
      if (flightInfo.carrierInfo) {
        const { marketingCarrier } = flightInfo.carrierInfo;
        const carrier = carriersData.find((carrier) => carrier.code === marketingCarrier);
        if (carrier) {
          uniqueLogos.add(carrier.logo);
        }
      }
    });

    const howManyAirlines = uniqueLogos.size > 4 ? 4 : uniqueLogos.size;
    let index = 0;
    uniqueLogos.forEach((logo) => {
      if (index > 4) return;

      logos.push({
        logo,
        mjmlGridPosition: MJML_COLUMN_MAPS[howManyAirlines][index]
      });
      index++;
    });

    return {
      logos: logos,
      columnCount: howManyAirlines > 1 ? 2 : 1,
      rowCount: howManyAirlines > 1 ? 2 : 1
    };
  }, [segment]);

  return {
    airlinesWithGridPosition,
    selectFlightCarriers,
    airlinesWithGridPositionForEmail
  };
};

export default useAirlineLogos;
