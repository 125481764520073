import { ClientSideExperiment } from "@flights/types";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "./et";
import { etV2 } from "./etV2";
import { getExtOriginMarketingTrackingVariable, isOfMetaOrigin } from "./marketing-url-params";

// This file is a wrapper for easily tracking meta related experiments

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use trackMetaExperimentStageV2 instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import { trackMetaExperimentStageV2 } from "utils/meta-et-tracking";
 * trackMetaExperimentStageV2("exp_name", 1);
 */
export const trackMetaExperimentStage = (expName: ClientSideExperiment, stage: number) => {
  const isMeta = isOfMetaOrigin();
  if (isMeta) {
    trackExperimentStage(expName, stage);
  }
};

export const trackMetaExperimentStageV2 = (expName: ClientSideExperiment, stage: number) => {
  const isMeta = isOfMetaOrigin();
  if (isMeta) {
    etV2.stage(expName, stage);
  }
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use trackMetaCustomGoalV2 instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import { trackMetaCustomGoalV2 } from "utils/meta-et-tracking";
 * trackMetaCustomGoalV2("exp_name", 1);
 */
export const trackMetaCustomGoal = (expName: ClientSideExperiment, goal: number) => {
  const isMeta = isOfMetaOrigin();
  if (isMeta) {
    trackCustomGoal(expName, goal);
  }
};

export const trackMetaCustomGoalV2 = (expName: ClientSideExperiment, goal: number) => {
  const isMeta = isOfMetaOrigin();
  if (isMeta) {
    etV2.customGoal(expName, goal);
  }
};

/**
 * @deprecated
 * We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use trackMetaExperimentV2 instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import { trackMetaExperimentV2 } from "utils/meta-et-tracking";
 * trackMetaExperimentV2("exp_name");
 */
export const trackMetaExperiment = (expName: ClientSideExperiment) => {
  const isMeta = getExtOriginMarketingTrackingVariable();
  if (!!isMeta) {
    trackExperiment(expName);
  }
};

export const trackMetaExperimentV2 = (expName: ClientSideExperiment) => {
  const isMeta = getExtOriginMarketingTrackingVariable();
  if (!!isMeta) {
    etV2.track(expName);
  }
};
