import {
  UIProductType,
  UIProductSelectionTypes,
  UIProductSelection,
  UIProductSelected,
  UIProductSeatMapSelected
} from "@flights/types";
import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";
import flights_apex_web_checkout_flow_improvements from "../../utils/experiments/apex/flights_apex_web_checkout_flow_improvements";

type AncillaryProductRelation =
  | "BY_TRAVELLER" // ancillary can be added per traveller
  | "BY_FLIGHT_SEGMENT_AND_LEG" // added for one leg
  | "BY_FLIGHT_PRODUCT" // added for one itinerary
  | "BY_ORDER"; // added for an entire reservation

type AncillaryRelationMap = Readonly<{ [key in UIProductType & UIProductSelectionTypes]: AncillaryProductRelation }>;

const relationTypes: AncillaryRelationMap = {
  cabinBaggage: "BY_FLIGHT_PRODUCT",
  cabinBaggagePerTraveller: "BY_TRAVELLER",
  flexibleTicket: "BY_TRAVELLER",
  seatingBesides: "BY_FLIGHT_PRODUCT",
  mobileTravelPlan: "BY_ORDER",
  travelInsurance: "BY_TRAVELLER",
  checkedInBaggage: "BY_TRAVELLER",
  mealPreference: "BY_TRAVELLER",
  seatingPreference: "BY_TRAVELLER",
  specialBags: "BY_TRAVELLER",
  cancelForAnyReason: "BY_ORDER",
  seatMapSelection: "BY_FLIGHT_SEGMENT_AND_LEG"
};

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        extraProducts: {},
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        extraProducts: action.payload.extraProducts,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        extraProducts: undefined
      };

    case ActionTypes.addProduct: {
      const { product, productType } = action.payload;
      const productRelation = relationTypes[productType];
      let currentSelection: UIProductSelection[typeof productType] = state.selected[productType] || [];
      flights_apex_web_checkout_flow_improvements.goals.user_select_any_ancillary();

      switch (productRelation) {
        case "BY_TRAVELLER":
          // append to products array
          if (currentSelection && "travellerReference" in product && product.travellerReference) {
            // remove previous choice for this product and traveller
            currentSelection = currentSelection.filter((item: any) => {
              return item.travellerReference !== product.travellerReference;
            });
          }
          return {
            ...state,
            selected: {
              ...state.selected,
              [productType]: [...currentSelection, action.payload.product]
            }
          };

        case "BY_FLIGHT_SEGMENT_AND_LEG":
          if (currentSelection && product?.travellerReference) {
            // remove previous choice for this product and traveller
            switch (productType) {
              case "seatMapSelection":
                const { travellerReference, segmentIndex, legIndex } = product as UIProductSeatMapSelected;
                currentSelection = (currentSelection as UIProductSeatMapSelected[]).filter((item) => {
                  return !(
                    item.travellerReference === travellerReference &&
                    item.segmentIndex === segmentIndex &&
                    item.legIndex === legIndex
                  );
                });
            }
          }
          return {
            ...state,
            selected: {
              ...state.selected,
              [productType]: [...currentSelection, action.payload.product]
            }
          };

        case "BY_ORDER":
          return {
            ...state,
            selected: {
              ...state.selected,
              [productType]: [action.payload.product]
            }
          };

        default:
          return {
            ...state,
            selected: {
              ...state.selected,
              [productType]: [action.payload.product]
            }
          };
      }
    }

    case ActionTypes.setProducts: {
      const { products, productType } = action.payload;
      flights_apex_web_checkout_flow_improvements.goals.user_select_any_ancillary();

      return {
        ...state,
        selected: {
          ...state.selected,
          [productType]: products
        }
      };
    }

    case ActionTypes.removeProduct: {
      const { travellerReference = "", airProductReference, productType, segmentIndex, legIndex } = action.payload;
      const currentSelection: UIProductSelected[] = state.selected[productType] || [];
      let selection: typeof currentSelection | undefined = undefined;

      if (travellerReference && segmentIndex !== undefined && legIndex !== undefined) {
        selection = currentSelection.filter((product: any) => {
          return !(
            product.travellerReference === travellerReference &&
            product.segmentIndex === segmentIndex &&
            product.legIndex === legIndex
          );
        });
      } else if (travellerReference) {
        // remove by travellerReference
        selection = currentSelection.filter((product: UIProductSelected) => {
          return !("travellerReference" in product && product.travellerReference === travellerReference);
        });
      } else if (airProductReference) {
        // remove by airProductReference
        selection = currentSelection.filter((product: UIProductSelected) => {
          return !("airProductReference" in product && product.airProductReference === airProductReference);
        });
      } else {
        // remove by name alone
        const selected = { ...state.selected };
        delete selected[productType];
        return { ...state, selected };
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          [productType]: selection
        }
      };
    }

    case ActionTypes.removeAllProducts: {
      const selected = { ...state.selected };
      delete selected[action.payload.productType];
      return { ...state, selected };
    }

    case ActionTypes.addPreOptInProduct: {
      const { productType, product } = action.payload;
      return {
        ...state,
        preOptedAncillaries: { ...state.preOptedAncillaries, [productType]: product }
      };
    }

    case ActionTypes.removePreOptInProduct: {
      const { productType } = action.payload;
      const preOptedAncillaries = { ...state.preOptedAncillaries };
      delete preOptedAncillaries[productType];
      return {
        ...state,
        preOptedAncillaries
      };
    }

    case ActionTypes.resetPreOptIn: {
      return {
        ...state,
        preOptedAncillaries: {}
      };
    }

    case ActionTypes.reset: {
      return {
        ...getInitialState(),
        preOptedAncillaries: state.preOptedAncillaries
      };
    }

    case ActionTypes.resetSelectedExtraProducts: {
      return {
        ...state,
        selected: {}
      };
    }
    case ActionTypes.setPageLoadTime: {
      return {
        ...state,
        pageLoadTime: { ...state.pageLoadTime, ...action.payload }
      };
    }

    default:
      return state;
  }
};

export { reducer, getInitialState };
