import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_ace_web_cfar_test_markets",
  stages: {
    cfar_available: 1, // main
    cfar_ticket_type_page: 2,
    cfar_baggage_extras_page: 3, // (or next in base)
    old90Days: 4,
    old150days: 5,
    new90days: 6,
    desktop: 7,
    mdot: 8,
    have_price_on_top: 9
  },
  goals: {
    flexibility_selection_error: 1,
    click_on_cancel_bookings_with_cfar: 2,
    click_on_cancel_bookings: 3
  },
  goalsWithValue: []
});

const trackStages = (isTicketTypePage: boolean, isMobile: boolean, bookWindowDays: number | null, pos?: string) => {
  utils.stages.cfar_available();
  if (isTicketTypePage) {
    utils.stages.cfar_ticket_type_page();
    utils.stages.have_price_on_top();
  } else {
    utils.stages.cfar_baggage_extras_page();
  }
  if (bookWindowDays) {
    if (bookWindowDays <= 90 && (pos === "dk" || pos === "fi" || pos === "nl")) {
      utils.stages.old90Days();
    } else if (bookWindowDays > 90 && (pos === "dk" || pos === "fi" || pos === "nl")) {
      utils.stages.old150days();
    } else if (bookWindowDays <= 90 && (pos === "be" || pos === "es" || pos === "pt")) {
      utils.stages.new90days();
    }
  }
  if (isMobile) {
    utils.stages.mdot();
  } else {
    utils.stages.desktop();
  }
};

export default { trackStages, ...utils };
