import { UITraveller } from "@flights/types";
import { createTrackingUtils } from "utils/experiments";
import flights_apex_web_bf_selection_cta_ddot from "./flights_apex_web_bf_selection_cta_ddot";
import flights_apex_web_bf_icons_blackout from "utils/experiments/apex/flights_apex_web_bf_icons_blackout";

const utils = createTrackingUtils({
  name: "flights_apex_web_move_branded_fare_price_to_top",
  stages: {
    branded_fare_page: 1,
    price_wrapping: 2,
    solo_travellers: 3,
    multi_travellers: 4,
    with_exclusions_or_sellable: 5,
    bf_cta_selection_exp: 6,
    bf_icons_removal_exp: 7,
    bf_popular_badge_exp: 8
  },
  goals: {
    click_price_info: 1,
    click_on_carousel: 2,
    next_visible: 3
  },
  goalsWithValue: []
});

const trackStages = (isMobile: boolean, travellers?: UITraveller[]) => {
  if (isMobile) return;

  utils.stages.branded_fare_page();

  if ((travellers?.length || 0) > 1) utils.stages.multi_travellers();
  else utils.stages.solo_travellers();

  if (flights_apex_web_bf_selection_cta_ddot.variant()) utils.stages.bf_cta_selection_exp();
  if (flights_apex_web_bf_icons_blackout.variant()) utils.stages.bf_icons_removal_exp();
};

export default { ...utils, trackStages };
