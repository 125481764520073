import React, { useState } from "react";
import { UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { lowerCase, startCase } from "lodash";
import { Accordion, Button, Card, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import FareFeaturesIcons from "./FareFeaturesIcons";
import FareCardPrice from "./FareCardPrice";
import FareFeatures from "components/elements/CheckoutFareInner/components/FareFeatures";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useGoToFlightDetails from "components/elements/FareSelector/hooks/useGoToFlightDetails";
import { trackExperiment } from "utils/et";
import Frame from "components/elements/Frame";
import { areSellableFeaturesRequiredByAirline } from "utils/brandedFares";
import useUserAgent from "hooks/useUserAgent";
import flights_apex_web_compact_branded_fares_for_mdot_sr from "utils/experiments/apex/flights_apex_web_compact_branded_fares_for_mdot_sr";

type FareCardProps = {
  fareOffer: UIBrandedFareOrFlexTicket;
  baseOffer: UIFlightData;
  fetchStatus: UIFetchStatus;
  index?: number;
};

const FareCard = ({ fareOffer, baseOffer, fetchStatus }: FareCardProps) => {
  const { brandedFareInfo, includedProducts, isFlexTicket, ancillaries } = fareOffer;
  const [showDetails, setShowDetails] = useState(false);
  const i18n = useI18n();
  const goToFlightDetails = useGoToFlightDetails();
  const theme = useTheme();
  const sellableFeaturesRequiredByAirline = areSellableFeaturesRequiredByAirline(baseOffer.segments);
  const { isMobile } = useUserAgent();

  const compactBrandedFares = !!flights_apex_web_compact_branded_fares_for_mdot_sr.variant() && isMobile;

  return (
    <Card>
      <div
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {compactBrandedFares ? (
          <>
            <Stack>
              {fetchStatus === "loading" ? (
                <Stack gap={6} direction="row">
                  <Stack.Item grow>
                    <SkeletonLoader />
                  </Stack.Item>
                  <SkeletonLoader width={theme.units.spacing_12x} />
                </Stack>
              ) : (
                <Text variant="strong_1">{startCase(lowerCase(brandedFareInfo?.fareName))}</Text>
              )}
              <FareCardPrice fareOffer={fareOffer} baseOffer={baseOffer} isFlexTicket={isFlexTicket} />
              <FareFeatures
                brandedFareInfo={brandedFareInfo}
                includedProducts={includedProducts}
                ancillaries={ancillaries}
                sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
                showHeader={false}
                isPreCheckAndPay={true}
                isCompact={compactBrandedFares}
                compactHeight={!!trackExperiment("flights_web_mdot_sr_card_single_cta")}
              />
            </Stack>

            <Frame pt={2}>
              <Button
                onClick={() => {
                  flights_apex_web_compact_branded_fares_for_mdot_sr.goals.click_child_offer_view_details();
                  goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket);
                }}
                variant="secondary"
                wide
              >
                {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
              </Button>
            </Frame>
          </>
        ) : (
          <Accordion
            active={showDetails}
            onOpen={() => {
              setShowDetails(true);
            }}
            onClose={() => setShowDetails(false)}
            titleContent={
              <Stack gap={!!trackExperiment("flights_web_mdot_sr_card_single_cta") ? 0.5 : undefined}>
                {fetchStatus === "loading" ? (
                  <Stack gap={6} direction="row">
                    <Stack.Item grow>
                      <SkeletonLoader />
                    </Stack.Item>
                    <SkeletonLoader width={theme.units.spacing_12x} />
                  </Stack>
                ) : (
                  <Text variant="strong_1">{startCase(lowerCase(brandedFareInfo?.fareName))}</Text>
                )}
                <FareCardPrice fareOffer={fareOffer} baseOffer={baseOffer} isFlexTicket={isFlexTicket} />
                {!showDetails && (
                  <FareFeaturesIcons includedProducts={includedProducts} brandedFareInfo={brandedFareInfo} limit={7} />
                )}
              </Stack>
            }
          >
            <FareFeatures
              brandedFareInfo={brandedFareInfo}
              includedProducts={includedProducts}
              ancillaries={ancillaries}
              sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
              showHeader={true}
              isPreCheckAndPay={true}
              compactHeight={!!trackExperiment("flights_web_mdot_sr_card_single_cta")}
            />

            <Frame pt={2}>
              <Button
                onClick={() => goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket)}
                variant="secondary"
                wide
              >
                {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
              </Button>
            </Frame>
          </Accordion>
        )}
      </div>
    </Card>
  );
};

export default FareCard;
