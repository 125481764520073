import { createTrackingUtils } from "../index";
import { UIBrandedFare, UIFlightData } from "@flights/types";

const utils = createTrackingUtils({
  name: "flights_apex_web_compact_branded_fares_for_mdot_sr",
  stages: {
    sees_any_bf_result_mdot: 1,
    sees_bf_offers_mdot: 2,
    sees_single_bf_offer_mdot: 3,
    more_than_3_child_offers: 4,
    between_3_to_5_requestable_bf_offers: 5,
    has_8_or_more_requestable_bf_offers: 6
  },
  goals: {
    click_child_offer_view_details: 1,
    click_hide_ticket_options_btn: 2
  },
  goalsWithValue: []
});

const { stages } = utils;

export const trackRequestableFares = (flights: UIFlightData[]) => {
  const requestableCount = flights.filter((flight) => flight.requestableBrandedFares === true).length;
  if (requestableCount >= 3 && requestableCount <= 5) stages.between_3_to_5_requestable_bf_offers();
  if (requestableCount >= 8) stages.has_8_or_more_requestable_bf_offers();
};

export const trackChildBfOffersStages = (brandedFareOffers: UIBrandedFare[] | undefined) => {
  stages.sees_any_bf_result_mdot();
  if (!brandedFareOffers || brandedFareOffers.length === 0) return;
  stages.sees_bf_offers_mdot();
  if (brandedFareOffers.length === 1) stages.sees_single_bf_offer_mdot();
  if (brandedFareOffers.length > 3) stages.more_than_3_child_offers();
};

export default { ...utils, trackRequestableFares, trackChildBfOffersStages };
