import { useEffect, useRef } from "react";
import { UIClientFetchError } from "@flights/types";
import clientFetch from "../utils/client-fetch";
import useContextName from "./useContextName";
import { appendFeatureAndExperimentParams, appendLanguageOptionParam } from "utils/fetchParams";
import usePointOfSale from "./usePointOfSale";

const debug = require("debug")("useClientFetch");

const CACHE_TIMEOUT = 1000 * 60 * 3;

type FetchOptions = {
  fetch?: (url?: string) => void;
  success: (data: any) => void;
  error: (error: UIClientFetchError) => void;
  condition?: () => void | boolean;
  headers?: Record<string, string>;
  method?: string;
  body?: string;
  cache?: boolean;
  delayFetchBy?: number;
  forceRefetch?: boolean;
  // Determines whether node should generate new clientside payload from context.
  // Every time node fetches order, it extracts soylent email id and registers it as a new identifier.
  // We use shouldGenerateNewClientsidePayload to get new payload and track users with soylent email
  shouldGenerateNewClientsidePayload?: boolean;
};

type ForceWithCacheOptions = FetchOptions & { forceRefetch?: boolean };

const cache = {};

// NOTE: remember this will be mounted/unmounted when navigation happens, so the
// useEffect memoization is only valid for duration of the component's lifecycle
export const useClientFetch = (_url: string, options: FetchOptions) => {
  const contextName = useContextName();

  /**
   * Must NOT be provided as a dependency to useEffect,
   * because it can change its' value while navigating from Home to SR and retrigger search request, leading to an infinite loop
   */
  const posCountry = useRef(usePointOfSale());

  useEffect(() => {
    if (process.env.BUILD_TARGET !== "client") return;

    const url = appendFeatureAndExperimentParams(appendLanguageOptionParam(_url));

    const {
      fetch: fetchStart,
      success: fetchSuccess,
      error: fetchError,
      condition,
      headers = {},
      delayFetchBy = 0,
      shouldGenerateNewClientsidePayload,
      forceRefetch
    } = options;

    // Note: we don't add actions to dependencies to allow defining them as inline functions
    // without requiring useCallback / useMemo
    const fetchData = () => {
      fetchStart?.(url);
      debug(`Fetching ${absoluteURL}`);

      fetchWithCache(url, { ...options, cache: !forceRefetch, headers })
        .then(fetchSuccess)
        .catch((error) => {
          fetchError(error.code ? error : { message: error.toString() });
        });
    };

    if (shouldGenerateNewClientsidePayload) {
      headers["X-Booking-Experiment-Prepare-Clientside-Payload"] = "1";
    }

    headers["X-Flights-Context-Name"] = contextName;

    if (posCountry.current) {
      headers["X-Flights-Context-POS"] = posCountry.current;
    }

    const absoluteURL = /^http/.test(url) ? url : `${location.protocol}//${location.host}${url}`;

    if (typeof condition === "function" && condition() != true) {
      debug(`Skipping fetch for ${absoluteURL}`);
      return;
    }

    if (delayFetchBy) {
      setTimeout(() => {
        fetchData();
      }, delayFetchBy);
    } else {
      fetchData();
    }

    return () => {
      debug(`Cleaning up after ${absoluteURL}`);
    };
  }, [_url, options.method, options.forceRefetch]); // eslint-disable-line
};

function pushToCache(url: string, obj: any) {
  const keys = Object.keys(cache);
  if (keys.length > 5) {
    delete cache[keys[0]];
  }
  cache[url] = obj;
  setTimeout(() => {
    cache[url] && delete cache[url];
  }, CACHE_TIMEOUT);
}

function fetchWithCache(url: string, options: ForceWithCacheOptions) {
  const { headers, method, body } = options;
  const cachedData = cache[url];

  if (options.cache !== false && cachedData) {
    return Promise.resolve(cachedData);
  }

  return clientFetch(url, { headers, method, body }).then((data) => {
    pushToCache(url, data);
    return data;
  });
}
