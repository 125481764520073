import { UIFetchStatus } from "@flights/types";
import { useEffect, useState } from "react";
import { createTimer, TimerFunc } from "utils/timer";

export default function useMeasureBrandedFaresWaitTime(fetchStatus: UIFetchStatus, hasBrandedFareOffers: boolean) {
  const [isMeasured, setIsMeasured] = useState(false);
  const [timer, setTimer] = useState<undefined | TimerFunc>();

  useEffect(() => {
    setTimer(() => createTimer());
  }, []);

  useEffect(() => {
    if (fetchStatus !== "success" || isMeasured || !timer) return;

    setIsMeasured(true);
  }, [fetchStatus, isMeasured, timer, hasBrandedFareOffers]);
}
